<template>
    <div class="auto-campaign-container animated fadeIn faster" ref="autoCampaignAssignment">
        <div class="auto-campaign-titlebar">
            <ar-titlebar
                campaign="Campaign and Platform Combos"
                subject="Combinations"
                a_class="feature"
                :from_count="from_page_count"
                :to_count="to_page_count"
                :max_count="total"
                is_listings
                :has_image="false"
            >
                <template v-slot:extra>
                    <ar-button
                        icon="fas fa-plus"
                        :is_fab="true"
                        style="padding-top: 8px; padding-bottom:8px;"
                        class="ml-3"
                        @click="hasClickedEdit = false,resetAutoCampaignAssignment(),showAutoCampaignAssignment()"
                    >
                        <!-- @click="current = {}; is_available = null; showAddFeatureModal()" -->
                        Add
                    </ar-button>
                    <ar-button
                        :is_fab="true"
                        style="padding-top: 8px; padding-bottom:8px;"
                        class="ml-3 accent"
                        @click="reset_manageGroup();openModal('auto-campaign-assignment-manage-group')"
                    >
                        Manage Groups
                    </ar-button>
                </template>
                <template v-slot:search-form>
                    <div class="form-inline" id="search-campaign-platform">
                        <div class="a-input-group">
                            <ar-textfield 
                                placeholder         ="Search Campaign or Platform..." 
                                type                ="text"
                                a_class             ="mr-1"
                                a_style             ="width: 300px;"
                                :icon               ="search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                                v-model             ="search"
                                @click              ="clearKeywords()"
                                @keyup.enter.native ="has_usedGlobalSearch = true,searchNow()"
                                v-tooltip.left      ="{ content: `Press <b>Enter</b> to search all the combinations.`, trigger: 'manual', show: !is_loading, container: '#search-campaign-platform', offset: 3 }"
                            />
                        </div>
                    </div>
                </template>
            </ar-titlebar>
            <div class="position-relative d-flex justify-content-between align-items-center filter-bar" :class="{'ml-4': !hasSelection, 'pl-0': hasSelection}">
                <div class="d-flex justify-content-between align-items-center selection-bar mr-5" :class="{'has-selection': hasSelection}">
                    <div class="w-100" v-show="!hasSelection">
                        <div class="animated fadeIn faster">
                            <div class="dropdown d-inline-block">
                                <a href="javascript:void(0);" id="filterByCampaign" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px; width:12px; height:12px;"
                                            src="/static/svg/Nav_Campaign_Active.svg" alt="campaign"> {{ $t(`Filter by - ${selected_campaign}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByCampaign">
                                    <a v-for="camp in modified_campaignForFilterBy" :key="camp.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="campaign = camp.value" style="gap: 12px;">
                                        <img :src="camp.logo" :alt="`${camp.label} Logo`" style="width: 18px; height: 18px; border-radius: 2px;">{{ $t(camp.label) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByPlatform" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;"
                                            src="/static/svg/Filterby_Platforms.svg" alt="platform"> {{ $t(`Filter by - ${selected_platform}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByPlatform">
                                    <a v-for="p in modified_platformForFilterBy" :key="p.id" class="dropdown-item"
                                        href="javascript:void(0);" @click="platform = p.id" style="gap: 12px;">
                                        <img :src="p.logo" :alt="`${p.name} Logo`" style="width: 18px; height: 18px; border-radius: 2px;">{{ $t(p.name) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByPriority" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;"
                                            src="/static/svg/Auto Campaign Admin/filter-priorities.svg" alt="priority"> {{ $t(`Filter by - ${selected_priority}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByPriority">
                                    <a v-for="prio in priorities" :key="prio.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="priority = prio.value" style="gap: 6px;">
                                        <span :style="`width: 6px !important; height:6px !important;border-radius: 100%; background: ${prio.color};`" />{{ $t(prio.label) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByStatus" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;"
                                            src="/static/svg/Auto Campaign Admin/filter-status.svg" alt="status"> {{ $t(`Filter by - ${selected_status}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByStatus">
                                    <a v-for="stat in statuses" :key="stat.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="status = stat.value" style="gap: 6px;">
                                        <template v-if="stat.label.toString().toLowerCase() == 'verified exhausted'">
                                            <span :style="`width: 6px !important; height:6px !important;border: 1px solid ${stat.color};border-radius: 100%; background: #FFFFFF;`" />{{ $t(stat.label) }}
                                        </template>
                                        <template v-else>
                                            <span :style="`width: 6px !important; height:6px !important;border-radius: 100%; background: ${stat.color};`" />{{ $t(stat.label) }}
                                        </template>
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByFallback" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <i class="fas fa-redo position-relative mr-1" style="color:#480048;transform: scaleX(-1)"></i> {{ $t(`Filter by - ${selected_fallback}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByFallback">
                                    <a v-for="fb in fallbacks" :key="fb.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="fallback = fb.value" style="gap: 6px;">
                                        <span :style="`width: 6px !important; height:6px !important;border-radius: 100%; background: ${fb.color};`" />{{ $t(fb.label) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterBySchedule" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <i class="far fa-calendar position-relative mr-1" style="color:#480048;transform: scaleX(-1)"></i> {{ $t(`Filter by - ${reformat_schedule(filterBy.schedules)}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterBySchedule">
                                    <a href="javscript:void(0)" class="dropdown-item">
                                        <ArCheckList 
                                            :id="`select-all-schedule--${schedule.label}`"
                                            :name="`select-all-schedule--checklist`"
                                            :value="filterBy.selectAll_schedules"
                                            :is-item="false"
                                            @click="filterBy.selectAll_schedules = !filterBy.selectAll_schedules"
                                            class="position-relative d-flex justify-content-start"
                                            style="height: 20px; top: 2px;padding-left: 0px !important;padding-right: 0px !important;"
                                            v-model="filterBy.selectAll_schedules"
                                        />
                                        {{ filterBy.selectAll_schedules ? 'Unselect' : 'Select' }} All Schedules
                                    </a>
                                    <a v-for="schedule in filterBy_schedules_options" :key="schedule.value" class="dropdown-item"
                                        href="javascript:void(0);" style="gap: 6px;">
                                        <ArCheckList 
                                            :id="`select-group--${schedule.label}`"
                                            :name="`select-group--checklist`"
                                            :value="schedule.value"
                                            :is-item="false"
                                            class="position-relative d-flex justify-content-start"
                                            style="height: 20px; top: 2px;padding-left: 0px !important;padding-right: 0px !important;"
                                            v-model="filterBy.schedules"
                                        />
                                        {{ $t(schedule.label) }}
                                    </a>
                                </div>
                            </div>
                            <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                                <a href="javascript:void(0);" id="filterByGroups" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <ar-button class="position-relative font-weight-bold pl-3 a-btn-text" 
                                        style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                        <img class="position-relative mr-1" style="top: -2px;width: 12px; height: 12px;"
                                            src="/static/svg/admin/Auto Campaign Assignment/folder-violet.svg" alt="status"> {{ $t(`Filter by - ${selected_filterBy_group}`) }}
                                    </ar-button>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="filterByGroups">
                                    <a v-for="group in mapped_groups" :key="group.value" class="dropdown-item"
                                        href="javascript:void(0);" @click="filterBy.group = group.value" style="gap: 6px;">
                                        {{ $t(group.name) }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100" v-show="hasSelection">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="d-flex justify-content-between align-items-center my-2">
                                <b class="text-uppercase">Actions</b>
                                <span style="border: 1px solid #ccc;height: 20px; width: 1px;margin: 0px 23px 0 12px;" />
                                <a href="javascript:void(0)" role="button" class="combo-btn mr-2" @click="initializeSelectionCache()"><img src="/static/svg/Main-feature.svg" alt="unselect"/>Unselect all</a>
                                <a href="javascript:void(0)" role="button" class="combo-btn update mr-2" @click="showActionsModal({ toggled: true, confirmation: false, action: '' })"><img src="/static/svg/auto-assignment-bulk/action-update-combo.svg" alt="update"/>Update combos</a>
                                <a href="javascript:void(0)" role="button" class="combo-btn enable mr-2" @click="showActionsModal({ toggled: false, confirmation: true, action: 'activating' })"><img src="/static/svg/auto-assignment-bulk/action-activate-combo.svg" alt="enable"/>Enable combos</a>
                                <a href="javascript:void(0)" role="button" class="combo-btn disable mr-2" @click="showActionsModal({ toggled: false, confirmation: true, action: 'disabling' })"><img src="/static/svg/auto-assignment-bulk/action-disable-combo.svg" alt="disable"/>Disable combos</a>
                                <!-- @click="showActionsModal({ toggled: false, confirmation: true, action: 'disabling' })" -->
                                <a href="javascript:void(0)" role="button" class="combo-btn add-group mr-2" @click="step = 4;openModal('auto-campaign-assignment-manage-group')"><img src="/static/svg/admin/Auto Campaign Assignment/Action_add group.svg" alt="Add Group"/>Add this to a group</a>
                                <a v-if="isOkayToEdit" href="javascript:void(0)" role="button" class="combo-btn edit-schedule mr-2" @click="openModal('edit-schedule');resetUngroupCombinations()"><img src="/static/svg/admin/Auto Campaign Assignment/Action_updatesched.svg" alt="Edit Schedule"/>Edit Schedule</a>
                                <!-- <a href="javascript:void(0)" role="button" class="combo-btn add-group" @click="step = 4;openModal('auto-campaign-assignment-manage-group')"><i class="far fa-object-ungroup" style="border: 2px solid #F07241;padding: 2px;border-radius: 4px;background: "></i>Edit Schedule</a> -->
                            </div>
                            <div class="has-selection-selected-count">
                                <img src="/static/svg/auto-assignment-bulk/selection-count.svg" alt="count"/>Selected {{ hasSelection }} combination(s)
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-end" style="gap: 10px; margin-right: 21px;height: 100%;">
                    <!-- <p class="m-0" style="width: 105px; padding-right: 10px; border-right: 1px solid; font-weight: 500;">Show 15 items</p> -->
                    <ar-select
                        :first_element_as_default="true"
                        v-model="page_size"
                        :options="showNumberOfCombos_options"
                        a_class="no-margin-bottom"
                        a_style="width: 150px; "
                    ></ar-select>
                    <span style="border: 1px solid #eee;height: 40px; width: 1px;margin: 0px 12px;" />
                    <ar-pagination-v2
                        :last-page="last_page"
                        v-model="page"
                        style="width: 110px;align-items: center;margin-right: 8px;"
                    ></ar-pagination-v2>
                    <img
                        title="Scheduling Report"
                        style="height: 17px;width: 17px;cursor: pointer;"
                        src="/static/svg/admin/Auto Campaign Assignment/manual report.svg"
                        alt="Manual Report Icon"
                        @click="openModal('group-schedule-report');getRecipients();"
                    >
                </div>
            </div>
        </div>
        <main class="main-con">
            <template>
                <table class="table-scroll">
                    <thead>
                        <tr>
                            <!-- <th class="col-1 text-center" @click="updateTableOrder('id')" title="ID corresponding to each Campaign and Platform Combo">
                                ID
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'id'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th> -->
                            <th class="col-1 text-center">
                                <ArCheckList 
                                    id="select-all-combo"
                                    v-model="selectAll"
                                    :value="true"
                                    :is-item="false"
                                    @click="multiSelect()"
                                    class="position-relative d-flex justify-content-center"
                                    style="height: 20px; top: 2px;"
                                />
                            </th>
                            <th class="col-2 text-left" scope="col" @click="updateTableOrder('campaign')">
                                Campaigns
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'campaign'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-left" scope="col" @click="updateTableOrder('platform')">
                                Platforms
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'platform'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('priority')">
                                Priority
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'priority'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>      
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('target')">
                                Target
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'target'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>      
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('no_of_researcher')">
                                No. of Researchers
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'no_of_researcher'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>      
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('status')">
                                Status
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'status'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>      
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('is_fallback')">
                                Fallback
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'is_fallback'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>      
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('assignment_groups.name')">
                                Group
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'assignment_groups.name'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>      
                            <th class="col-1 text-center" scope="col" @click="updateTableOrder('assignment_groups.name')">
                                Schedule
                                <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'assignment_groups.name'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div>
                            </th>
                            <th class="col-1 text-center" scope="col">
                                Actions
                                <!-- <div class="d-inline-flex position-relative ml-1 sorting-caret-con">
                                    <template v-if="table_order.column == 'actions'">
                                        <i class="fas fa-caret-up sorting-caret align-self-center" v-if="table_order.order == 'asc'"/>
                                        <i class="fas fa-caret-down sorting-caret align-self-center" v-else/>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-caret-up sorting-caret top-caret"/>
                                        <i class="fas fa-caret-down sorting-caret bottom-caret"/>
                                    </template>
                                </div> -->
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <div v-if="!filtered_combos.result.length" class="w-100 h-100 d-flex align-items-center justify-content-center">
                            <img style="min-width: 300px;" src="/static/svg/NoData.svg" alt="No Data Found">
                        </div>
                        <template v-else>
                            <div v-for="(combo, index) in filtered_combos.result" :key="index" @mouseover="hovered_index = index" class="table-item" :class="{'isHovered': hovered_index == index, 'noSchedule': combo?.status_label?.toLowerCase() == 'disabled'} ">
                                <!-- 
                                    noSchedule previous condition:
                                    combo?.assignment_group_id != null && combo?.assignment_group_id != undefined && combo?.assignment_group_id != -1 ? getGroupBy_Id(combo.assignment_group_id)?.schedules?.length == 0 : combo?.schedules?.length == 0
                                 -->
                                <tr class="justify-content-center">
                                    <td class="col-1 text-center">
                                        <ArCheckList 
                                            :id="`select-combo--${combo.id}`"
                                            :name="`select-combo--checklist`"
                                            v-model="selectedCombos[page]"
                                            :value="combo.id"
                                            :is-item="false"
                                            class="position-relative d-flex justify-content-center"
                                            style="height: 20px; top: 2px;"
                                        />
                                    </td>
                                    <td class="col-2 text-left">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="d-flex align-items-center justify-content-start" style="gap: 6px;width: 100%;">
                                                <img :src="$parent.getImage('campaigns',combo?.campaign?.name || 'Default')" :alt="combo?.campaign?.name" style="width: 20px; height: 20px;border-radius: 4px;">
                                                {{combo?.campaign?.name}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="col-1 text-left">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="d-flex align-items-center justify-content-start" style="gap: 6px;width: 100%;">
                                                <img :src="$parent.getImage('platforms',combo?.platform?.name || 'Default')" :alt="combo?.platform?.name" style="width: 20px; height: 20px;border-radius: 4px;">
                                                {{combo?.platform?.name}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <p class="m-0 priority" :class="[combo?.assignment_group_id == null ? combo?.priority?.toLowerCase() : combo?.group?.priority?.toLowerCase()]">
                                                {{ combo?.assignment_group_id == null ? combo?.priority : combo?.group?.priority }}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;color: #601848;">
                                            <img src="/static/svg/Auto Campaign Admin/target.svg" alt="Target Icon" style="width: 12px; height:12px;">
                                            {{ combo?.assignment_group_id == null ? combo?.target : combo?.group?.target }}
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center" style="gap: 6px;color: #601848;">
                                            <img src="/static/svg/Auto Campaign Admin/researchers.svg" alt="Researcher Icon" style="width: 12px; height:12px;">
                                            {{ combo?.assignment_group_id == null ? combo?.no_of_researcher : combo?.group?.no_of_researcher }}
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <p class="m-0 status"
                                                :class="{
                                                    'exhausted': combo?.status_label.toLowerCase() == 'exhausted',
                                                    'pending': combo?.status_label.toLowerCase() == 'pending',
                                                    'completed': combo?.status_label.toLowerCase() == 'completed',
                                                    'in-progress': combo?.status_label.toLowerCase() == 'in progress',
                                                    'verified-exhausted': combo?.status_label.toLowerCase() == 'verified exhausted',
                                                    'disabled': combo?.status_label.toLowerCase() == 'disabled',
                                                    'cursor-pointer': combo?.status_label.toLowerCase() == 'exhausted' || combo?.status_label.toLowerCase() == 'verified exhausted',
                                                }"
                                                @click="
                                                    combo?.status_label.toLowerCase() == 'exhausted' ? 
                                                    showAutoCampaignAssignmentExhausted(combo) :
                                                    combo?.status_label.toLowerCase() == 'verified exhausted' ?
                                                    showAutoCampaignAssignmentVerifiedExhausted(combo):
                                                    ''
                                                "
                                            >
                                                {{combo?.status_label}}
                                            </p>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <p class="m-0" style="color: #601848;text-transform: uppercase;">
                                            {{ combo?.assignment_group_id == null ? combo?.is_fallback == 1 ? 'Yes' : 'No' : combo?.group?.is_fallback == 1 ? 'Yes' : 'No' }}
                                        </p>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <template v-if="combo?.assignment_group_id == null || combo?.assignment_group_id == null">
                                                <p class="m-0" style="color: #383A3D;text-transform: uppercase;font-size: 10px;opacity:60%;letter-spacing: -0.2px;">
                                                    NOT IN A GROUP
                                                </p>
                                            </template>
                                            <template v-else>
                                                <div class="dropdown d-inline-block w-100">
                                                    <a href="javascript:void(0);" id="groupNameDropdown" data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false"
                                                        class="w-100"
                                                    >
                                                        <ar-button class="groupDropdown position-relative d-flex align-items-center justify-content-center font-weight-bold pl-3 a-btn-text w-100" 
                                                            style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px; ">
                                                                <span class="d-flex align-items-center justify-content-center w-100" style="border:1px solid #383A3D26;border-radius: 4px;padding: 4px;gap: 4px;max-width: fit-content;">
                                                                    <img style="width: 12px; height: 12px;" src="/static/svg/admin/Auto Campaign Assignment/orange_folder.svg" alt="Group Folder Icon">
                                                                    <p class="m-0 w-100" :title="getGroupBy_Id(combo.assignment_group_id)?.name" style="color: #383A3D;text-transform: uppercase;font-size: 10px;letter-spacing: -0.2px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;">
                                                                        {{ getGroupBy_Id(combo.assignment_group_id)?.name }}
                                                                    </p>
                                                                </span>
                                                        </ar-button>
                                                    </a>
                                                    <div class="dropdown-menu" aria-labelledby="groupNameDropdown">
                                                        <a v-for="group in groupDropdown_options" :key="group.value" class="dropdown-item"
                                                            href="javascript:void(0);" style="gap: 12px;"
                                                            @click="group.value == 0 ? initialize_moveToAnotherGroup(combo) : initialize_removeFromThisGroup(combo)"
                                                        >
                                                            <i :class="group.icon" style="width: 18px; height: 18px; border-radius: 2px;"></i>
                                                            {{ $t(group.label) }}
                                                        </a>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center">
                                                <div class="d-flex align-items-center justify-content-center flex-wrap w-100" style="gap: 5px;">
                                                    <p 
                                                        v-for="
                                                            (schedule, index) in
                                                            rearranged_schedule(combo?.assignment_group_id == null ? combo?.schedules?.map(c => c.day) : getGroupBy_Id(combo?.assignment_group_id ?? '')?.schedules)
                                                        "
                                                        :key="index"
                                                        class="m-0 schedule-format"
                                                        :class="{'isActive': schedule?.is_active}"
                                                    >
                                                        <!-- {{schedule}} -->
                                                        {{ ['saturday','sunday','thursday'].includes(schedule?.name?.toLowerCase()) ? `${schedule?.name[0]}${schedule?.name[1]}` : `${schedule?.name[0]}` }}
                                                    </p>
                                                </div>
                                        </div>
                                    </td>
                                    <td class="col-1 text-center">
                                        <div class="d-flex align-items-center justify-content-center"  style="gap: 12px;">
                                            <img
                                                v-show="combo?.status_label.toLowerCase() == 'exhausted'"
                                                src="/static/svg/Error_SA.svg"
                                                alt="Replenish"
                                                class="width: 24px; height: 24px;border-radius: 100%;"
                                                style="cursor: pointer;"
                                                @click="selected_combo = combo,showAutoCampaignAssignmentExhausted(combo)"
                                                v-tooltip="`Exhaust Combo`"
                                            >
                                            <img
                                                v-show="combo?.status_label.toLowerCase() == 'verified exhausted'"
                                                src="/static/svg/export/Export_Submission_Resume.svg"
                                                alt="Replenish"
                                                class="width: 24px; height: 24px;border-radius: 100%;"
                                                style="cursor: pointer;"
                                                @click="selected_combo = combo,showAutoCampaignAssignmentVerifiedExhausted(combo)"
                                                v-tooltip="`Replenish Combo`"
                                            >
                                            <!-- add the data to the modal -->
                                            <img 
                                                src="/static/svg/Auto Campaign Admin/action-edit.svg"
                                                alt="Edit"
                                                class="width: 24px; height: 24px;border-radius: 100%;"
                                                style="cursor: pointer;"
                                                @click="selected_combo = combo,hasClickedEdit = true,showAutoCampaignAssignment('edit')"
                                                v-tooltip="`Edit Combo`"
                                            >
                                            <!-- add the data and modify to disable -->
                                            <!-- v-show="combo.status != 0" -->
                                            <img
                                                :src="`/static/svg/${combo?.status_label.toLowerCase() == 'disabled' ? 'UnselectedTemplate' : 'Auto Campaign Admin/action-disable' }.svg`"
                                                alt="Disable"
                                                class="width: 24px; height: 24px;border-radius: 100%;"
                                                style="cursor: pointer;"
                                                @click="selected_combo = combo,showDisableCampaignAssignment()"
                                                v-tooltip="`Activate / Deactivate Combo`"
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </div>
                        </template>
                    </tbody>
                </table>
            </template>
        </main>

        <ArAutoAssignmentActionsModal 
            :priority_level_options="priority_level_options" 
            :toggled="bulk.toggled"
            :confirmation="bulk.confirmation"
            :image="bulk.image"
            :combos="selectedCombosValues"
            :enableFunction="enableMultiCombo"
            :disableFunction="disableMultiCombo"
            :updateFunction="updateMultiCombo"
        />

        <ar-dialog
            id="auto-campaign-assignment"
            custom_width="fit-content"
            custom_minwidth="800px"
            large
            :has_button="false"
            :has_footer="false"
            dialog_style="padding: 0px;height: 565px;"
            mbClass="no-padding"
        >  
            <div class="d-flex align-items-center justify-content-end" style="height: 565px;width: auto;">
                <!-- left -->
                <div style="width: 430px;height:100%;padding: 34px 30px 24px 36px;" class="text-left d-flex flex-column align-items-center justify-content-between">
                    <div class="d-flex flex-column align-items-center justify-content-start">
                        <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 12px;">
                            <p class="m-0" style="color: #000000DE; font-size: 24px; letter-spacing: 0.31px;font-weight: 500;">Auto Campaign Assignment</p>
                            <p class="m-0" style="color: #515365;font-size: 12px;">This feature allows an administrator to set the platform and campaign combinations which will be assigned to researchers randomly.</p>
                        </div>
                        <div class="container my-3" style="height: 330px;overflow:auto;padding: 0px 10px 15px;">
                            <div class="row">
                                <ar-select
                                    type="search"
                                    class="col-12"
                                    style="padding: 0px !important;"
                                    label="
                                        Campaign
                                        <span style='font-size: 10px;
                                            letter-spacing: 1.16px;
                                            color: #FFFFFF;
                                            background: #F5686C;
                                            border: 1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 1px 4px;'
                                            title='Required'
                                        >REQ</span>
                                    "
                                    :options="mapped_campaigns"
                                    :show_default="true"
                                    v-model="create_auto_assign.campaign"
                                    :default_val="{label: campaigns.filter(c => c.id == create_auto_assign.campaign)[0]?.name, value: create_auto_assign.campaign}"
                                    placeholder="Select Campaign here..."
                                ></ar-select>
                                <ar-select
                                    type="search"
                                    class="col-12"
                                    style="padding: 0px !important;"
                                    label="
                                        Platform
                                        <span style='font-size: 10px;
                                            letter-spacing: 1.16px;
                                            color: #FFFFFF;
                                            background: #F5686C;
                                            border: 1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 1px 4px;'
                                            title='Required'
                                        >REQ</span>
                                    "
                                    v-model="create_auto_assign.platform"
                                    :options="mapped_platforms"
                                    :show_default="true"
                                    :default_val="{label: platforms.filter(p => p.id == create_auto_assign.platform)[0]?.name, value: create_auto_assign.platform}"
                                    placeholder="Select Platform here..."
                                    :disabled="create_auto_assign.campaign == 0"
                                ></ar-select>
                                <div class="col-12 mb-3 mt-1">
                                    <div class="d-flex align-items-center justify-content-start" style="gap: 10px;">
                                        <ar-checkbox
                                            id="fallback"
                                            name="fallback"
                                            :checked="create_auto_assign.fallback == 0 ? false : true"
                                            @change="create_auto_assign.fallback == 0 ? create_auto_assign.fallback = 1 : create_auto_assign.fallback = 0"
                                            label="Is this combo a Fallback?"
                                            label_style="vertical-align: middle;"
                                            :disabled="create_auto_assign.priority == 'URGENT'"
                                        ></ar-checkbox>
                                        <!-- <span style='font-size: 10px;
                                            letter-spacing: 1.16px;
                                            color: #FFFFFF;
                                            background: #F5686C;
                                            border: 1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 1px 4px;'
                                            title='Required'
                                        >REQ</span> -->
                                    </div>
                                </div>
                                <ar-textfield
                                    class="col-6"
                                    a_type="number"
                                    label="Target"
                                    placeholder="Input number here..."
                                    style="padding-left: 0px !important;"
                                    v-model="create_auto_assign.target"
                                    :state="create_auto_assign.fallback == false && create_auto_assign.target == 0 && create_auto_assign.priority != 'URGENT' ? 'invalid' : ''"
                                    :err_txt="create_auto_assign.fallback == false && create_auto_assign.target == 0 && create_auto_assign.priority != 'URGENT' ? `Invalid value for not fallback`:''"
                                    :min="0"
                                    err_style="color: #DC3C40; margin-left: 0"
                                ></ar-textfield>
                                <ar-textfield
                                    class="col-6"
                                    a_type="number"
                                    placeholder="Input number here..."
                                    label="
                                        No. of Researchers
                                        <span style='font-size: 10px;
                                            letter-spacing: 1.16px;
                                            color: #FFFFFF;
                                            background: #F5686C;
                                            border: 1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 1px 4px;'
                                            title='Required'
                                        >REQ</span>
                                    "
                                    style="padding-right: 0px !important;"
                                    v-model="create_auto_assign.no_of_researchers"
                                    :min="0"
                                ></ar-textfield>
                                <div class="col-12" style="padding: 0px !important;">
                                    <label for="" v-html="`
                                            Priority Level
                                            <span style='font-size: 10px;
                                                letter-spacing: 1.16px;
                                                color: #FFFFFF;
                                                background: #F5686C;
                                                border: 1px solid #383A3D26;
                                                border-radius: 4px;
                                                padding: 1px 4px;'
                                                title='Required'
                                            >REQ</span>`"
                                        style="color: #383A3D;
                                            margin-left: 5px;
                                            font-weight: 500;
                                            font-size: 13px;
                                            margin-bottom: 12px;
                                        "
                                    >
                                    </label>
                                    <div class="d-flex align-items-center justify-content-start" style="gap: 14px">
                                        <ar-radio
                                            v-for="(pl, index) in priority_level_options"
                                            :key="index"
                                            :label="pl.label"
                                            :id="`priority-level-${index}`"
                                            class="sm"
                                            name="
                                                Priority Level
                                            "
                                            @change="create_auto_assign.priority = pl.value"
                                            :checked="create_auto_assign.priority == pl.value"
                                        ></ar-radio>
                                    </div>
                                </div>
                                <div class="w-100 mt-3" style="font-size: 14px;color:#515365;">
                                    <ar-checkbox
                                        id="add-schedule-single-edit"
                                        name="add-schedule-single-edit"
                                        :checked="create_auto_assign.hasNoSchedule"
                                        @change="!create_auto_assign.hasNoSchedule ? create_auto_assign.hasNoSchedule = true : create_auto_assign.hasNoSchedule = false"
                                        label="Don't Set Schedule for this Combo"
                                        label_style="vertical-align: middle;"
                                    ></ar-checkbox>
                                    <!-- <p class="m-0">Include the Assigned Recipients.</p> -->
                                </div>
                                <div 
                                    v-if="(selected_combo.assignment_group_id == null || selected_combo.assignment_group_id == undefined || selected_combo.assignment_group_id == null)"
                                    class="d-flex flex-column align-items-start justify-content-start position-relative mt-4"
                                    :style="[
                                        create_auto_assign.hasNoSchedule ? {'opacity':'50%'} : {'opacity':'100%'}
                                    ]"
                                    style="gap: 20px;"
                                >
                                    <div class="d-flex align-items-center justify-content-start" style="gap:5px;">
                                        <p class="m-0" style="font-size: 13px;margin-left: 5px !important;font-weight: 500;">Assign Schedule</p>
                                        <span style='font-size: 10px;
                                            letter-spacing: 1.16px;
                                            color: #FFFFFF;
                                            background: #F5686C;
                                            border: 1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 1px 4px;
                                            font-size: 10px;
                                            font-weight: bold;'
                                            title='Required'                                
                                        >REQ</span>
                                    </div>
                                    <div class="row g-3 w-100">
                                        <ArCheckList 
                                            v-for="(day, index) in schedules_options"
                                            :key="index"
                                            :id="`select-schedule--${day.value}-combination`"
                                            :name="`select-schedule--checklist-combination`"
                                            :value="day.value"
                                            :is-item="false"
                                            class="position-relative d-flex justify-content-start col-md-6"
                                            :label="day.label"
                                            style="height: 16px; margin: 10px 0px;"
                                            v-model="create_auto_assign.schedules"
                                            :disabled="create_auto_assign.hasNoSchedule"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ar-button
                        class="w-100 "
                        style="margin-bottom: 22px;"
                        :disabled="!isValidForAddingCombo"
                        @click="hasClickedEdit ? updateCombo() : addCombo()"
                    >
                        {{ hasClickedEdit ? 'Proceed Updating' : 'Proceed Adding' }}
                    </ar-button>
                </div>
                <!-- right -->
                <!-- width: 274px; position: relative; -->
                <div style="width:370px; height:100%;position:relative;">
                    <img src="/static/svg/Auto Campaign Admin/add-combo.svg" alt="drawer" style="position: absolute;z-index: 4;width: 370px;height: 563px;top:0;right:0;">
                    <img src="/static/svg/Auto Campaign Admin/bg-add-combo.png" alt="bg-drawer" style="position: absolute;width: 388px;height: 580px;z-index: 3;right: -9px;top:-6px;">
                </div>
            </div>
        </ar-dialog>

        <ar-dialog
            id="auto-campaign-assignment-exhausted"
            custom_width="fit-content"
            custom_minwidth="760px"
            large
            :has_button="false"
            :has_footer="false"
            dialog_style="padding: 0px;height: 565px;"
            mbClass="no-padding"
        >
            <div class="d-flex align-items-center justify-content-end" style="height: 565px;width: auto;">
                <div
                    style="
                        width: 390px;
                        height: 100%;
                        padding: 36px 16px 36px 28px;
                    "
                >
                    <!-- header -->
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding-bottom: 12px;border-bottom: 1px solid #7070703B;">
                        <div class="d-flex flex-column align-items-start justify-content-start" >
                            <p class="m-0" style="text-transform: uppercase;color: #601848;font-size: 12px;font-weight: bold;">
                                auto campaign assignment
                            </p>
                            <p class="m-0" style="color: #383A3D;font-size: 24px;font-weight: bold;">
                                Exhausted
                            </p>
                        </div>
                        <div class="d-flex align-items-center justify-content-start w-100" style="gap: 28px;">
                            <div class="d-flex align-items-center justify-content-start text-left"
                                style="
                                    gap: 6px;
                                    max-width: 45%;
                                "
                            >
                                <img :src="$parent.getImage('campaigns',selected_combo?.campaign?.name || 'Default')" alt="Campaign Logo" style="width: 20px; height: 20px;border-radius: 4px;">
                                <p
                                    class="m-0"
                                    style="
                                        font-size: 12px;
                                        letter-spacing: 0.13px;
                                        color: #515365;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        width: calc(100% - 20px);
                                    "
                                >
                                    {{selected_combo?.campaign?.name}}
                                </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-start text-left"
                                style="
                                    gap: 6px;
                                    min-width: 40%;
                                    max-width: 45%;
                                "
                            >
                                <img :src="$parent.getImage('platforms',selected_combo?.platform?.name || 'Default')" alt="Platform Logo" style="width: 20px; height: 20px;border-radius: 4px;">
                                <p
                                    class="m-0"
                                    style="
                                        font-size: 12px;
                                        letter-spacing: 0.13px;
                                        color: #515365;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        width: calc(100% - 20px);
                                    "
                                >
                                    {{selected_combo?.platform?.name}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- body  -->
                    <div class="d-flex flex-column align-items-center justify-content-start w-100" style="gap: 20px;margin-top: 12px;">
                        <div class="w-100">
                            <div class="d-flex align-items-center justify-content-between w-100" style="font-weight: bold">
                                <p class="m-0" style="font-size: 14px;color:#515365;">Showing {{ assigned_researchers?.length }} Assigned Researchers</p>
                                <span class="d-flex align-items-center justify-content-end" style="gap: 6px;font-size: 12px;color: #601848;">
                                    <img src="/static/svg/Auto Campaign Admin/target.svg" alt="Target Icon" style="width: 14px; height: 14px;">
                                    <p class="m-0">{{ selected_combo?.target }}</p>
                                </span>
                            </div>
                            <div class="d-flex align-items-center justify-content-start text-left" style="margin-top: 3px;">
                                <span style="font-size: 12px;color:#515365;">
                                    Researchers with this icon
                                    <img style="width: 16px;margin-bottom:7px" src="/static/svg/Auto Campaign Admin/exhausted-icon.svg" alt="Exhausted Icon">
                                    flagged the combo as exhausted.
                                </span>
                            </div>
                        </div>
                        <!-- Assigned Researchers List -->
                        <div class="w-100 overflow-auto" style="height: 240px;">
                            <div
                                style="
                                    padding: 14px;
                                    border-bottom: 1px solid #70707033;
                                    border-top: 1px solid #70707033;
                                "
                                class="d-flex align-items-center justify-content-between"
                                v-for="(item, index) in assigned_researchers"
                                :key="index"
                            >
                                <div class="d-flex align-items-center justify-content-start" style="gap: 10px;">
                                    <!-- Change image to Specific Access level -->
                                    <img :src="$parent.getImage('users', getRole(item?.access_level))" alt="Avatar Icon" style="width: 16px;height: 16px;border-radius: 2px;">
                                    <p class="m-0" style="font-size: 14px;letter-spacing:0.15px;color:#515365;font-weight: 500;">{{ `${item?.first_name} ${item?.last_name}` }}</p>
                                </div>
                                <img v-if="item.exhausted_flag == 1" src="/static/svg/Auto Campaign Admin/exhausted-icon.svg" alt="Exhausted Icon" style="width: 24px;">
                            </div>
                        </div>
                    </div>
                    <div class="w-100 animated fadeIn" v-show="exhausted_gui == 0">
                        <!-- button -->
                        <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                            <ar-button
                                v-if="assigned_researchers == null || assigned_researchers.some(researcher => researcher.exhausted_flag === 0)"
                                :disabled="true"
                                v-tooltip="'You are not allowed to mark this as verified exhausted. Atleast one user is still working on this combo'"
                                style="background: #DC3C40;width: 100%;"
                            >
                                Verified Exhausted
                            </ar-button>
                            <ar-button
                                v-else
                                style="background: #DC3C40;width: 100%;"
                                @click="exhausted_gui = 1"
                            >
                                Verified Exhausted
                            </ar-button>
                            <a href="javascript:void(0)" @click="exhausted_gui = 2" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Please return this combination</a>
                        </div>
                    </div>
                </div>
                <div style="width: 370px;height: 100%;">
                    <div style="height: 100%;" v-show="exhausted_gui == 0" class="animated fadeIn">
                        <!-- Exhausted -->
                        <div style="height: 100%;position: relative;">
                            <img src="/static/svg/Auto Campaign Admin/artemis_exhausted .svg" alt="exhausted" style="position: absolute;z-index: 4;width: 370px;bottom:2px;right:0;">
                            <img src="/static/svg/Auto Campaign Admin/bg-exhausted.png" alt="bg-exhausted" style="position: absolute;width: 370px;height: 580px;z-index: 3;right: -9px;top:-6px;">
                        </div>
                    </div>
                    <div style="height: 100%;" v-show="exhausted_gui == 1" class="animated fadeIn">
                        <!-- Verifying -->
                        <div style="height: 100%;padding: 38px 38px 44px 38px;position: relative;" class="d-flex flex-column align-items-center justify-content-between">
                            <div>
                                <img src="/static/svg/Auto Campaign Admin/artemis_verified.svg" alt="exhausted" style="width: 235px;height: 250px;">
                                <p class="m-0" style="color:#515365;font-size: 24px;letter-spacing: 0.43px;font-weight: bold;">Verifying...</p>
                                <p class="m-0" style="color:#515365;font-size: 16px;font-weight: 500;letter-spacing:0.26px;margin-top: 6px !important;width: 246px;">This would be removed from the Auto Campaign randomizer list.</p>
                            </div>
                            <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                                <ar-button
                                    style="width: 100%;"
                                    @click="exhaustCombo()"
                                >
                                    <!-- @click="exhausted_gui = 1" -->
                                    I got it, I would like to proceed
                                </ar-button>
                                <a href="javascript:void(0)" @click="exhausted_gui = 0" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Please cancel the action</a>
                            </div>
                        </div>
                    </div>
                    <div style="height: 100%;" v-show="exhausted_gui == 2" class="animated fadeIn">
                        <!-- For Further Research -->
                        <div style="height: 100%;padding: 38px 38px 44px 38px;position: relative;" class="d-flex flex-column align-items-center justify-content-between">
                            <div>
                                <img src="/static/svg/Auto Campaign Admin/artemis_return.svg" alt="exhausted" style="width: 232px;height: 146px;margin-top: 60px;margin-bottom: 34px;">
                                <p class="m-0" style="color:#515365;font-size: 24px;letter-spacing: 0.43px;font-weight: bold;">For further research</p>
                                <p class="m-0" style="color:#515365;font-size: 16px;font-weight: 500;letter-spacing:0.26px;margin-top: 6px !important;width: 288px;">This would be returned to the Auto assignment list of the user who tagged it as “Exhausted”.</p>
                            </div>
                            <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                                <ar-button
                                    style="width: 100%;"
                                    @click="forFurtherResearchCombo()"
                                >
                                    <!-- @click="exhausted_gui = 1" -->
                                    I got it, I would like to proceed
                                </ar-button>
                                <a href="javascript:void(0)" @click="exhausted_gui = 0" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Please cancel the action</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog
            id="auto-campaign-assignment-verified-exhausted"
            custom_width="fit-content"
            custom_minwidth="760px"
            large
            :has_button="false"
            :has_footer="false"
            dialog_style="padding: 0px;height: 565px;"
            mbClass="no-padding"
        >
            <div class="d-flex align-items-center justify-content-end" style="height: 565px;width: auto;">
                <div
                    style="
                        width: 390px;
                        height: 100%;
                        padding: 36px 16px 36px 28px;
                    "
                >
                    <!-- header -->
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding-bottom: 12px;border-bottom: 1px solid #7070703B;">
                        <div class="d-flex flex-column align-items-start justify-content-start" >
                            <p class="m-0" style="text-transform: uppercase;color: #601848;font-size: 12px;font-weight: bold;">
                                auto campaign assignment
                            </p>
                            <p class="m-0" style="color: #383A3D;font-size: 24px;font-weight: bold;">
                                Verified Exhausted
                            </p>
                        </div>
                        <div class="d-flex align-items-center justify-content-start w-100" style="gap: 28px;">
                            <div class="d-flex align-items-center justify-content-start text-left"
                                style="
                                    gap: 6px;
                                    max-width: 45%;
                                "
                            >
                                <img :src="$parent.getImage('campaigns',selected_combo?.campaign?.name || 'Default')" alt="Campaign Logo" style="width: 20px; height: 20px;border-radius: 4px;">
                                <p
                                    class="m-0"
                                    style="
                                        font-size: 12px;
                                        letter-spacing: 0.13px;
                                        color: #515365;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        width: calc(100% - 20px);
                                    "
                                >
                                    {{selected_combo?.campaign?.name}}
                                </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-start text-left"
                                style="
                                    gap: 6px;
                                    min-width: 40%;
                                    max-width: 45%;
                                "
                            >
                                <img :src="$parent.getImage('platforms',selected_combo?.platform?.name || 'Default')" alt="Platform Logo" style="width: 20px; height: 20px;border-radius: 4px;">
                                <p
                                    class="m-0"
                                    style="
                                        font-size: 12px;
                                        letter-spacing: 0.13px;
                                        color: #515365;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        width: calc(100% - 20px);
                                    "
                                >
                                    {{selected_combo?.platform?.name}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- body  -->
                    <div class="d-flex flex-column align-items-center justify-content-start w-100" style="gap: 20px;margin-top: 12px;">
                        <div class="w-100">
                            <div class="d-flex align-items-center justify-content-between w-100" style="padding: 8px;background: #EFF0F0;border-radius: 4px;margin-bottom: 10px;">
                                <div class="d-flex align-items-center justify-content-start" style="gap: 7px;">
                                    <img :src="$parent.getImage('users', getRole(selected_combo?.verified_by?.access_level))" alt="Avatar Icon" style="width: 24px;height: 24px;border-radius: 2px;">
                                    <div class="d-flex flex-column align-items-start justify-content-center text-left w-100" style="max-width: 200px">
                                        <p class="m-0" style="font-size: 10px; color: #383A3D;letter-spacing: 0.1px;font-weight: bold;">VERIFIED BY</p>
                                        <p 
                                            class="m-0 w-100"
                                            style="
                                                font-size: 12px;
                                                letter-spacing: 0.15px;
                                                color:#515365;
                                                font-weight: bold;
                                                text-overflow: ellipsis;
                                                overflow: hidden;
                                                white-space: nowrap;
                                                padding-right: 10px;
                                            "
                                            :title="`${selected_combo?.verified_by?.first_name} ${selected_combo?.verified_by?.last_name}`"
                                        > {{  `${selected_combo?.verified_by?.first_name} ${selected_combo?.verified_by?.last_name}` }}</p>
                                    </div>
                                </div>
                                <span style="font-size: 12px; color: #515365;font-weight: 500;letter-spacing: -0.2px;padding: 4px;border-radius: 4px;border: 1px solid #70707054;background: #FFFFFF;">
                                    {{ getDate(selected_combo?.verified_at) }}
                                </span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between w-100" style="font-weight: bold">
                                <p class="m-0" style="font-size: 14px;color:#515365;">Showing {{ assigned_researchers_verifiedExhausted?.length }} Assigned Researchers</p>
                                <span class="d-flex align-items-center justify-content-end" style="gap: 6px;font-size: 12px;color: #601848;">
                                    <img src="/static/svg/Auto Campaign Admin/target.svg" alt="Target Icon" style="width: 14px; height: 14px;">
                                    <p class="m-0">{{ selected_combo?.target }}</p>
                                </span>
                            </div>
                            <div class="d-flex align-items-center justify-content-start text-left" style="margin-top: 3px;">
                                <span style="font-size: 12px;color:#515365;">
                                    Researchers with this icon
                                    <img style="width: 16px;margin-bottom:7px" src="/static/svg/Auto Campaign Admin/exhausted-icon.svg" alt="Exhausted Icon">
                                    flagged the combo as exhausted.
                                </span>
                            </div>
                        </div>
                        <!-- Assigned Researchers List -->
                        <div class="w-100 overflow-auto" style="height: 212px;">
                            <div
                                style="
                                    padding: 14px;
                                    border-bottom: 1px solid #70707033;
                                    border-top: 1px solid #70707033;
                                "
                                class="d-flex align-items-center justify-content-between"
                                v-for="(item, index) in assigned_researchers_verifiedExhausted"
                                :key="index"
                            >
                                <div class="d-flex align-items-center justify-content-start" style="gap: 10px;">
                                    <!-- Change image to Specific Access level -->
                                    <img :src="$parent.getImage('users', getRole(item?.access_level))" alt="Avatar Icon" style="width: 16px;height: 16px;border-radius: 2px;">
                                    <p class="m-0" style="font-size: 14px;letter-spacing:0.15px;color:#515365;font-weight: 500;">{{ `${item?.first_name} ${item?.last_name}` }}</p>
                                </div>
                                <img v-if="item.exhausted_flag == 1" src="/static/svg/Auto Campaign Admin/exhausted-icon.svg" alt="Exhausted Icon" style="width: 24px;">
                            </div>
                        </div>
                    </div>
                    <div class="w-100 animated fadeIn" v-show="verified_exhausted_gui == 0">
                        <!-- button -->
                        <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                            <ar-button
                                style="background: #DC3C40;width: 100%;"
                                @click="verified_exhausted_gui = 1"
                            >
                                Replenish Combo
                            </ar-button>
                        </div>
                    </div>
                </div>
                <div style="width: 370px;height: 100%;">
                    <div style="height: 100%;" v-show="verified_exhausted_gui == 0" class="animated fadeIn">
                        <!-- Exhausted -->
                        <div style="height: 100%;position: relative;">
                            <img src="/static/svg/Auto Campaign Admin/artemis_exhausted .svg" alt="exhausted" style="position: absolute;z-index: 4;width: 370px;bottom:2px;right:0;">
                            <img src="/static/svg/Auto Campaign Admin/bg-exhausted.png" alt="bg-exhausted" style="position: absolute;width: 370px;height: 580px;z-index: 3;right: -9px;top:-6px;">
                        </div>
                    </div>
                    <div style="height: 100%;" v-show="verified_exhausted_gui == 1" class="animated fadeIn">
                        <!-- For Further Research -->
                        <div style="height: 100%;padding: 38px 38px 44px 38px;position: relative;" class="d-flex flex-column align-items-center justify-content-between">
                            <div>
                                <img src="/static/svg/Auto Campaign Admin/artemis_return.svg" alt="exhausted" style="width: 232px;height: 146px;margin-top: 60px;margin-bottom: 34px;">
                                <p class="m-0" style="color:#515365;font-size: 24px;letter-spacing: 0.43px;font-weight: bold;">Replenishing</p>
                                <p class="m-0" style="color:#515365;font-size: 16px;font-weight: 500;letter-spacing:0.26px;margin-top: 6px !important;width: 288px;">This would be returned to the Auto assignment list</p>
                            </div>
                            <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                                <ar-button
                                    style="width: 100%;"
                                    @click="replenishCombo()"
                                >
                                    I got it, I would like to proceed
                                </ar-button>
                                <a href="javascript:void(0)" @click="verified_exhausted_gui = 0" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Please cancel the action</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ar-dialog>

        <ar-dialog
            id="disable-combo"
            :heading="selected_combo?.status_label?.toLowerCase() == 'disabled' ? `Activate Combo` : `Deactivate Combo`"
            :heading_style="selected_combo?.status_label?.toLowerCase() == 'disabled' ? `color: #117b0a` : `color: #DC3C40;`"
            :sub_heading="
                selected_combo?.status_label?.toLowerCase() == 'disabled'
                    ? `You are about to activate this Combo.\n'${selected_combo?.campaign?.name}' - '${selected_combo?.platform?.name}'\nClick 'Proceed' to continue to activation.` 
                    : `You are about to deactivate this Combo.\n'${selected_combo?.campaign?.name}' - '${selected_combo?.platform?.name}'\nClick 'Proceed' to continue to deactivation.`"
        >
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    outlined
                    @click="hideDisableCampaignAssignment()"
                >
                    Cancel
                </ar-button>
                <ar-button @click="selected_combo?.status_label?.toLowerCase() == 'disabled' ? enableCombo() : disableCombo()">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog
            id="auto-campaign-assignment-manage-group"
            custom_width="fit-content"
            custom_minwidth="415px"
            custom_maxwidth="1226px"
            large
            :has_button="false"
            :has_footer="false"
            dialog_style="padding: 36px 40px;height: 565px;"
            mbClass="no-padding"
            :a_class="[1,2,3,4].includes(Math.floor(step)) ? 'will-not-close-when-clicked-outside' : 'pointer-events-activate'"
        >
            <!-- Manage Groups -->
            <div 
                class="d-flex flex-column align-items-start justify-content-start"
                style="gap: 15px;width: 333px;"
                v-if="step == 0"
            >
                <div
                    class="manage-group-view-title w-100"
                >
                    <h4 class="m-0">Manage Groups</h4>
                    <p class="m-0" style="font-size: 12px;">Update a specific custom group</p>
                </div>
                <ar-textfield 
                    placeholder         ="Search Group here..." 
                    type                ="text"
                    a_class             ="mr-1"
                    a_style             ="width: 100%;"
                    :icon               ="manage_group.search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                    v-model             ="manage_group.search"
                    class               ="w-100 m-0"
                    @click              ="manage_group.search = ''"
                />
                <div
                    class="manage-group-group-container"
                    style="margin-top: 15px;"
                >
                    <div
                        v-for="(group, index) in filtered_groups"
                        :key="index"
                        class="manage-group-group-item"
                    >
                        <div
                            class="manage-group-group-item-item"
                            style="gap: 12px;"
                            @click="isClickedGroup(group) ? selected_group = {} : selected_group = group"
                            @mouseover="hovered_group = group"
                            @mouseleave="hovered_group = {}"
                            :class="{'isClicked': isClickedGroup(group), 'isHovered': isHoveredGroup(group)}"
                        >
                            <img style="width: 24px; height: 24px;" src="/static/svg/admin/Auto Campaign Assignment/folder-gray.svg" alt="Group Folder SVG">
                            <div class="d-flex flex-column align-items-start justify-content-start text-left" style="width: calc(100% - 100px);">
                                <p :title="group.name" class="m-0 w-100" style="font-size: 14px;letter-spacing: 0.24px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;">
                                    {{ group.name }}
                                </p>
                                <p class="m-0" style="font-size: 10px;letter-spacing: 0.17px;font-weight: normal !important;">
                                    {{ group.assignments.length ? `${group.assignments.length} Number of Combination(s)` : 'No Combinations for this Group' }} 
                                </p>
                            </div>
                            <!-- <i v-if="isClickedGroup(group)" class="fas fa-check-circle click-check"></i> -->
                            <!-- v-if="isHoveredGroup(group) && !isClickedGroup(group)" -->
                            <div
                                v-if="isHoveredGroup(group)"
                                class="d-flex align-items-start justify-content-end position-absolute"
                                style="right: 12px;"
                            >
                                <img @click="isClickedGroup(group) ? selected_forDelete_group = {} : selected_forDelete_group = group" style=" height: 42px;" src="/static/svg/3month-report/Delete-report-circle.svg" title="Remove Group" alt="Delete Group">
                                <img @click="step = 2;selected_group = group; setUpdateGroupValues()" style=" height: 36px;padding-top:2px;" src="/static/svg/Campaign_Edit.svg" title="Edit Group" alt="Edit Group">
                            </div>
                        </div>
                        <div v-if="selected_forDelete_group.id == group.id" class="d-flex align-items-center justify-content-between w-100" style="gap: 5px;padding: 5px 10px;height: 40px;">
                            <p class="m-0" style="color: #2D599ECC;font-size: 12px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Group?</p>
                            <!-- <p class="m-0" style="font-size: 12px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p> -->
                            <div class="d-flex align-items-center justify-content-end" style="gap: 15px;">
                                <div @click="selected_forDelete_group = {};selected_group = {};" title="Click to Cancel Removing Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid red;padding: 5px;border-radius:100%;">
                                    <img class="w-100 h-100" src="/static/svg/Checklist_No.svg" alt="X Icon" >
                                </div>
                                <div @click="removeGroup(group.id)" title="Click to Remove Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                    <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                </div>
                            </div>
                        </div>
                        <hr v-if="groups.length - index != 1">
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center w-100">
                    <ar-button
                        style="width: 285px;vertical-align: middle;"
                        @click="step = 1;"
                    >
                        <i class="far fa-star"></i>
                        Create a new group
                    </ar-button>
                </div>
            </div>
            
            <!-- create a new group -->
            <div
                v-if="Math.floor(step) == 1"
                style="text-align: left;"
            >
                <div
                    class="create-new-custom-group-title w-100"
                >
                    <h4 class="m-0">Create a New Custom Group</h4>
                    <p class="m-0" style="font-size: 12px;">Fill in the required fields.</p>
                </div>
                <div class="d-flex align-items-end justify-content-start" style="gap: 15px">
                    <ar-textfield
                        class="w-100 mt-3"
                        a_type="text"
                        placeholder="Input here..."
                        label="
                            Group Name
                            <span style='font-size: 10px;
                                letter-spacing: 1.16px;
                                color: #FFFFFF;
                                background: #F5686C;
                                border: 1px solid #383A3D26;
                                border-radius: 4px;
                                padding: 1px 4px;'
                                title='Required'
                            >REQ</span>
                        "
                        v-model="create_group.group_name"
                        style="text-align: left;"
                        :state="create_group.is_valid ? '' : 'invalid'"
                        :err_class="create_group.is_valid ? '' : 'error-text'"
                        @keyup.enter.native="group_checkAvailability(create_group.group_name)"
                        :err_txt="!create_group.is_valid ? create_group.err_txt : ''"
                        :disabled="step == 1.1 ? true : false"
                    ></ar-textfield>
                    <img v-if="step == 1.1" @click="step = 1;" style="margin-bottom: 18px;height: 36px;padding-top:2px;cursor: pointer;" src="/static/svg/Campaign_Edit.svg" title="Edit Group Name" alt="Edit Group">
                </div>
                <!-- style="padding-right: 0px !important;" -->
                <div
                    class="w-100 mt-4 h-100"
                >
                    <template v-if="step == 1">
                        <div class="check-description">
                            <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">Before Adding,</label>
                            <p style="font-size: 16px;">let us check if the inputted group already existed in the database.</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-start mt-3" style="gap:4px;">
                            <ar-button
                                :disabled="create_group.group_name == ''"
                                @click="group_checkAvailability(create_group.group_name)"
                            >
                                Check Availability
                            </ar-button>
                            <ar-button
                                @click="step=0"
                                outlined
                            >
                                Cancel
                            </ar-button>
                        </div>
                    </template>
                    <!-- When Group Name is Available -->
                    <template v-if="step == 1.1">
                        <div class="d-flex flex-column align-items-center justify-content-between" style="height: 325px;">
                            <div class="w-100">
                                <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">New Custom Group name is available,</label>
                                <p style="font-size: 16px;">click the add button to proceed.</p>
                            </div>
                            <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                                <ar-button
                                    style="width: 100%;"
                                    @click="addGroup(create_group.group_name)"
                                    :disabled="create_group.disable_adding"
                                >
                                    Proceed Adding
                                </ar-button>
                                <a href="javascript:void(0)" @click="step = 1;create_group.group_name = ''" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Please cancel the action</a>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <!-- update group -->
            <div
                v-if="Math.floor(step) == 2"
                style="height: 100%;width: 100%;gap: 50px;"
                class="d-flex align-items-center justify-content-center"
            >
                <!-- Use the selected_group -->
                 
                <!-- Left Side -->
                <div class="h-100 position-relative" style="width: 382px;">
                    <!-- header -->
                    <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 2px;border-bottom: 1px solid #7070703B;">
                        <p class="m-0" style="font-size: 10px;color: #601848;font-weight: bold;">UPDATE GROUP</p>
                        <div class="d-flex align-items-center justify-content-start w-100 text-left" style="gap: 8px;">
                            <img style="width: 20px; height: 20px;" src="/static/svg/admin/Auto Campaign Assignment/update group.svg" alt="Group Icon">
                            <p class="m-0 w-100" :title="selected_group?.name" style="font-size: 24px;color: #383A3D;font-weight: bold;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;">{{ selected_group?.name }}</p>
                        </div>
                    </div>
                    <div class="row align-items-center text-left mt-4">
                        <ar-textfield 
                            placeholder="Input here"
                            label="Group Name
                                <span style='font-size: 10px;
                                    letter-spacing: 1.16px;
                                    color: #FFFFFF;
                                    background: #F5686C;
                                    border: 1px solid #383A3D26;
                                    border-radius: 4px;
                                    padding: 1px 4px;'
                                    title='Required'
                                >REQ</span>"
                            type="text"
                            class="col-md-7"
                            v-model.trim="selected_group.name"
                            disabled
                        />
                        <div class="col-md-5">
                            <ar-button
                                class="p-2 mt-3"
                                outlined
                                @click="step = 3"
                            >
                                <!-- @click="changeGroupName()" -->
                                Change Name
                            </ar-button>
                        </div>
                    </div>
                    <div class="w-100 mt-3" style="font-size: 14px;color:#515365;">
                        <ar-checkbox
                            id="add-schedule-group-edit"
                            name="add-schedule-group-edit"
                            :checked="update_group.hasNoSchedule"
                            @change="!update_group.hasNoSchedule ? update_group.hasNoSchedule = true : update_group.hasNoSchedule = false"
                            label="Don't Set Schedule for this Group"
                            label_style="vertical-align: middle;"
                            a_class="d-flex justify-content-start"
                        ></ar-checkbox>
                        <!-- <p class="m-0">Include the Assigned Recipients.</p> -->
                    </div>
                    <div
                        class="d-flex flex-column align-items-start justify-content-start position-relative mt-4"
                        style="gap: 20px;"
                        :style="[ 
                            update_group.hasNoSchedule ? {'opacity': '50%'} : {'opacity':'100%'}
                        ]"
                    >
                        <div class="d-flex align-items-center justify-content-start" style="gap:5px;">
                            <p class="m-0" style="font-size: 14px;color: #515365;margin-left: 5px !important;">Assign Schedule</p>
                            <span style='font-size: 10px;
                                letter-spacing: 1.16px;
                                color: #FFFFFF;
                                background: #F5686C;
                                border: 1px solid #383A3D26;
                                border-radius: 4px;
                                padding: 1px 4px;
                                font-size: 10px;
                                font-weight: bold;'
                                title='Required'                                
                            >REQ</span>
                        </div>
                        <div class="row g-3 w-100">
                            <ArCheckList 
                                v-for="(day, index) in schedules_options"
                                :key="index"
                                :id="`select-schedule--${day.value}`"
                                :name="`select-schedule--checklist`"
                                :value="day.value"
                                :is-item="false"
                                class="position-relative d-flex justify-content-start col-md-6"
                                :label="day.label"
                                style="height: 16px; margin: 10px 0px;"
                                v-model="update_group.schedules"
                                :disabled="update_group.hasNoSchedule"
                            />
                        </div>
                    </div>
                    <a 
                        href="javascript:void(0)"
                        @click="step = 0;create_group.group_name = '';resetUpdateGroupValues()"
                        style="
                            letter-spacing: 1.25px;
                            font-size: 16px;
                            color:#383A3D;
                            font-weight: 500;
                            text-decoration:underline;
                            position: absolute;
                            bottom: 0px;
                            left: 20px;
                        "
                    >Please cancel the action</a>
                </div>

                <!-- Center -->
                <div class="h-100 position-relative text-left" style="width: 382px;">
                    <div class="row">
                        <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 12px;">
                            <p class="m-0" style="color: #515365;font-size: 12px;">This feature allows an administrator to set the platform and campaign combinations which will be assigned to researchers randomly.</p>
                            <hr class="mt-0 mb-3 w-100" style="border-style: dashed;"/>
                            <span class="d-flex align-items-start mb-3 w-100" style="padding: 15px;border: 1px solid #601848;border-radius: 0 8px 8px 0;color: #601848;background-color: #E5AD930F;">
                                <i class="fas fa-info-circle mr-2"/>
                                <h6 class="mb-0" style="font-size: 12px;"><b>These combinations have different values.</b> <span class="d-block mt-1">You can set unified values.</span></h6>
                            </span>
                        </div>
                        <div class="col-12 mb-3 mt-1">
                            <div class="d-flex align-items-center justify-content-start" style="gap: 10px;">
                                <ar-checkbox
                                    id="group-fallback"
                                    name="group-fallback"
                                    :checked="update_group.fallback == 0 ? false : true"
                                    @change="update_group.fallback == 0 ? update_group.fallback = 1 : update_group.fallback = 0"
                                    label="Is this combo a Fallback?"
                                    label_style="vertical-align: middle;"
                                ></ar-checkbox>
                                <!-- <span style='font-size: 10px;
                                    letter-spacing: 1.16px;
                                    color: #FFFFFF;
                                    background: #F5686C;
                                    border: 1px solid #383A3D26;
                                    border-radius: 4px;
                                    padding: 1px 4px;'
                                    title='Required'
                                >REQ</span> -->
                            </div>
                        </div>
                        <ar-textfield
                            class="col-6"
                            a_type="number"
                            label="Target"
                            placeholder="Input number here..."
                            style="padding-left: 0px !important;"
                            v-model="update_group.target"
                            :min="0"
                            :state="update_group.fallback == false && update_group.target == 0 && update_group.priority != 'URGENT' ? 'invalid' : ''"
                            :err_txt="update_group.fallback == false && update_group.target == 0 && update_group.priority != 'URGENT' ? `Invalid value for not fallback`:''"
                            err_style="color: #DC3C40; margin-left: 0"
                        ></ar-textfield>
                        <ar-textfield
                            class="col-6"
                            a_type="number"
                            placeholder="Input number here..."
                            label="
                                No. of Researchers
                                <span style='font-size: 10px;
                                    letter-spacing: 1.16px;
                                    color: #FFFFFF;
                                    background: #F5686C;
                                    border: 1px solid #383A3D26;
                                    border-radius: 4px;
                                    padding: 1px 4px;'
                                    title='Required'
                                >REQ</span>
                            "
                            :min="0"
                            style="padding-right: 0px !important;"
                            v-model="update_group.no_of_researchers"
                        ></ar-textfield>
                        <div class="col-12" style="padding: 0px !important;">
                            <label for="" v-html="`
                                    Priority Level
                                    <span style='font-size: 10px;
                                        letter-spacing: 1.16px;
                                        color: #FFFFFF;
                                        background: #F5686C;
                                        border: 1px solid #383A3D26;
                                        border-radius: 4px;
                                        padding: 1px 4px;'
                                        title='Required'
                                    >REQ</span>`"
                                style="color: #383A3D;
                                    margin-left: 5px;
                                    font-weight: 500;
                                    font-size: 13px;
                                    margin-bottom: 12px;
                                "
                            >
                            </label>
                            <div class="d-flex align-items-center justify-content-start" style="gap: 14px">
                                <ar-radio
                                    v-for="(group_pl, index) in priority_level_options"
                                    :key="index"
                                    :label="group_pl.label"
                                    :id="`group-priority-level-${index}`"
                                    class="sm"
                                    name="
                                        Priority Level
                                    "
                                    @change="update_group.priority = group_pl.value"
                                    :checked="update_group.priority == group_pl.value"
                                ></ar-radio>
                            </div>
                        </div>
                    </div>
                    <ar-button
                        class="w-100 position-absolute"
                        style="bottom: 0px;"
                        @click="updateGroup()"
                        :disabled="!isValidForAddingGroup"
                    >
                        <!-- :disabled="!isValidForAddingCombo" -->
                        <!-- @click="hasClickedEdit ? updateCombo() : addCombo()" -->
                        <!-- {{ hasClickedEdit ? 'Proceed Updating' : 'Proceed Adding' }} -->
                        Proceed Updating
                    </ar-button>
                </div>

                <!-- Right Side -->
                <div class="h-100 text-left" style="width: 382px;border-left: 1px solid #00000029;padding-left: 20px;">
                    <p style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;">Showing {{ selected_group?.assignments?.length }} Combos</p>
                    <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 10px;">
                        <span class="d-flex align-items-start mb-3 w-100" style="padding: 12px;border: 1px solid #601848;border-radius: 0 8px 8px 0;color: #601848;background-color: #E5AD930F;">
                            <i class="fas fa-info-circle mr-2"/>
                            <h6 class="mb-0" style="font-size: 12px;"><b>Please Add Combinations below for this group.</b> <span class="d-block mt-1">Now showing combinations that are not in a group.</span></h6>
                        </span>
                    </div>
                    <div class="row position-relative" style="border-bottom: 1px solid #00000029;margin-left:8px;width: calc(100% - 24px);">
                        <ar-select
                            ref="adminSelectCombinationsInUpdateGroup"
                            id="update-group-select-combinations"
                            type="search"
                            class="col-10"
                            style="padding: 0px !important;margin: 0px;"
                            label="
                                Select Combinations
                            "
                            :options="filtered_mapped_all_combos"
                            v-model="update_group.add_combo"
                            placeholder="Select Combo here..."
                            :resetValue="selectCombo_resetValue"
                        ></ar-select>
                            <!-- :default_val="{label: campaigns.filter(c => c.id == create_auto_assign.campaign)[0]?.name, value: create_auto_assign.campaign}" -->
                        <img 
                            @click="update_group.add_combo == -1 || update_group.add_combo == 0 ? '' : addUpdate_selectedCombinations(update_group.add_combo)"
                            :disabled="update_group.add_combo == -1 || update_group.add_combo == 0"
                            :style="[update_group.add_combo == -1 || update_group.add_combo == 0 ? {'opacity':'50%'} : {'opacity':'100%','cursor':'pointer'}]"
                            style="height: 36px;width: 36px;position: absolute;top: 58%;right: 0;transform: translateY(-50%);"
                            src="/static/svg/admin/Auto Campaign Assignment/Add.svg" alt="Add Button"
                        >
                        <!-- @click="update_group.add_combo == -1 || update_group.add_combo == 0 ? '' : addGroupToCombo(update_group.add_combo)" -->
                    </div>
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding: 10px 15px;overflow:auto;height: 270px;">
                        <!-- calc(100% - 220px) -->
                        <div style="min-height: 42px;height: auto;max-height: 80px;width: 100%;">
                            <!-- v-for="(assignment, index) in selected_group?.assignments" -->
                            <div
                                v-for="(assignment, index) in update_group?.selected_combinations"
                                :key="index"
                                class="combo-assignments d-flex flex-column align-items-center justify-content-start"
                                @mouseover="update_group.isHovered_assignment = assignment.id"
                                @mouseleave="update_group.isHovered_assignment = -1"
                                :style="[selected_forDelete.id == assignment.id ? {'height':'100%'}:{'height':'42px'}]"
                            >
                                <!-- :class="{'isHovered': update_group.isHovered_assignment}" -->
                                <div class="w-100 d-flex align-items-center justify-content-between" style="height: 42px;">
                                    <div :style="[update_group.isHovered_assignment == assignment.id ? {'opacity': '50%'} : {'opacity':'100%'} ]" class="d-flex align-items-center justify-content-start" style="gap: 10px;width: 100%;">
                                        <img :src="$parent.getImage('campaigns',getCampaign(assignment.campaign_id)?.name || 'Default')" :alt="getCampaign(assignment.campaign_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getCampaign(assignment.campaign_id)?.name }}
                                    </div>
                                    <div :style="[update_group.isHovered_assignment == assignment.id ? {'margin-right': '0px','opacity': '50%'} : {'margin-right':'50px','opacity':'100%'} ]" class="d-flex align-items-center justify-content-end"
                                        style="
                                            gap: 4px;
                                            width: 100%;
                                            border:1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 4px !important;
                                            width: fit-content;
                                            text-transform: uppercase;
                                            font-size: 10px;
                                            letter-spacing: -0.2px;
                                        "
                                    >
                                        <img :src="$parent.getImage('platforms',getPlatform(assignment.platform_id)?.name || 'Default')" :alt="getPlatform(assignment.platform_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getPlatform(assignment.platform_id)?.name }}
                                    </div>
                                    <img
                                        v-if="update_group.isHovered_assignment == assignment.id"
                                        @click="isClickedAssignment(assignment) ? selected_forDelete = {} : selected_forDelete = assignment;"
                                        style="width: 40px; height: 40px;margin-left: 10px;opacity: 100% !important;cursor: pointer;"
                                        src="/static/svg/3month-report/Delete-report-circle.svg"
                                        alt="Delete Icon"
                                    >
                                    <!-- openModal('remove-combination-in-group-modal'); -->
                                </div>
                                <div v-if="selected_forDelete.id == assignment.id" class="d-flex align-items-center justify-content-between w-100" style="gap: 5px;padding: 5px 10px;height: 40px;">
                                    <p class="m-0" style="color: #2D599ECC;font-size: 12px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Assigned Combo?</p>
                                    <!-- <p class="m-0" style="font-size: 12px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p> -->
                                    <div class="d-flex align-items-center justify-content-end" style="gap: 15px;">
                                        <div @click="selected_forDelete = {}" title="Click to Cancel Removing Combo" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid red;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_No.svg" alt="X Icon" >
                                        </div>
                                        <!-- <div @click="addGroupToCombo(assignment.id,true)" title="Click to Remove Combo" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                        </div> -->
                                        <div @click="addUpdate_selectedCombinations(assignment.id,'remove')" title="Click to Remove Combo" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                        </div>
                                    </div>
                                </div>
                                <hr v-if="selected_group?.assignments?.length - index > 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Change Name -->
            <div 
                v-if="Math.floor(step) == 3"
                style="height: 100%;width: 100%;gap: 50px;"
                class="d-flex align-items-center justify-content-center"
            >
                <!-- Left Side -->
                <div class="h-100 position-relative" style="width: 382px;">
                    <div class="d-flex flex-column align-items-start justify-content-start" style="gap: 2px;border-bottom: 1px solid #7070703B;">
                        <p class="m-0" style="font-size: 10px;color: #601848;font-weight: bold;">UPDATE GROUP</p>
                        <div class="d-flex align-items-center justify-content-start" style="gap: 8px;">
                            <img style="width: 20px; height: 20px;" src="/static/svg/admin/Auto Campaign Assignment/update group.svg" alt="Group Icon">
                            <p class="m-0" style="font-size: 24px;color: #383A3D;font-weight: bold;">{{ selected_group.name }}</p>
                        </div>
                    </div>
                    <ar-textfield
                        class="mt-3"
                        a_type="text"
                        placeholder="Input here..."
                        label="
                            Group Name
                            <span style='font-size: 10px;
                                letter-spacing: 1.16px;
                                color: #FFFFFF;
                                background: #F5686C;
                                border: 1px solid #383A3D26;
                                border-radius: 4px;
                                padding: 1px 4px;'
                                title='Required'
                            >REQ</span>
                        "
                        v-model="update_group.name"
                        style="text-align: left;width:300px;"
                        :state="update_group.is_valid ? '' : 'invalid'"
                        @keyup.enter.native="group_checkAvailability(update_group.name,'is_changeName')"
                        :disabled="step == 3.2 ? true : false"
                    ></ar-textfield>
                        <!-- :err_class="update_group.name != selected_group.name ? '' : 'error-text'" -->
                        <!-- :err_txt="!update_group.is_valid ? update_group.err_txt : ''" -->
                    <!-- style="padding-right: 0px !important;" -->
                    <div
                        class="w-100 mt-4 h-100"
                    >
                        <template v-if="step == 3">
                            <div class="check-description text-left">
                                <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">Before Updating,</label>
                                <p style="font-size: 16px;">let us check if the inputted group already existed in the database.</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-start mt-3" style="gap:4px;">
                                <ar-button
                                    :disabled="update_group.name == ''"
                                    @click="group_checkAvailability(update_group.name, 'is_changeName')"
                                >
                                    Check Availability
                                </ar-button>
                                <ar-button
                                    @click="step=2"
                                    outlined
                                >
                                    Cancel
                                </ar-button>
                            </div>
                        </template>
                        <!-- When Group Name is not Available -->
                        <template v-if="step == 3.1">
                            <div class="check-description text-left">
                                <label class="mb-0 d-block" style="color: #F5686C; font-size: 16px;">Group name already in Database,</label>
                                <p style="font-size: 16px;">you can't update this, please try other new Group name.</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-start mt-3" style="gap:4px;">
                                <ar-button
                                    :disabled="update_group.name == ''"
                                    a_class="btn-invalid"
                                    @click="step = 3"
                                >
                                    Try Other Group name
                                </ar-button>
                            </div>
                        </template>
                        <!-- When Group Name is Available -->
                        <template v-if="step == 3.2">
                            <div class="check-description text-left w-100" style="height: 45px !important;">
                                <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">New Group name is available,</label>
                                <p style="font-size: 16px;">click the update button to proceed.</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-start mt-3" style="gap:4px;">
                                <ar-button
                                    :disabled="update_group.name == ''"
                                    @click="updateGroup(true)"
                                >
                                    Update Name
                                </ar-button>
                                <ar-button
                                    @click="step=2"
                                    outlined
                                >
                                    Cancel
                                </ar-button>
                            </div>
                        </template>
                    </div>
                </div>
                
                <!-- Right Side -->
                <div class="h-100 text-left" style="width: 382px;border-left: 1px solid #00000029;padding-left: 20px;">
                    <p style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;">Showing {{ selected_group?.assignments?.length }} Combos</p>
                    
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding: 10px 15px;overflow:auto;height: calc(100% - 45px);">
                        <!-- calc(100% - 220px) -->
                        <div style="min-height: 42px;height: auto;max-height: 80px;width: 100%;">
                            <div
                                v-for="(assignment, index) in selected_group?.assignments"
                                :key="index"
                                class="combo-assignments d-flex flex-column align-items-center justify-content-start"
                                :style="[selected_forDelete.id == assignment.id ? {'height':'100%'}:{'height':'42px'}]"
                            >
                                <!-- :class="{'isHovered': update_group.isHovered_assignment}" -->
                                <div class="w-100 d-flex align-items-center justify-content-between" style="height: 42px;">
                                    <div :style="[update_group.isHovered_assignment == assignment.id ? {'opacity': '50%'} : {'opacity':'100%'} ]" class="d-flex align-items-center justify-content-start" style="gap: 10px;width: 100%;">
                                        <img :src="$parent.getImage('campaigns',getCampaign(assignment.campaign_id)?.name || 'Default')" :alt="getCampaign(assignment.campaign_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getCampaign(assignment.campaign_id)?.name }}
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end"
                                        style="
                                            gap: 4px;
                                            width: 100%;
                                            border:1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 4px !important;
                                            width: fit-content;
                                            text-transform: uppercase;
                                            font-size: 10px;
                                            letter-spacing: -0.2px;
                                            margin-right: 0px;
                                        "
                                    >
                                        <img :src="$parent.getImage('platforms',getPlatform(assignment.platform_id)?.name || 'Default')" :alt="getPlatform(assignment.platform_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getPlatform(assignment.platform_id)?.name }}
                                    </div>
                                </div>
                                <hr v-if="selected_group?.assignments.length - index > 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add this to Group / Multiple Adding of Combo to a Group -->
            <div 
                v-if="Math.floor(step) == 4"
                style="height: 100%;width: 100%;gap: 50px;"
                class="d-flex align-items-center justify-content-center"
            >
                <div 
                    class="d-flex flex-column align-items-start justify-content-start"
                    style="gap: 15px;width:332px;"
                    v-if="step == 4"
                >
                    <div
                        class="group-combination-view-title w-100"
                    >
                        <h4 class="m-0">Group Combination</h4>
                        <p class="m-0" style="font-size: 12px;">Select an existing group or add a new group</p>
                    </div>
                    <ar-textfield 
                        placeholder         ="Search Group here..." 
                        type                ="text"
                        a_class             ="mr-1"
                        a_style             ="width: 100%;"
                        :icon               ="manage_group.search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                        v-model             ="manage_group.search"
                        class="w-100 m-0"
                    />
                    <div
                        class="group-combination-group-container"
                        style="margin-top: 15px;"
                    >
                        <div
                            v-for="(group, index) in filtered_groups"
                            :key="index"
                            class="group-combination-group-item"
                        >
                            <div
                                class="group-combination-group-item-item"
                                style="gap: 12px;"
                                @click="isClickedGroup(group) ? selected_group = {} : selected_group = group"
                                @mouseover="hovered_group = group"
                                @mouseleave="hovered_group = {}"
                                :class="{'isClicked': isClickedGroup(group), 'isHovered': isHoveredGroup(group)}"
                            >
                                <img style="width: 24px; height: 24px;" src="/static/svg/admin/Auto Campaign Assignment/folder-gray.svg" alt="Group Folder SVG">
                                <div class="d-flex flex-column align-items-start justify-content-start">
                                    <p class="m-0" style="font-size: 14px;letter-spacing: 0.24px;">
                                        {{ group.name }}
                                    </p>
                                    <p class="m-0" style="font-size: 10px;letter-spacing: 0.17px;font-weight: normal !important;">
                                        {{ group.assignments.length ? `${group.assignments.length} Number of Combination(s)` : 'No Combinations for this Group' }} 
                                    </p>
                                </div>
                                <i v-if="isClickedGroup(group)" class="fas fa-check-circle click-check"></i>
                            </div>
                            <div v-if="selected_forDelete_group.id == group.id" class="d-flex align-items-center justify-content-between w-100" style="gap: 5px;padding: 5px 10px;height: 40px;">
                                <p class="m-0" style="color: #2D599ECC;font-size: 12px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Group?</p>
                                <!-- <p class="m-0" style="font-size: 12px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p> -->
                                <div class="d-flex align-items-center justify-content-end" style="gap: 15px;">
                                    <div @click="selected_forDelete_group = {};selected_group = {};" title="Click to Cancel Removing Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid red;padding: 5px;border-radius:100%;">
                                        <img class="w-100 h-100" src="/static/svg/Checklist_No.svg" alt="X Icon" >
                                    </div>
                                    <div @click="removeGroup(group.id)" title="Click to Remove Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                        <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                    </div>
                                </div>
                            </div>
                            <hr v-if="groups.length - index != 1">
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <ar-button
                            style="width: 285px;vertical-align: middle;"
                            @click="step = 4.1;"
                            :disabled="selected_group == {} || selected_group == undefined"
                        >
                            <i class="far fa-star"></i>
                            Create a new group
                        </ar-button>
                    </div>
                </div>

                <!-- create a new group in group addition -->
                <div
                    v-if="step.toString().includes('4.1')"
                    class="d-flex flex-column align-items-start justify-content-start h-100"
                    style="gap: 15px;width:332px;text-align: left;"
                >
                    <div
                        class="create-new-custom-group-title w-100"
                    >
                        <h4 class="m-0">Create a New Custom Group</h4>
                        <p class="m-0" style="font-size: 12px;">Fill in the required fields.</p>
                    </div>
                    <ar-textfield
                        class="w-100"
                        a_type="text"
                        placeholder="Input here..."
                        label="
                            Group Name
                            <span style='font-size: 10px;
                                letter-spacing: 1.16px;
                                color: #FFFFFF;
                                background: #F5686C;
                                border: 1px solid #383A3D26;
                                border-radius: 4px;
                                padding: 1px 4px;'
                                title='Required'
                            >REQ</span>
                        "
                        v-model="create_group.group_name"
                        style="text-align: left;"
                        :state="create_group.is_valid ? '' : 'invalid'"
                        :err_class="create_group.is_valid ? '' : 'error-text'"
                        @keyup.enter.native="group_checkAvailability(create_group.group_name, 'group_combination')"
                        :err_txt="!create_group.is_valid ? create_group.err_txt : ''"
                        :disabled="step == 4.12 ? true : false"
                    ></ar-textfield>
                    <!-- style="padding-right: 0px !important;" -->
                    <div
                        class="w-100 h-100"
                    >
                        <template v-if="step == 4.1">
                            <div class="check-description">
                                <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">Before Adding,</label>
                                <p style="font-size: 16px;">let us check if the inputted group already existed in the database.</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-start mt-3" style="gap:4px;">
                                <ar-button
                                    :disabled="create_group.group_name == ''"
                                    @click="group_checkAvailability(create_group.group_name, 'group_combination')"
                                >
                                    Check Availability
                                </ar-button>
                                <ar-button
                                    @click="step=4"
                                    outlined
                                >
                                    Cancel
                                </ar-button>
                            </div>
                        </template>
                        <!-- When Group Name is Available -->
                        <template v-if="step == 4.12">
                            <div class="d-flex flex-column align-items-center justify-content-between" style="height: 325px;">
                                <div class="w-100">
                                    <label class="mb-0 d-block" style="color: #601848; font-size: 16px;">New Custom Group name is available,</label>
                                    <p style="font-size: 16px;">click the add button to proceed.</p>
                                </div>
                                <div class="w-100 d-flex flex-column align-items-center justify-content-start" style="gap: 20px;margin-top: 25px;">
                                    <ar-button
                                        style="width: 100%;"
                                        @click="addGroup(create_group.group_name, 'group_combination')"
                                    >
                                        Proceed Adding
                                    </ar-button>
                                    <a href="javascript:void(0)" @click="step = 1;create_group.group_name = ''" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Please cancel the action</a>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                
                <!-- Right Side -->
                <div class="h-100 text-left" style="width: 540px;border-left: 1px solid #00000029;padding-left: 20px;">
                    <p style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;">Selected {{ selectedCombosClean?.length }} Combination(s) to be added to a group</p>
                    <hr>
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding: 10px 15px;overflow:auto;height: calc(100% - 105px);margin-bottom: 20px;">
                        <!-- calc(100% - 220px) -->
                        <div style="min-height: 42px;height: auto;max-height: 80px;width: 100%;">
                            <div
                                v-for="(combo, index) in selectedCombosValues"
                                :key="index"
                                class="combo-assignments d-flex flex-column align-items-center justify-content-start"
                                :style="[selected_forDelete.id == combo.id ? {'height':'100%'}:{'height':'42px'}]"
                            >
                                <!-- :class="{'isHovered': update_group.isHovered_assignment}" -->
                                <div class="w-100 d-flex align-items-center justify-content-between" style="height: 42px;">
                                    <div class="d-flex align-items-center justify-content-start" style="gap: 10px;">
                                        <div :style="[update_group.isHovered_assignment == combo.id ? {'opacity': '50%'} : {'opacity':'100%'} ]" class="d-flex align-items-center justify-content-start" style="gap: 10px;width: 100%;">
                                            <img :src="$parent.getImage('campaigns',getCampaign(combo.campaign_id)?.name || 'Default')" :alt="getCampaign(combo.campaign_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                            {{ getCampaign(combo.campaign_id)?.name }}
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end"
                                            style="
                                                gap: 4px;
                                                width: 100%;
                                                border:1px solid #383A3D26;
                                                border-radius: 4px;
                                                padding: 4px !important;
                                                width: fit-content;
                                                text-transform: uppercase;
                                                font-size: 10px;
                                                letter-spacing: -0.2px;
                                                margin-right: 0px;
                                            "
                                        >
                                            <img :src="$parent.getImage('platforms',getPlatform(combo.platform_id)?.name || 'Default')" :alt="getPlatform(combo.platform_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                            {{ getPlatform(combo.platform_id)?.name }}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <template v-if="combo?.assignment_group_id == null || combo?.assignment_group_id == null">
                                            <p class="m-0" style="color: #383A3D;text-transform: uppercase;font-size: 10px;opacity:60%;letter-spacing: -0.2px;padding-right: 4px;">
                                                NOT IN A GROUP
                                            </p>
                                        </template>
                                        <template v-else>
                                            <div class="dropdown d-inline-block">
                                                <span class="d-flex align-items-center justify-content-center w-100" style="border:1px solid #383A3D26;border-radius: 4px;padding: 4px;gap: 4px;">
                                                    <img style="width: 12px; height: 12px;" src="/static/svg/admin/Auto Campaign Assignment/orange_folder.svg" alt="Group Folder Icon">
                                                    <p class="m-0 w-100" :title="getGroupBy_Id(combo.assignment_group_id)?.name" style="color: #383A3D;text-transform: uppercase;font-size: 10px;letter-spacing: -0.2px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;">
                                                        {{ getGroupBy_Id(combo.assignment_group_id)?.name }}
                                                    </p>
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <hr v-if="selectedCombosValues.length - index > 1">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end" style="gap: 8px;">
                        <ar-button
                            a_class="mr-2"
                            data-dismiss="modal"
                            outlined
                        >
                            <!-- @click="resetActionOptionsData();" -->
                            Cancel
                        </ar-button>
                        <ar-button
                            v-if="selected_group?.id != undefined"
                            style="width: 300px;vertical-align: middle;"
                            @click="selectedCombos_hasGroup ? openModal('bulk-transfer-to-group-confirmation') : multiple_addGroupToCombo(selectedCombosClean) "
                        >
                            <!-- @click="multiple_addGroupToCombo(selectedCombosClean)" -->
                            <!-- style="width: 24px; height: 24px;" -->
                            <img  src="/static/svg/admin/Auto Campaign Assignment/manage groups.svg" alt="Group Folder SVG">
                            <!-- <i class="far fa-star"></i> -->
                            Group Selected Combination(s)
                        </ar-button>
                    </div>
                </div>
            </div>
            <!-- Move to Another Group -->
            <div
                v-if="Math.floor(step) == 5"
                style="height: 100%;width: 100%;gap: 50px;"
                class="d-flex align-items-center justify-content-center"
            >
                <div 
                    class="d-flex flex-column align-items-start justify-content-start"
                    style="gap: 15px;width:332px;"
                    v-if="step == 5"
                >
                    <div
                        class="group-combination-view-title w-100"
                    >
                        <h4 class="m-0">Move to Another Group</h4>
                        <p class="m-0" style="font-size: 12px;">Select an existing custom group to move.</p>
                    </div>
                    <ar-textfield 
                        placeholder         ="Search Group here..." 
                        type                ="text"
                        a_class             ="mr-1"
                        a_style             ="width: 100%;"
                        :icon               ="manage_group.search.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                        v-model             ="manage_group.search"
                        class="w-100 m-0"
                    />
                    <div
                        class="group-combination-group-container"
                        style="margin-top: 15px;"
                    >
                        <div
                            v-for="(group, index) in filtered_groups"
                            :key="index"
                            class="group-combination-group-item"
                        >
                            <div
                                class="group-combination-group-item-item"
                                style="gap: 12px;"
                                @click="isClickedGroup(group) ? selected_group = {} : selected_group = group"
                                @mouseover="hovered_group = group"
                                @mouseleave="hovered_group = {}"
                                :class="{'isClicked': isClickedGroup(group), 'isHovered': isHoveredGroup(group)}"
                            >
                                <img style="width: 24px; height: 24px;" src="/static/svg/admin/Auto Campaign Assignment/folder-gray.svg" alt="Group Folder SVG">
                                <div class="d-flex flex-column align-items-start justify-content-start">
                                    <p class="m-0" style="font-size: 14px;letter-spacing: 0.24px;">
                                        {{ group.name }}
                                    </p>
                                    <p class="m-0" style="font-size: 10px;letter-spacing: 0.17px;font-weight: normal !important;">
                                        {{ group.assignments.length ? `${group.assignments.length} Number of Combination(s)` : 'No Combinations for this Group' }} 
                                    </p>
                                </div>
                                <i v-if="isClickedGroup(group)" class="fas fa-check-circle click-check"></i>
                            </div>
                            <div v-if="selected_forDelete_group.id == group.id" class="d-flex align-items-center justify-content-between w-100" style="gap: 5px;padding: 5px 10px;height: 40px;">
                                <p class="m-0" style="color: #2D599ECC;font-size: 12px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Group?</p>
                                <!-- <p class="m-0" style="font-size: 12px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p> -->
                                <div class="d-flex align-items-center justify-content-end" style="gap: 15px;">
                                    <div @click="selected_forDelete_group = {};selected_group = {};" title="Click to Cancel Removing Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid red;padding: 5px;border-radius:100%;">
                                        <img class="w-100 h-100" src="/static/svg/Checklist_No.svg" alt="X Icon" >
                                    </div>
                                    <div @click="removeGroup(group.id)" title="Click to Remove Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                        <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                    </div>
                                </div>
                            </div>
                            <hr v-if="groups.length - index != 1">
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <ar-button
                            style="width: 285px;vertical-align: middle;"
                            :disabled="selected_group?.id == undefined || selected_group?.id == null"
                            @click="moveToAnotherGroup()"
                        >
                            <!-- <i class="far fa-star"></i> -->
                            Move to this Group
                        </ar-button>
                    </div>
                </div>
                <!-- Right Side -->
                <div class="h-100 text-left" style="width: 540px;border-left: 1px solid #00000029;padding-left: 20px;">
                    <p style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;">To be transferred Combo</p>
                    <hr>
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding: 10px 15px;overflow:auto;height: calc(100% - 105px);margin-bottom: 20px;">
                        <!-- calc(100% - 220px) -->
                        <div style="min-height: 42px;height: auto;max-height: 80px;width: 100%;">
                            <div
                                v-for="(combo, index) in moveTo_anotherGroup.selected_combo"
                                :key="index"
                                class="combo-assignments d-flex flex-column align-items-center justify-content-start"
                                :style="[selected_forDelete.id == combo.id ? {'height':'100%'}:{'height':'42px'}]"
                            >
                                <!-- :class="{'isHovered': update_group.isHovered_assignment}" -->
                                <div class="w-100 d-flex align-items-center justify-content-between" style="height: 42px;">
                                    <div :style="[update_group.isHovered_assignment == combo.id ? {'opacity': '50%'} : {'opacity':'100%'} ]" class="d-flex align-items-center justify-content-start" style="gap: 10px;width: 100%;">
                                        <img :src="$parent.getImage('campaigns',getCampaign(combo.campaign_id)?.name || 'Default')" :alt="getCampaign(combo.campaign_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getCampaign(combo.campaign_id)?.name }}
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end"
                                        style="
                                            gap: 4px;
                                            width: 100%;
                                            border:1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 4px !important;
                                            width: fit-content;
                                            text-transform: uppercase;
                                            font-size: 10px;
                                            letter-spacing: -0.2px;
                                            margin-right: 0px;
                                        "
                                    >
                                        <img :src="$parent.getImage('platforms',getPlatform(combo.platform_id)?.name || 'Default')" :alt="getPlatform(combo.platform_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getPlatform(combo.platform_id)?.name }}
                                    </div>
                                </div>
                                <hr v-if="selectedCombosValues.length - index > 1">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="d-flex align-items-center justify-content-end" style="gap: 8px;">
                        <ar-button
                            a_class="mr-2"
                            data-dismiss="modal"
                            outlined
                        >
                            Cancel
                        </ar-button>
                        <ar-button
                            v-if="selected_group?.id != undefined"
                            style="width: 300px;vertical-align: middle;"
                            @click="multiple_addGroupToCombo(selectedCombosClean)"
                        >
                            <img  src="/static/svg/admin/Auto Campaign Assignment/manage groups.svg" alt="Group Folder SVG">
                            Group Selected Combination(s)
                        </ar-button>
                    </div> -->
                </div>
            </div>
        </ar-dialog>
        <ar-dialog
            id="group-schedule-report"
            custom_width="fit-content"
            custom_minwidth="415px"
            xLarge
            :has_button="false"
            :has_footer="false"
            dialog_style="padding: 36px 40px;height: 625px;"
            mbClass="no-padding"
            a_class="will-not-close-when-clicked-outside"
        >
            <div class="d-flex align-items-center justify-content-start h-100 w-100" style="gap: 20px;">
                <!-- Left side -->
                <div 
                    class="d-flex flex-column align-items-start justify-content-start"
                    style="gap: 15px;"
                >
                    <div
                        class="scheduling-report-title w-100"
                    >
                        <h4 class="m-0">Scheduling Report</h4>
                        <p class="m-0" style="font-size: 12px;">Select users to receive weekly report.</p>
                    </div>
                    <div class="h-100 text-left" style="width: 382px;">
                        <div class="row position-relative" style="border-bottom: 1px solid #00000029;margin-left:0px;width: 100%;">
                            <ar-select
                                id="selection-scheduling-report"
                                type="search"
                                class="col-10"
                                style="padding: 0px !important;margin: 0px;"
                                label=""
                                :options="filtered_mapped_users"
                                v-model="scheduling_report.add_user"
                                placeholder="Select User here..."
                                :resetValue="selectUsers_resetValue"
                                :disabled="users_copy.length == 0"
                            ></ar-select>
                                <!-- @click="scheduling_report.add_user == -1 || scheduling_report.add_user == 0 ? '' : addGroupToCombo(scheduling_report.add_user)" -->
                            <!-- 
                                Add in front-end only and trigger the @click in Save Button
                             -->
                            <img
                                @click="scheduling_report.add_user == -1 || scheduling_report.add_user == 0 ? '' : add_selectedUser_forSchedule(scheduling_report.add_user)" 
                                :disabled="scheduling_report.add_user == -1 || scheduling_report.add_user == 0"
                                :style="[scheduling_report.add_user == -1 || scheduling_report.add_user == 0 ? {'opacity':'50%'} : {'opacity':'100%','cursor':'pointer'}]"
                                style="height: 36px;width: 36px;position: absolute;top: 34%;right: 0;transform: translateY(-50%);"
                                src="/static/svg/admin/Auto Campaign Assignment/Add.svg" alt="Add Button"
                            >
                        </div>
                        <!-- <p style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;margin-top:10px;">{{ scheduling_report.selected.length }} {{ scheduling_report.selected.length > 1 ? 'Users' : 'User' }} Selected</p> -->
                        <div class="d-flex align-items-start justify-content-between my-2">
                            <p class="m-0" style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;margin-top:10px;">{{ scheduling_report.selected.length }} {{ scheduling_report.selected.length > 1 ? 'Users' : 'User' }} Selected</p>
                            <p v-if="scheduling_report?.selected?.length > 0" @click="clearAll_selectedUser_forSchedule()" class="m-0" style="color: #480048;font-size: 12px;letter-spacing: 0.15px;text-decoration: underline;font-weight: bold;margin-top:10px;cursor: pointer;">Clear All</p>
                        </div>
                        <div
                            class="scheduling-report-container"
                            style="margin-top: 15px;margin-bottom: 15px !important;height: 220px !important;"
                        >
                            <div
                                v-for="(user, index) in scheduling_report?.selected"
                                :key="index"
                                class="scheduling-report-item"
                            >
                                <div
                                    class="scheduling-report-item-item"
                                    style="gap: 12px;"
                                    @mouseover="hovered_user = user"
                                    @mouseleave="hovered_user = {}"
                                    :class="{'isClicked': isClickedUser(user), 'isHovered': isHoveredUser(user)}"
                                    @click="isClickedUser(user) ? selected_user = {} : selected_user = user"
                                >
                                    <img style="width: 34px; height: 34px;" :src="$parent.getImage('users', getRole(user?.access_level))" alt="Group Folder SVG">
                                    <div class="d-flex flex-column align-items-start justify-content-start">
                                        <p class="m-0" style="font-size: 14px;letter-spacing: 0.24px;font-weight: bold;color: #601848;">
                                            {{ user?.label }}
                                        </p>
                                        <p class="m-0" style="font-size: 12px;letter-spacing: 0.17px;font-weight: normal !important;color: #601848;">
                                            {{ user?.email ?? 'No Email Added for this Account' }} 
                                        </p>
                                    </div>
                                    <div
                                        v-if="isHoveredUser(user)"
                                        class="d-flex align-items-start justify-content-end position-absolute"
                                        style="right: 12px;"
                                    >
                                        <img @click="remove_selectedUser_forSchedule(user.value)" style=" height: 42px;" src="/static/svg/3month-report/Delete-report-circle.svg" title="Remove User" alt="Delete Group">
                                        <!-- <img @click="step = 2;selected_user = user;" style=" height: 36px;padding-top:2px;" src="/static/svg/Campaign_Edit.svg" title="Edit Group" alt="Edit Group"> -->
                                    </div>
                                </div>
                                <!-- <div v-if="selected_forDelete_user.id == group.id" class="d-flex align-items-center justify-content-between w-100" style="gap: 5px;padding: 5px 10px;height: 40px;">
                                    <p class="m-0" style="color: #2D599ECC;font-size: 12px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Group?</p>
                                    <div class="d-flex align-items-center justify-content-end" style="gap: 15px;">
                                        <div @click="selected_forDelete_user = {};selected_group = {};" title="Click to Cancel Removing Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid red;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_No.svg" alt="X Icon" >
                                        </div>
                                        <div @click="removeGroup(group.id)" title="Click to Remove Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                        </div>
                                    </div>
                                </div> -->
                                <hr v-if="scheduling_report?.selected?.length - index != 1">
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center text-align-center w-100">
                            <!-- <span style="font-size: 14px;text-align:center;color:#515365;">Selected users will receive Weekly Report <b style="color: #480048 !important">(Monday 4:00)</b> <br> automatically, Would you like to proceed? <br> Please click <b style="color:#480048 !important;">Save</b>.</span> -->
                            <span style="font-size: 14px;text-align:center;color:#515365;">Selected users will <b style="color: #C04848 !important;font-style:italic;">ONLY</b> be used in <b style="font-style: italic;color: #C04848 !important;">manual report sending</b> by clicking <b style="color: #C04848 !important;text-decoration:underline;">Send Report</b>. If you wish to add them on <br> <b style="color: #480048 !important;font-style:italic;">Automatic Weekly Report</b>, please click <b style="color:#480048 !important;text-decoration:underline;">Save.</b></span>
                        </div>
                        <div class="w-100 my-3" style="font-size: 14px;color:#515365;">
                            <ar-checkbox
                                id="include-assigned-recipients"
                                name="include-assigned-recipients"
                                :checked="scheduling_report.include_assigned_recipient == 0 ? false : true"
                                @change="scheduling_report.include_assigned_recipient == 0 ? scheduling_report.include_assigned_recipient = 1 : scheduling_report.include_assigned_recipient = 0"
                                :label="scheduling_report?.selected?.length > 0 ? 'Include the Assigned Recipients' : 'Use the Assigned Recipients' "
                                label_style="vertical-align: middle;"
                                :disabled="scheduling_report?.assigned_recipients?.length == 0"
                            ></ar-checkbox>
                            <!-- <p class="m-0">Include the Assigned Recipients.</p> -->
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100 mt-4">
                            <a href="javascript:void(0)" @click="reset_schedulingReport();closeModal('group-schedule-report')" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Cancel</a>
                            <div class="d-flex align-items-center justify-content-end" style="gap: 8px;">
                                <ar-button
                                    style="width: fit-content;vertical-align: middle;"
                                    class="accent"
                                    :disabled="!isAbleTo_sendReport"
                                    @click="sendReport()"
                                >
                                    Send Report
                                </ar-button>
                                <ar-button
                                    style="width: fit-content;vertical-align: middle;"
                                    @click="addRecipient_forSchedule(scheduling_report?.selected)"
                                    :disabled="scheduling_report?.selected?.length == 0"
                                >
                                    <!-- @click="step = 4.1;" -->
                                    Save
                                </ar-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Right Side -->
                <div class="h-100 text-left" style="width: 382px;border-left: 1px solid #00000029;padding-left: 20px;">
                    <p style="color: #515365;font-size: 14px;letter-spacing: 0.15px;font-weight:bold;" class="m-0">Showing {{ scheduling_report?.assigned_recipients?.length ?? 0 }} {{ scheduling_report?.assigned_recipients?.length > 1 ? 'Recipients' : 'Recipient' }} of Automatic Weekly Report.</p>
                    <p style="color: #C04848;font-size: 14px;letter-spacing: 0.15px;">Select recipients to send a manual report.</p>
                    <!-- <ar-select
                        id="selection-scheduling-report-permanent-recipients"
                        type="search"
                        class="col-10"
                        style="padding: 0px !important;margin: 0px;width: 100%;"
                        label=""
                        :options="scheduling_report?.assigned_recipients"
                        v-model="scheduling_report.permanent_recipient"
                        placeholder="Select Recipient here..."
                        :resetValue="selectUsers_resetValue"
                        :disabled="scheduling_report?.assigned_recipients.length == 0"
                    ></ar-select> -->
                    <ar-textfield
                        id                  ="scheduling-report-permanent-recipients"
                        placeholder         ="Search Recipients here..." 
                        type                ="text"
                        a_class             ="mr-1 mb-3 px-2"
                        a_style             ="width: 100%;"
                        :icon               ="scheduling_report.permanent_recipientSearch.toString().trim() != '' ? 'fas fa-times' : 'fas fa-search'"
                        v-model             ="scheduling_report.permanent_recipientSearch"
                        class               ="w-100 m-0"
                        :disabled           ="scheduling_report?.assigned_recipients.length == 0"
                        @click              ="scheduling_report.permanent_recipientSearch = ''"
                    />
                    <div
                        class="d-flex align-items-center justify-content-end"
                        style="margin-bottom: 10px;"
                        :disabled="scheduling_report?.include_assigned_recipient == 0"
                        :style="scheduling_report?.include_assigned_recipient == 0 ? {'opacity': '.5'}: {'opacity': '1'} "
                    >
                        <p
                            @click="handleClick_UnSelectAll_permaRecipients()"
                            class="m-0"
                            style="
                                color: #480048;
                                font-size: 12px;
                                letter-spacing: 0.15px;
                                text-decoration: underline;
                                font-weight: bold;
                                margin-top:10px;
                            "
                            :style="scheduling_report?.include_assigned_recipient == 0 ? {'cursor': 'default'} : {'cursor':'pointer'} "
                        >
                            {{ scheduling_report?.selected_permaRecipient?.length == scheduling_report?.assigned_recipients_clean.length ? 'Unselect All' : 'Select All' }}
                        </p>
                    </div>
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding: 10px 15px;overflow:auto;height: calc(100% - 135px);">
                        <div style="min-height: 42px;height: auto;max-height: 80px;width: 100%;">
                            <div
                                v-for="(user, index) in filtered_permanent_recipients"
                                :key="index"
                                class="scheduling-report-item"
                            >
                                <div
                                    class="scheduling-report-item-item"
                                    style="gap: 12px;"
                                    @mouseover="hovered_user = user"
                                    @mouseleave="hovered_user = {}"
                                >
                                    <!-- <ArCheckList
                                        v-if="scheduling_report.include_assigned_recipient"
                                        :id="`perma-recipient--${index}`"
                                        :name="`perma-recipient--checklist`"
                                        v-model="scheduling_report.selected_permaRecipient[user.id]"
                                        :value="user.id"
                                        :is-item="false"
                                        class="position-relative d-flex justify-content-start"
                                        style="height: 20px; top: 2px;padding: 0px !important;"
                                    /> -->
                                    <!-- v-if="scheduling_report.include_assigned_recipient" -->
                                    <ar-checkbox
                                        :id="`perma-recipient--${index}`"
                                        :name="`perma-recipient--checklist`"
                                        :checked="scheduling_report.selected_permaRecipient.includes(user.id)"
                                        @change="addOrRemove(scheduling_report.selected_permaRecipient, user.id)"
                                        :disabled="scheduling_report?.include_assigned_recipient == 0"
                                    />
                                    <!-- :class="{'isClicked': isClickedUser(user), 'isHovered': isHoveredUser(user)}"
                                    @click="isClickedUser(user) ? selected_user = {} : selected_user = user" -->
                                    <img style="width: 34px; height: 34px;" :src="$parent.getImage('users', getRole(user?.access_level))" alt="Group Folder SVG">
                                    <div class="d-flex flex-column align-items-start justify-content-start">
                                        <p class="m-0" style="font-size: 14px;letter-spacing: 0.24px;font-weight: bold;color: #601848;">
                                            {{ user.first_name }} {{ user.last_name }}
                                        </p>
                                        <p class="m-0" style="font-size: 12px;letter-spacing: 0.17px;font-weight: normal !important;color: #601848;">
                                            {{ user.e_mail ?? 'No Email Added for this Account' }} 
                                        </p>
                                    </div>
                                    <div
                                        v-if="isHoveredUser(user)"
                                        class="d-flex align-items-start justify-content-end position-absolute"
                                        style="right: 12px;"
                                    >
                                        <!-- <img @click="remove_selectedUser_forSchedule(user.value)" style=" height: 42px;" src="/static/svg/3month-report/Delete-report-circle.svg" title="Remove Selected User" alt="Delete Group"> -->
                                        <img @click="selected_forDelete_recipient = user" style=" height: 42px;" src="/static/svg/3month-report/Delete-report-circle.svg" title="Remove Recipient" alt="Delete Group">
                                        <!-- <img @click="step = 2;selected_user = user;" style=" height: 36px;padding-top:2px;" src="/static/svg/Campaign_Edit.svg" title="Edit Group" alt="Edit Group"> -->
                                    </div>
                                </div>
                                <div v-if="selected_forDelete_recipient.id == user.id" class="d-flex align-items-center justify-content-between w-100" style="gap: 5px;padding: 5px 10px;height: 40px;">
                                    <p class="m-0" style="color: #2D599ECC;font-size: 12px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Recipient?</p>
                                    <div class="d-flex align-items-center justify-content-end" style="gap: 15px;">
                                        <div @click="selected_forDelete_recipient = {};" title="Click to Cancel Removing Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid red;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_No.svg" alt="X Icon" >
                                        </div>
                                        <div @click="removeRecipient_forSchedule(user.id)" title="Click to Remove Group" class="d-flex align-items-center justify-content-center" style="width: 22px;height: 22px;cursor: pointer;border:1px solid green;padding: 5px;border-radius:100%;">
                                            <img class="w-100 h-100" src="/static/svg/Checklist_Yes.svg" alt="Check Icon">
                                        </div>
                                    </div>
                                </div>
                                <hr v-if="scheduling_report?.assigned_recipients?.length - index != 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ar-dialog>
        <ar-dialog
            id="edit-schedule"
            custom_width="fit-content"
            custom_minwidth="415px"
            xLarge
            :has_button="false"
            :has_footer="false"
            dialog_style="padding: 36px 40px;height: 565px;"
            mbClass="no-padding"
            a_class="will-not-close-when-clicked-outside"
        >
            <div class="d-flex align-items-center justify-content-start h-100 w-100" style="gap: 20px;">
                <!-- Left side -->
                <div 
                    class="d-flex flex-column align-items-start justify-content-between h-100"
                    style="gap: 15px;width: 382px;"
                >
                    <div class="d-flex flex-column align-items-start justify-content-start position-relative" style="gap: 20px;">
                        <div
                            class="scheduling-report-title w-100"
                        >
                            <h4 class="m-0">Edit Schedule</h4>
                            <p class="m-0 text-left" style="font-size: 14px;">Set schedule to Selected Ungrouped Combinations. <br> <b style="color: #480048 !important;font-size: 12px;">(Please take Note that this will <i style="text-decoration: underline;color: #C04848;">OVERRIDE</i> the individual schedules for Selected Ungrouped Combinations)</b> </p>
                        </div>
                        <div class="w-100 mt-3" style="font-size: 14px;color:#515365;">
                            <ar-checkbox
                                id="add-schedule-bulk-edit"
                                name="add-schedule-bulk-edit"
                                :checked="ungroup_combinations.hasNoSchedule"
                                @change="!ungroup_combinations.hasNoSchedule ? ungroup_combinations.hasNoSchedule = true : ungroup_combinations.hasNoSchedule = false"
                                :label="selectedCombosValues?.length > 1 ? `Don't Set Schedule for these Combos` : `Don't Set Schedule for this Combo`"
                                label_style="vertical-align: middle;"
                                a_class="d-flex justify-content-start"
                            ></ar-checkbox>
                            <!-- <p class="m-0">Include the Assigned Recipients.</p> -->
                        </div>
                        <div
                            class="d-flex flex-column align-items-start justify-content-start position-relative mt-2"
                            style="gap: 20px;"
                            :style="[ 
                                ungroup_combinations.hasNoSchedule ? {'opacity': '50%'} : {'opacity':'100%'}
                            ]"
                        >
                            <div class="d-flex align-items-center justify-content-start" style="gap:5px;">
                                <p class="m-0" style="font-size: 14px;color: #515365;margin-left: 5px !important;">Assign Schedule</p>
                                <span style='font-size: 10px;
                                    letter-spacing: 1.16px;
                                    color: #FFFFFF;
                                    background: #F5686C;
                                    border: 1px solid #383A3D26;
                                    border-radius: 4px;
                                    padding: 1px 4px;
                                    font-size: 10px;
                                    font-weight: bold;'
                                    title='Required'                                
                                >REQ</span>
                            </div>
                            <div class="row g-3 w-100">
                                <ArCheckList 
                                    v-for="(day, index) in schedules_options"
                                    :key="index"
                                    :id="`select-schedule--${day.value}-edit-schedule`"
                                    :name="`select-schedule--checklist-edit-schedule`"
                                    :value="day.value"
                                    :is-item="false"
                                    class="position-relative d-flex justify-content-start col-md-6"
                                    :label="day.label"
                                    style="height: 16px; margin: 10px 0px;"
                                    v-model="ungroup_combinations.schedules"
                                    :disabled="ungroup_combinations.hasNoSchedule"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between w-100 mt-4">
                        <a href="javascript:void(0)" @click="closeModal('edit-schedule')" style="letter-spacing: 1.25px;font-size: 16px;color:#383A3D;font-weight: 500;text-decoration:underline;">Cancel</a>
                        <div class="d-flex align-items-center justify-content-end" style="gap: 8px;">
                            <ar-button
                                style="width: fit-content;vertical-align: middle;"
                                :disabled="ungroup_combinations.schedules.length == 0 && !ungroup_combinations.hasNoSchedule"
                                @click="addUpdateScheduleTo_multipleCombo()"
                            >
                                Save
                            </ar-button>
                        </div>
                    </div>
                </div>
                <!-- Right Side -->
                <div class="h-100 text-left" style="width: 382px;border-left: 1px solid #00000029;padding-left: 20px;">
                    <p style="color: #515365;font-size: 12px;letter-spacing: 0.15px;font-weight:bold;">Showing {{ selectedCombosValues.length ?? 0 }} Selected Combos</p>
                    
                    <div class="d-flex flex-column align-items-start justify-content-start w-100" style="gap: 4px;padding: 10px 15px;overflow:auto;height: calc(100% - 45px);">
                        <!-- calc(100% - 220px) -->
                        <div style="min-height: 42px;height: auto;max-height: 80px;width: 100%;">
                            <div
                                v-for="(assignment, index) in selectedCombosValues"
                                :key="index"
                                class="combo-assignments d-flex flex-column align-items-center justify-content-start"
                                style="height: 42px;"
                            >
                                <!-- :style="[selected_forDelete.id == assignment.id ? {'height':'100%'}:{'height':'42px'}]" -->
                                <div class="w-100 d-flex align-items-center justify-content-between" style="height: 42px;">
                                    <div class="d-flex align-items-center justify-content-start" style="gap: 10px;width: 100%;">
                                        <img :src="$parent.getImage('campaigns',getCampaign(assignment.campaign_id)?.name || 'Default')" :alt="getCampaign(assignment.campaign_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getCampaign(assignment.campaign_id)?.name }}
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end"
                                        style="
                                            gap: 4px;
                                            width: 100%;
                                            border:1px solid #383A3D26;
                                            border-radius: 4px;
                                            padding: 4px !important;
                                            width: fit-content;
                                            text-transform: uppercase;
                                            font-size: 10px;
                                            letter-spacing: -0.2px;
                                            margin-right: 0px;
                                        "
                                    >
                                        <img :src="$parent.getImage('platforms',getPlatform(assignment.platform_id)?.name || 'Default')" :alt="getPlatform(assignment.platform_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                                        {{ getPlatform(assignment.platform_id)?.name }}
                                    </div>
                                </div>
                                <hr v-if="selectedCombosValues.length - index > 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ar-dialog>
        <ar-dialog id="bulk-transfer-to-group-confirmation">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-info.svg" alt="Info Icon">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;">Assigning to a Group!</p>
                    <p class="m-0" style="font-size: 16px;width: 100%;">Some Combos already belong to a group. <br> Would you like to <b>Transfer</b> them to the selected group? <br> Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    <!-- @click="resetActionOptionsData();" -->
                    Cancel
                </ar-button>
                <ar-button @click="multiple_addGroupToCombo(selectedCombosClean);closeModal('bulk-transfer-to-group-confirmation')">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="remove-group-from-combo">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-error.svg" alt="Error SVG">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #2D599ECC;font-size: 24px;font-weight: bold;"><b style="color: #DC3C40;">Remove</b> this Group from Combo</p>
                    <span class="d-flex align-items-center justify-content-center" style="border:1px solid #383A3D26;border-radius: 4px;padding: 4px;gap: 4px;width: fit-content;">
                        <img style="width: 14px; height: 14px;" src="/static/svg/admin/Auto Campaign Assignment/orange_folder.svg" alt="Group Folder Icon">
                        <p class="m-0 w-100" :title="removeFrom_thisGroup.group?.name" style="color: #383A3D;text-transform: uppercase;font-size: 12px;letter-spacing: -0.2px;white-space: nowrap;overflow:hidden;text-overflow: ellipsis;font-weight: bold;">
                            {{ removeFrom_thisGroup.group?.name }}
                        </p>
                    </span>
                    <div class="w-100 d-flex align-items-center justify-content-center" style="height: 42px;">
                        <div class="d-flex align-items-center justify-content-start mr-4" style="gap: 10px;">
                            <img :src="$parent.getImage('campaigns',getCampaign(removeFrom_thisGroup.combo?.[0]?.campaign_id)?.name || 'Default')" :alt="getCampaign(removeFrom_thisGroup.combo?.[0]?.campaign_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                            {{ getCampaign(removeFrom_thisGroup.combo?.[0]?.campaign_id)?.name }}
                        </div>
                        <div class="d-flex align-items-center justify-content-end"
                            style="
                                gap: 4px;
                                width: 100%;
                                border:1px solid #383A3D26;
                                border-radius: 4px;
                                padding: 4px !important;
                                width: fit-content;
                                text-transform: uppercase;
                                font-size: 10px;
                                letter-spacing: -0.2px;
                                margin-right: 0px;
                            "
                        >
                            <img :src="$parent.getImage('platforms',getPlatform(removeFrom_thisGroup.combo?.[0]?.platform_id)?.name || 'Default')" :alt="getPlatform(removeFrom_thisGroup.combo?.[0]?.platform_id)?.name" style="width: 16px; height: 16px;border-radius: 4px;">
                            {{ getPlatform(removeFrom_thisGroup.combo?.[0]?.platform_id)?.name }}
                        </div>
                    </div>
                    <p class="m-0" style="font-size: 16px;width: 90%;">Confirm by clicking on the <b>"Proceed"</b> button.</p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    <!-- @click="resetActionOptionsData();" -->
                    Cancel
                </ar-button>
                <ar-button @click="removeFromThisGroup()">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="proceed-sending-report-confirmation-multiple">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-warning.svg" alt="Warning Icon">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #F07241;font-size: 24px;font-weight: bold;">Can't Send Report Now!</p>
                    <p class="m-0" style="font-size: 16px;width: 100%;">At least one user has no email address set. <br><b style="color: #480048;text-decoration: underline;">Proceed</b> with sending the report anyway? <br> Users with <b style="color: #F07241;"><i>NO</i></b> set email address <br> <b style="color: #F07241;"><i>will not receive the report.</i></b></p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                    @click="$parent.hideSnackbar()"
                >
                    <!-- @click="resetActionOptionsData();" -->
                    Cancel
                </ar-button>
                <ar-button @click="sendReport(true);closeModal('proceed-sending-report-confirmation-multiple')">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
        <ar-dialog id="proceed-adding-recipient-as-permanent">
            <div>
                <img style="width: 350px;" src="/static/svg/new-snackbar/modal-warning.svg" alt="Warning Icon">
                <div class="d-flex flex-column align-items-center justify-content-start" style="gap: 5px;">
                    <p class="m-0" style="color: #F07241;font-size: 24px;font-weight: bold;">Unable to Add User!</p>
                    <p class="m-0" style="font-size: 16px;width: 100%;">At least one user has no email address set. <br><b style="color: #480048;text-decoration: underline;">Proceed</b> with adding remaining users <br> to Permanent Recipients list? <br> Users with <b style="color: #F07241;"><i>NO</i></b> set email address <br> <b style="color: #F07241;"><i>will not be added.</i></b></p>
                </div>
            </div>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    <!-- @click="resetActionOptionsData();" -->
                    Cancel
                </ar-button>
                <ar-button @click="addRecipient_forSchedule(scheduling_report?.selected, true);closeModal('proceed-adding-recipient-as-permanent')">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>
    </div>
</template>

<script>
import qs from 'qs'
import TitleBarVue from '@/components/layouts/TitleBar'
import TextfieldVue from '@/components/layouts/Textfield'
import ButtonVue from '@/components/layouts/Button'
import PaginationV2Vue from '@/components/layouts/Paginationv2.vue'
import axios from 'axios'
import DialogVue from '@/components/layouts/Dialog.vue'
import SelectVue from '@/components/layouts/Select.vue'
import RadioButtonVue from '@/components/layouts/RadioButton.vue'
import Checkboxes from '@/components/layouts/Checkboxes.vue'
import CheckList from '@/components/layouts/CheckList.vue'
import AutoAssignmentActionsModal from '@/components/layouts/modals/AutoAssignmentActionsModal'
import { signalNewDisabledCombo } from '../../../events/assignments'
import { listen as listenNewUpdatedCampaign } from '../../../events'
import { getHeaders, getRole } from '@/utils/common'
import { addOrRemove, isEmptyObject, arraySort_byNumberKeyword } from '@/utils/array'
export default {
    name: 'admin-auto-campaign-assignment',
    components: {
        'ar-titlebar': TitleBarVue,
        'ar-textfield': TextfieldVue,
        'ar-button': ButtonVue,
        'ar-pagination-v2': PaginationV2Vue,
        'ar-dialog': DialogVue,
        'ar-select': SelectVue,
        'ar-radio': RadioButtonVue,
        'ar-checkbox': Checkboxes,
        'ArCheckList': CheckList,
        'ArAutoAssignmentActionsModal': AutoAssignmentActionsModal
    },
    data() {
        return {
            /**
             * 0 : Exhausted GUI
             * 1 : Clicked Verified Exhausted -> Verifying GUI
             * 2 : Clicked Please return this combination -> For further research GUI
             */
            exhausted_gui: 0,
            verified_exhausted_gui: 0,
            has_usedGlobalSearch: false,
            hasClickedEdit: false,
            hovered_index: -1,
            search: '',
            statuses: [{
                label: 'All Statuses',
                value: 0,
                color: '#FFFFFF',
            }, {
                label: 'Pending',
                value: 'Pending',
                color: '#F07241',
            }, {
                label: 'In Progress',
                value: 'In progress',
                color: '#601848',
            }, {
                label: 'Completed',
                value: 'Completed',
                color: '#4ECDC4',
            }, {
                label: 'Exhausted',
                value: 'Exhausted',
                color: '#DC3C40',
            }, {
                label: 'Verified Exhausted',
                value: 'Verified Exhausted',
                color: '#DC3C40',
            }, {
                label: 'Disabled',
                value: 'Disabled',
                color: '#93999C',
            }],
            priorities: [{
                label: 'All Priorities',
                value: 0,
                color: '#FFFFFF',
            }, {
                label: 'Urgent',
                value: 'URGENT',
                color: '#DC3C40',
            }, {
                label: 'High',
                value: 'HIGH',
                color: '#f2a2a3',
            }, {
                label: 'Medium',
                value: 'MEDIUM',
                color: '#F07241',
            }, {
                label: 'Low',
                value: 'LOW',
                color: '#DCA73C',
            }],
            fallbacks: [{
                label: 'All Fallbacks',
                value: -1,
                color: '#FFFFFF',
            }, {
                label: 'Yes',
                value: 1,
                color: '#4ECDC4',
            }, {
                label: 'No',
                value: 0,
                color: '#93999C',
            }],
            priority_level_options: [{
                label: 'Urgent',
                value: 'URGENT',
            }, {
                label: 'High',
                value: 'HIGH',
            }, {
                label: 'Medium',
                value: 'MEDIUM',
            }, {
                label: 'Low',
                value: 'LOW',
            }],
            table_order: {
                column: null,
                order: null,
            },
            showNumberOfCombos_options: [{
                label: 'Show 15 Items',
                value: 15,
            }, {
                label: 'Show 30 Items',
                value: 30,
            }, {
                label: 'Show 50 Items',
                value: 50,
            }, {
                label: 'Show 100 Items',
                value: 100,
            }, {
                label: 'Show 150 Items',
                value: 150,
            }],
            access_level_options: [
                { label: "Researcher", value: 1 },
                { label: "QA", value: 2 },
                { label: "Lawyer", value: 3 },
                { label: "Administrator", value: 4 },
                { label: "Campaign Lead", value: 5 },
                { label: "Client", value: 6 },
                { label: "Super Admin", value: 7 }
            ],
            // pagination
            page: 1,
            page_size: 15,
            visible_total: 0,
            visible: 0,
            last_page: 1,
            total: 1,

            // sortby
            sortBy: '',

            // filterby
            // v-models
            status: 0,
            priority: 0,
            platform: 0,
            campaign: 0,
            fallback: -1, 
            schedule: 0,

            create_auto_assign: {
                campaign: 0,
                platform: 0,
                fallback: 0,
                target: 0,
                no_of_researchers: 0,
                priority: 'LOW',
                hasNoSchedule: false,
                schedules: [],
                schedules_raw: [],
            },

            // store data from function
            campaigns: [],
            platforms: [],
            combos: [],
            assigned_researchers: [],
            platforms_inCombination: [],
            campaigns_inCombination: [],
            assigned_researchers_verifiedExhausted: [],

            // selected combo
            selected_combo: {},

            // selections
            selectAll: false,
            selectedCombos: {},
            requestedCombos: [],

            // bulks
            bulk: {
                confirmation: false,
                toggled: false,
                image: '',
                update: {}
            },

            manage_group: {
                search: '',
            },

            create_group: {
                group_name: '',
                is_valid: false,
                err_txt: '',
                disable_adding: true,
            },

            update_group: {
                add_combo: -1,
                // assignment is the combo in the Group data
                isHovered_assignment: -1,
                isRemove_assignment: -1,
                is_valid: false,
                // err_txt: '',
                // reset_value: false,

                name: '',
                fallback: 0,
                target: 0,
                no_of_researchers: 0,
                priority: 'LOW',
                schedules: [],
                selected_combinations: [],
                hasNoSchedule: false,
            },

            // original combinations of the selected_group
            // will get data from clickin the Edit Group in Manage Group Modal
            preset_combinations: [],

            ungroup_combinations: {
                schedules: [],
                hasNoSchedule: false,
            },

            scheduling_report: {
                search: '',
                permanent_recipientSearch: '',
                add_user: -1,
                permanent_recipient: -1,
                selected: [], // manual_added_recipients
                assigned_recipients: [],
                assigned_recipients_clean: [],
                include_assigned_recipient: 0,
                selected_permaRecipient: [],
            },

            selected_forDelete_recipient: {},

            filterBy_schedules_options: [{
                value: 'SUNDAY',
                label: 'Sunday',
            }, {
                value: 'MONDAY',
                label: 'Monday',
            }, {
                value: 'TUESDAY',
                label: 'Tuesday',
            }, {
                value: 'WEDNESDAY',
                label: 'Wednesday',
            }, {
                value: 'THURSDAY',
                label: 'Thursday',
            }, {
                value: 'FRIDAY',
                label: 'Friday',
            }, {
                value: 'SATURDAY',
                label: 'Saturday',
            }],

            schedules_options: [{
                // value: 0,
                value: 'SUNDAY',
                label: 'Sunday',
            }, {
                // value: 4,
                value: 'THURSDAY',
                label: 'Thursday',
            }, {
                // value: 1,
                value: 'MONDAY',
                label: 'Monday',
            }, {
                // value: 5,
                value: 'FRIDAY',
                label: 'Friday',
            }, {
                // value: 2,
                value: 'TUESDAY',
                label: 'Tuesday',
            }, {
                // value: 6,
                value: 'SATURDAY',
                label: 'Saturday',
            }, {
                // value: 3,
                value: 'WEDNESDAY',
                label: 'Wednesday',
            }],

            groupDropdown_options: [{
                label: 'Move to another group',
                value: 0,
                icon: 'fas fa-retweet',
            }, {
                label: 'Remove from this group',
                value: 1,
                icon: 'fas fa-eraser',
            }],
            /**
             * @var { Number } step // `step` value GUIDE
             * 0 = Manage Groups
             * 1 = Create a New Custom Group
             * 2 = Update Group
             * 3 = Change Name
             * 4 = Add this to Group / Multiple Adding of Combo to a Group
             */
            step: 0,
            selected_group: {},
            selected_forDelete: {},
            selected_forDelete_group: {},
            groups: [],
            schedules: [],
            all_combos: [],
            mapped_all_combos: [],
            hovered_group: {},
            is_loading: false,
            moveTo_anotherGroup: {
                from_group: {}, // from or current group to be transferred
                // to_group: {}, // to or selected_group,
                selected_combo: [], // to be transferred combo
            },
            removeFrom_thisGroup: {
                group: {},
                combo: {},
            },
            /**
             * Altered in selecting users @function add_selectedUser_forSchedule()
             * @var {Array} scheduling_report.selected
             */
            users: [],
            hovered_user: {},
            selected_user: {},
            selected_forDelete_user: {},

            users_copy: [],

            /**
             * How to Use?
             * 1. Add this property to the computed function
             * 2. Wherever you want to trigger the computed function just add the value
             *  ex. this.refreshKey_forComputed++
             */
            refreshKey_forComputed: 0,
            addOrRemove,
            filterBy: {
                schedules: ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'],
                selectAll_schedules: true,
                group: 0,
            },
        }
    },
    async mounted() {
        listenNewUpdatedCampaign(message => {
            if (message.type != 'campaign-update') return;

            this.getCombos('socket')
        })

        this.showBackdrop()
        this.initializeSelectionCache()
        await this.getAllCombos()
        await this.getPlatforms()
        await this.getCampaigns()
        await this.getPlatforms_inCombination()
        await this.getCampaigns_inCombination()
        await this.getGroups()
        await this.getUsers()
        await this.getCombos()
        this.hideBackdrop()
    },
    methods: {
        showBackdrop() {
            this.is_loading = true
            this.$parent?.showBackdrop()
        },
        hideBackdrop() {
            this.is_loading = false
            this.$parent?.hideBackdrop()
        },
        showActionsModal({ toggled, confirmation, action }) {
            this.bulk.toggled = toggled
            this.bulk.confirmation = confirmation
            this.bulk.image = action
            $('#auto-campaign-assignment-actions-modal').modal('show')
        },
        async clearKeywords() {
            this.search = ''
        },
        // get
        async getPlatforms() {
            let headers = getHeaders().headers

            let queries = {
				filter: {
                    status: 1
                },
                sort: '-priority,name',
                page_size: 10000,
			};

            
            await axios.get(`${process.env.VUE_APP_URL}/api/v2/platforms?${qs.stringify(queries, { encode: false })}`, {headers}).then(
                response => {
                    if(response.status == 200) {
                        this.platforms = response.data.data
                    }
                }
            )
            .catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Platforms", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async getCampaigns() {
            let headers = {headers: {...getHeaders().headers}}
            let queries = {
				filter: {
                    status: 1
                },
                sort: '-campaign_priority,name',
                page_size: 10000,
			}
            await axios.get(`${process.env.VUE_APP_URL}/api/v2/campaigns?${qs.stringify(queries, { encode: false })}`,headers).then(
                response => {
                    if(response.status == 200) {
                        this.campaigns = response.data.data
                    }
                }
            )
            .catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Campaigns", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async getPlatforms_inCombination() {
            // GET /api/assignments/platforms - by Joe's endpoint
            let headers = getHeaders().headers

            await axios.get(`${process.env.VUE_APP_URL}/api/assignments/platforms?`, {headers}).then(
                response => {
                    if(response.status == 200) {
                        this.platforms_inCombination = response.data.data.map(d => (d.platform)).sort((a,b) => b.priority.toString().localeCompare(a.priority.toString(), undefined, { numeric: true }) ||
                            a.name.toString().localeCompare(b.name.toString(), undefined, { numeric: true })
                        )
                    }
                }
            )
            .catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Platforms", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async getCampaigns_inCombination() {
            // GET /api/assignments/campaigns
            let headers = {headers: {...getHeaders().headers}}
            await axios.get(`${process.env.VUE_APP_URL}/api/assignments/campaigns?`,headers).then(
                response => {
                    if(response.status == 200) {
                        this.campaigns_inCombination = response.data.data.map(d => (d.campaign)).sort((a,b) => b.campaign_priority.toString().localeCompare(a.campaign_priority.toString(), undefined, { numeric: true }) ||
                            a.name.toString().localeCompare(b.name.toString(), undefined, { numeric: true })
                        )
                    }
                }
            )
            .catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Campaigns", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async getCombos(requestFrom = 'mounted') {
            // GET
            // /api/assignments?include[0]=statusChangeLogsCount&include[1]=campaign&include[2]=platform
            const headers = getHeaders()
            if(requestFrom == 'pagination' || requestFrom == 'filterBy') {
                this.showBackdrop()
            }
            let params = {
                include: ['campaign','platform', 'status_label','schedules','group.schedules'],
                filter: {},
                page: this.page,
                page_size: this.page_size,
                sort: this.sortBy,
            }
            if(this.campaign != 0) {
                params.filter.campaign_id = this.campaign
            }
            if(this.platform != 0) {
                params.filter.platform_id = this.platform
            }
            if(this.priority != 0) {
                params.filter.priority = this.priority
            }
            if(this.fallback != -1) {
                params.filter.is_fallback = this.fallback
            }
            if(this.status != 0) {
                params.filter.status_label = this.status
            }
            if(this.search.trim() != '') {
                params.filter.search = this.search.trim()
            }
            if(this.filterBy.schedules) {
                params.filter['schedule-day'] = this.filterBy.schedules
            }
            if(this.filterBy.group) {
                // params.filter['group.id'] = this.filterBy.group
                params.filter['group.name'] = this.selected_filterBy_group
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/assignments?${qs.stringify(params, { encode: false })}`,headers).then(
                response => {
                    if(response.status == 200) {
                        // reset the data for reques tedCombos
                        // to store it again
                        this.requestedCombos = []

                        this.combos = response.data.data
                        this.combos.forEach(combo => {
                            if (!this.requestedCombos.map(r => r.id).includes(combo.id))
                                this.requestedCombos.push(combo)
                        })
                        this.last_page = response.data.last_page
                        this.page = response.data.current_page
                        this.total = response.data.total
                        
                        if (requestFrom != 'socket')
                            this.$parent.showSnackbar('Successfully Fetch Combos', false, 'success', 'fas fa-check-circle')
                        
                        if (requestFrom == 'pagination' || requestFrom == 'filterBy') {
                            this.hideBackdrop()
                        }
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Combos", false, 'invalid','fas fa-exclamation-triangle')
                if(requestFrom == 'pagination' || requestFrom == 'filterBy') {
                    this.hideBackdrop()
                }
            })
        },
        async getAssignedResearchers() {
            // GET /api/assignments/{id}/user-assignments
            this.assigned_researchers = null;
            const headers = getHeaders()
            await axios.get(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/user-list`,headers).then(
                response => {
                    if(response.status == 200) {
                        // console.log(response.data.data)
                        let ars = []
                        response.data.data.forEach(ar => {
                            ars.push({
                                id: ar.user.id,
                                first_name: ar.user.first_name,
                                last_name: ar.user.last_name,
                                access_level: ar.user.access_level,
                                exhausted_flag: ar.exhausted_assignment_log_count > 0 ? 1 : 0
                            });
                        })
                        this.assigned_researchers = ars
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Assigned Researchers", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async getAssignedResearchersVerifiedExhausted() {
            // GET /api/assignments/{id}/user-assignments
            const headers = getHeaders()
            await axios.get(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/user-assignments`, headers).then(
                response => {
                    if(response.status == 200) {
                        // console.log(response.data)
                        let ars = []
                        response.data.data.filter(d => d.created_at !== null).forEach(ar => {
                            if(ars[ar.user.id] == undefined) {
                                ars[ar.user.id] = {first_name: ar?.user?.first_name, last_name: ar?.user?.last_name, id: ar?.user?.id, access_level: ar?.user?.access_level, exhausted_flag: ar?.exhausted_flag}
                            } else if(ars[ar.user.id] != undefined && ar.exhausted_flag == 1) {
                                ars[ar.user.id] = {first_name: ar?.user?.first_name, last_name: ar?.user?.last_name, id: ar?.user?.id, access_level: ar?.user?.access_level, exhausted_flag: ar?.exhausted_flag}
                            }
                        })
                        this.assigned_researchers_verifiedExhausted = Object.values(ars)
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Assigned Researchers", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        // post
        async addCombo() {
            // POST /api/assignments
            // { campaign_id, platform_id, is_fallback, target, no_of_researcher, priority }
            const headers = getHeaders()

            let payload = {
                campaign_id: this.create_auto_assign.campaign,
                platform_id: this.create_auto_assign.platform,
                is_fallback: this.create_auto_assign.fallback,
                target: this.create_auto_assign.target,
                no_of_researcher: this.create_auto_assign.no_of_researchers,
                priority: this.create_auto_assign.priority,
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/assignments`,payload,headers).then(
                async response => {
                    if(response.status == 201) {
                        // console.log(response.data)
                        await this.addUpdateScheduleTo_singleCombo(response.data.id)
                        this.$parent.showSnackbar('Successfully Added Combo', false, 'success', 'fas fa-check-circle')
                        await this.getCombos()
                        this.hide_showAutoCampaignAssignment()
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('Duplicate Entry: Already have this combo.', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        async updateMultiCombo(auto_assign_data, callback = () => {}) {
            try {
                let payload = []
                const { fallback, target, no_of_researchers, priority } = auto_assign_data
                const body = { is_fallback: fallback, target, no_of_researcher: no_of_researchers, priority }

                if (body.is_fallback == -1)
                    delete body.is_fallback

                if (['', null].includes(body.target))
                    delete body.target
                
                if (['', null].includes(body.no_of_researcher))
                    delete body.no_of_researcher

                if (body.priority == -1)
                    delete body.priority
                
                this.selectedCombosValues.forEach(c => {
                    payload.push({ id: c.id, update: { campaign_id: c.campaign_id, platform_id: c.platform_id, ...body } })
                })

                const { data } = await this.$http.patch(`api/assignments/multiple`, { data: payload }, getHeaders())
                $('.modal').modal('hide')
                callback()
                this.initializeSelectionCache()
                this.$parent.showSnackbar('Successfully Updated Selected Combos', false, 'success', 'fas fa-check-circle')

                await sleep(2000)

                this.getCombos()
            } catch (error) {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Updating Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            }
        },
        async updateCombo() {
            // PATCH /api/assignments/{id}
            this.$parent.showSnackbar('Processing...', true)
            
            const headers = getHeaders()
            let payload = {
                campaign_id: this.create_auto_assign.campaign,
                platform_id: this.create_auto_assign.platform,
                is_fallback: this.create_auto_assign.fallback,
                target: this.create_auto_assign.target,
                no_of_researcher: this.create_auto_assign.no_of_researchers,
                priority: this.create_auto_assign.priority,
            }
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}`,payload,headers).then(
                async response => {
                    if(response.status == 200) {
                        await this.addUpdateScheduleTo_singleCombo(this.selected_combo.id,'edit')
                        // await this.getCombos()
                        let status = this.selected_combo.status
                        let status_label = this.selected_combo.status_label
                        if(this.create_auto_assign?.schedules_raw?.length) {
                            // check if it has schedule today
                            const days = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
                            const today = new Date();
                            const currentDay = days[today.getDay()];

                            let mapped_schedules_raw = this.create_auto_assign.schedules_raw.map(cs => cs.day)
                            if(mapped_schedules_raw.includes(currentDay)) {
                                status = 1
                                status_label = "Pending"
                                if(this.create_auto_assign.fallback) status_label = 'In Progress'
                                this.refreshKey_forComputed++
                            }
                        } else {
                            status = 0
                            status_label = "Disabled"
                        }
                        const source = {
                            campaign_id: this.create_auto_assign.campaign,
                            platform_id: this.create_auto_assign.platform,
                            is_fallback: this.create_auto_assign.fallback,
                            target: this.create_auto_assign.target,
                            no_of_researcher: this.create_auto_assign.no_of_researchers,
                            priority: this.create_auto_assign.priority,
                            campaign: this.campaigns.filter(c => c.id == this.create_auto_assign.campaign)[0],
                            platform: this.platforms.filter(p => p.id == this.create_auto_assign.platform)[0],
                            schedules: this.create_auto_assign.schedules_raw,
                            status: status,
                            status_label: status_label
                        }

                        this.combos.forEach(combo => {
                            if(combo.id == this.selected_combo.id) {
                                Object.assign(combo, source)

                                this.selected_combo = combo
                                return
                            }
                        })
                        this.$parent.showSnackbar('Successfully Updated Combo', false, 'success', 'fas fa-check-circle')
                        this.hide_showAutoCampaignAssignment()
                        this.resetAutoCampaignAssignment()
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Updating Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        async disableMultiCombo() {
            try {
                const { data } = await this.$http.patch(`api/assignments/disable/multiple`, { ids: this.selectedCombosClean }, getHeaders())
                const campaign_ids = [...new Set(this.selectedCombosValues.map(s => s.campaign_id))]
                campaign_ids.forEach(c => signalNewDisabledCombo({ campaignId: c }))
                $('.modal').modal('hide') // hide modal
                this.initializeSelectionCache()
                this.$parent.showSnackbar('Successfully Deactivated Selected Combo', false, 'success', 'fas fa-check-circle')

                await sleep(2000)

                this.getCombos()
            } catch (error) {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Deactivating Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            }
        },
        async disableCombo() {
            // PATCH /api/assignments/{id}/disable
            const headers = getHeaders()
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/disable`,{},headers).then(
                response => {
                    if(response.status == 200) {
                        this.combos.forEach(combo => {
                            if(combo.id == this.selected_combo.id) {
                                combo.status = response.data.status
                                combo.status_label = 'Disabled'

                                this.selected_combo = combo
                            }
                        })
                        this.hideDisableCampaignAssignment()
                        this.$parent.showSnackbar('Successfully Deactivated Combo', false, 'success', 'fas fa-check-circle')
                        signalNewDisabledCombo({ campaignId: this.selected_combo.campaign_id })
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Deactivating Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        async enableMultiCombo() {
            try {
                const { data } = await this.$http.patch(`api/assignments/enable/multiple`, { ids: this.selectedCombosClean }, getHeaders())
                const campaign_ids = [...new Set(this.selectedCombosValues.map(s => s.campaign_id))]
                campaign_ids.forEach(c => signalNewDisabledCombo({ campaignId: c }))
                $('.modal').modal('hide') // hide modal
                this.initializeSelectionCache()
                this.$parent.showSnackbar('Successfully Activated Selected Combos', false, 'success', 'fas fa-check-circle')

                await sleep(2000)

                this.getCombos()
            } catch (error) {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Deactivating Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            }
        },
        async enableCombo() {
            // PATCH /api/assignments/{id}/disable
            const headers = getHeaders()
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/enable`,{},headers).then(
                async response => {
                    if(response.status == 200) {
                        signalNewDisabledCombo({ campaignId: this.selected_combo.campaign_id })
                        this.showBackdrop()
                        await this.getCombos()
                        this.hideBackdrop()
                        this.hideDisableCampaignAssignment()
                        this.$parent.showSnackbar('Successfully Activated Combo', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Deactivating Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        async exhaustCombo() {
            // PATCH /api/assignments/{id}/exhaust
            // /api/
            const headers = getHeaders()
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/exhaust`,{},headers).then(
                async response => {
                    if(response.status == 200) {
                        // this.combos.forEach(combo => {
                        //     if(combo.id == this.selected_combo.id) {
                        //         combo.status = response.data.status
                        //         combo.status_label = 'Verified Exhausted'

                        //         this.selected_combo = combo
                        //     }
                        // })
                        this.showBackdrop()
                        await this.getCombos()
                        this.hideBackdrop()
                        this.hide_showAutoCampaignAssignmentExhausted()
                        this.$parent.showSnackbar('Successfully Exhausted Combo', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Exhausting Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        async forFurtherResearchCombo() {
            // PATCH /assignments/{id}/return
            const headers = getHeaders()
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/return`,{},headers).then(
                async response => {
                    if(response.status == 200) {
                        this.showBackdrop()
                        await this.getCombos()
                        this.hideBackdrop()
                        this.hide_showAutoCampaignAssignmentExhausted()
                        this.$parent.showSnackbar('Successfully returning the Combo to "For Further Research"', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Executing "For Further Research" Combo', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        async replenishCombo() {
            const headers = getHeaders()
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${this.selected_combo.id}/enable`,{},headers).then(
                async response => {
                    if(response.status == 200) {
                        this.showBackdrop()
                        await this.getCombos()
                        this.hideBackdrop()
                        this.hide_AutoCampaignAssignmentVerifiedExhausted()
                        this.$parent.showSnackbar('Successfully Replenished Combo', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when executing "Replenish Combo" action', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        // others
        showAutoCampaignAssignment(type = 'add') {
            if(type == 'edit') {
                this.create_auto_assign.campaign = this.selected_combo.campaign.id
                this.create_auto_assign.platform = this.selected_combo.platform.id
                this.create_auto_assign.fallback = this.selected_combo.is_fallback
                this.create_auto_assign.target = this.selected_combo.target
                this.create_auto_assign.no_of_researchers = this.selected_combo.no_of_researcher
                this.create_auto_assign.priority = this.selected_combo.priority
                this.create_auto_assign.schedules = this.selected_combo?.schedules?.map(sc => sc.day)
                this.create_auto_assign.schedules_raw = this.selected_combo?.schedules
                this.create_auto_assign.hasNoSchedule = false
            }
            $('#auto-campaign-assignment').modal('show')
        },
        hide_showAutoCampaignAssignment() {
            $('#auto-campaign-assignment').modal('hide')
        },
        async showAutoCampaignAssignmentExhausted(combo) {
            this.selected_combo = combo
            $('#auto-campaign-assignment-exhausted').modal('show')
            await this.getAssignedResearchers()
        },
        hide_showAutoCampaignAssignmentExhausted() {
            $('#auto-campaign-assignment-exhausted').modal('hide')
        },
        async showAutoCampaignAssignmentVerifiedExhausted(combo) {
            this.selected_combo = combo
            $('#auto-campaign-assignment-verified-exhausted').modal('show')
            await this.getAssignedResearchersVerifiedExhausted()
        },
        hide_AutoCampaignAssignmentVerifiedExhausted() {
            $('#auto-campaign-assignment-verified-exhausted').modal('hide')
        },
        showDisableCampaignAssignment() {
            $('#disable-combo').modal('show')
        },
        hideDisableCampaignAssignment() {
            $('#disable-combo').modal('hide')
        },
        openModal(modal_id) {
            $(`#${modal_id}`).modal('show')
        },
        closeModal(modal_id) {
            $(`#${modal_id}`).modal('hide')
        },
        resetUngroupCombinations() {
            this.ungroup_combinations.schedules = []
            this.ungroup_combinations.hasNoSchedule = false
        },
        resetAutoCampaignAssignment() {
            this.selected_combo = {}

            // reset adding auto campaign assignment here
            this.create_auto_assign.campaign = 0
            this.create_auto_assign.platform = 0
            this.create_auto_assign.fallback = 0
            this.create_auto_assign.target = 0
            this.create_auto_assign.no_of_researchers = 0
            this.create_auto_assign.priority = 'LOW'
            this.create_auto_assign.schedules = []
            this.create_auto_assign.schedules_raw = []
            this.create_auto_assign.hasNoSchedule = false
        },
        resetUpdateGroupValues() {
            // this.selected_group = {}

            this.update_group.fallback = 0
            this.update_group.target = 0
            this.update_group.no_of_researchers = 0
            this.update_group.priority = 'LOW'
            this.update_group.schedules = []
            this.update_group.selected_combinations = []
            this.update_group.hasNoSchedule = false
        },
        setUpdateGroupValues() {
            let selected_group = {...this.selected_group}
            this.update_group.name = selected_group.name
            this.update_group.fallback = selected_group.is_fallback
            this.update_group.target = selected_group.target
            this.update_group.no_of_researchers = selected_group.no_of_researcher
            this.update_group.priority = selected_group.priority
            this.update_group.schedules = selected_group.schedules
            this.update_group.hasNoSchedule = false

            this.preset_combinations = JSON.parse(JSON.stringify(selected_group.assignments))
            this.update_group.selected_combinations = JSON.parse(JSON.stringify(selected_group.assignments))
        },
        async updateTableOrder(column) {
            this.table_order.order = this.table_order.column == column ? ['asc', 'desc'][+(this.table_order.order == 'asc')] : 'asc';
            this.table_order.column = column;

            switch(column) {
                case 'id':
                    this.sortBy = 'id'
                    break
                case 'campaign':
                    this.sortBy = 'campaigns.name'
                    break
                case 'platform':
                    this.sortBy = 'platforms.name'
                    break
                case 'priority':
                    this.sortBy = 'priority'
                    break
                case 'target':
                    this.sortBy = 'target'
                    break
                case 'no_of_researcher':
                    this.sortBy = 'no_of_researcher'
                    break
                case 'status':
                    this.sortBy = 'status_label'
                    break
                case 'is_fallback':
                    this.sortBy = 'is_fallback'
                    break
                case 'assignment_groups.name':
                    this.sortBy = 'assignment_groups.name'
            }

            if(this.table_order.order == 'desc') {
                this.sortBy = `-${this.sortBy}`
            }
            this.showBackdrop()
            await this.getCombos()
            this.hideBackdrop()
        },
        async searchNow() {
            this.showBackdrop()
            await this.getCombos()
            this.hideBackdrop()
        },
        getRole(access_level) {
            if (access_level === undefined) {
                return null;
            }
            return this.access_level_options[access_level - 1]?.label;
        },
        // used in verified exhausted modal 
        getDate(date) {
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            let d = new Date(date)
            let month = d.getMonth()
            let day = d.getDay()
            let year = d.getFullYear()


            return `${day} ${monthNames[month]} ${year}`
        },
        multiSelect() {
            this.selectedCombos[this.page] = (this.last_page == this.page && this.selectedCombos[this.page].length == this.combos.length) || this.selectedCombos[this.page].length == this.page_size ? [] : this.filtered_combos.result.map(r => r.id)
        },
        initializeSelectionCache() {
            for (let i = 0; i < this.last_page; i++) {
                this.selectedCombos = { ...this.selectedCombos, [i+1]: [] }
            }
        },
        initializeSelectAll() {
            this.selectAll = (this.last_page == this.page && this.selectedCombos[this.page].length == this.combos.length) || this.selectedCombos[this.page].length == this.page_size
        },
        async getGroups(isNewGroup = false, from = 'mounted') {
            if(isNewGroup) {
                this.$parent.showSnackbar('Getting Group Info...', true)
            }
            const headers = getHeaders()
            await axios.get(`${process.env.VUE_APP_URL}/assignment_groups?page_size=10000`,headers).then(
                async response => {
                    if(response.status == 200) {
                        this.groups = response.data.data
                        if(this.selected_group?.id !== undefined || this.selected_group?.id !== null) {
                            await this.getAllCombos()

                            let new_data = response.data.data.filter(d => d.id == this.selected_group?.id)[0]
                            this.selected_group = new_data

                            // after adding and getting the specific data, move to updating
                            if(isNewGroup) {
                                this.setUpdateGroupValues()
                                this.$parent.hideSnackbar()
                                if(from == 'group_combination') this.step = 4
                                else this.step = 2
                            }
                        }
                        
                        if(from != 'mounted') {
                            /**
                             * Using 'pagination' just for showing the backdrop
                             */
                            await this.getCombos('pagination')
                        }
                    }
                    this.$parent.hideSnackbar()
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while retrieving Groups", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async addGroup(group_name, from = 'add_group') {
            if(this.create_group.disable_adding == true)
                return

            this.create_group.disable_adding = true
            this.$parent.showSnackbar('Processing...', true)

            const headers = getHeaders()
            let payload = {
                name: group_name
            }
            await axios.post(`${process.env.VUE_APP_URL}/assignment_groups`,payload, headers).then(
                async response => {
                    if(response.status == 200 || response.status == 201) {
                        let group_id = response.data.data.id
                        await this.addSchedule_toGroup(group_id)

                        if(from == 'add_group') {
                            this.update_group.fallback = 0
                            this.update_group.target = 1
                            this.update_group.no_of_researchers = 1
                            this.update_group.priority = 'LOW'
                            this.updateGroup()
                        }

                        /**
                         * Empty &
                         * Assign the ID so that when getGroups() is executed 
                         * it will then get the selected_group of the assigned ID based only on ID
                         */
                        if(this.selected_group == undefined)
                            this.selected_group = {}

                        this.selected_group.id = group_id 
                        // console.log(this.selected_group)
                        if(from == 'group_combination') await this.getGroups(true,from)
                        else await this.getGroups(true)

                        this.reset_createGroup()

                        this.$parent.hideSnackbar()
                        this.$parent.showSnackbar('Successfully Added Group', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.hideSnackbar()
                this.$parent.showSnackbar("An error occurred while adding Group", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async updateGroup(is_changeName = false) {
            this.$parent.showSnackbar("Updating Schedule first...",true)

            // addSchedule_toGroup will hide the Snackbar if it is successfully updated
            if(JSON.stringify(this.update_group.schedules) != JSON.stringify(this.selected_group.schedules))
                await this.addSchedule_toGroup(this.selected_group?.id,this.update_group.schedules,false)

            // updating the combinations
            // will update only if there is changes to the combinations
            if(this.update_group.selected_combinations.length != this.preset_combinations.length) {
                let assignment_ids = this.update_group.selected_combinations.map(up_sc => up_sc.id)
                this.updateSelectedCombinations(this.selected_group.id, assignment_ids)
            }

            this.$parent.showSnackbar("Updating Group...",true)

            const headers = getHeaders()
            let payload = {
                is_fallback: this.update_group.fallback,
                target: parseInt(this.update_group.target),
                no_of_researcher: parseInt(this.update_group.no_of_researchers),
                priority: this.update_group.priority,
            }

            if(is_changeName) {
                payload = {
                    name: this.update_group.name
                }
            }

            await axios.patch(`${process.env.VUE_APP_URL}/assignment_groups/${this.selected_group?.id}`,payload,headers).then(
                async response => {
                    if(response.status == 200) {
                        // console.log(response.data.data)
                        // await this.getGroups()
                        this.resetUpdateGroupValues()
                        this.closeModal('auto-campaign-assignment-manage-group')
                        this.$parent.showSnackbar('Successfully Updated Group', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while updating Group", false, 'invalid','fas fa-exclamation-triangle')
            })
            // this.$parent.hideSnackbar()
            
            if (is_changeName) {
                this.$parent.showSnackbar("Moving to Next View...",true)
                await this.getGroups(true)
            } else {
                this.$parent.showSnackbar("Fetching Data...",true)
                this.showBackdrop()
                await this.getGroups(false,'updateGroup')
            }
            
            this.$parent.hideSnackbar()
        },
        async removeGroup(group_id) {
            this.$parent.showSnackbar("Processing...",true)
            this.$parent.showBackdrop()
            const headers = getHeaders()
            
            await axios.delete(`${process.env.VUE_APP_URL}/assignment_groups/${group_id}`, headers).then(
                async response => {
                    this.$parent.hideSnackbar()
                    if (response.status == 200) {
                        let combos_withDeletedGroup = this.all_combos.filter(ac => ac.assignment_group_id == group_id).map(ac => ac.id)
                        
                        const headers = getHeaders()

                        let payload = {
                            assignment_group_id: null,
                        }

                        const addGroup_toCombo = async (combo_id) => {
                            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${combo_id}`,payload, headers).then(
                                response => {
                                    if(response.status == 200) {
                                        console.log(response.data.data)
                                    }
                                }
                            ).catch(error => {
                                this.$parent.showSnackbar('Something went wrong in adding combo to Group. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');
                                console.log(error)
                                // Ensure the promise is always resolved, even if there's an error
                                return Promise.resolve();
                            })
                        }

                        let promises = combos_withDeletedGroup.map(c => addGroup_toCombo(c));

                        // Wait for all promises to settle
                        Promise.allSettled(promises).then(
                            async results => {
                                // Check each result to see if the promise was fulfilled or rejected
                                results.forEach(result => {
                                    if (result.status === 'fulfilled') {
                                        console.log('Promise fulfilled:', result.value);
                                    } else {
                                        console.error('Promise rejected:', result.reason);
                                    }
                                })
                                // this.$parent.hideSnackbar()
                                // this.$parent.showSnackbar('Successfully Assigned Combinations to the Group.', false, 'success', 'fas fa-check-circle')
                                // this.closeModal('auto-campaign-assignment-manage-group')
                                // this.initializeSelectionCache()
                                // this.reset_manageGroup()

                                // this.$parent.showSnackbar('Fetching Groups...', true)
                                // await this.getGroups()
                                
                                // console.log('Assignment group deleted successfully');
                                this.$parent.showSnackbar('Successfully Removed Group', false, 'success', 'fas fa-check-circle')
                                setTimeout(async () => {
                                    this.$parent.hideSnackbar()
                                    this.$parent.showSnackbar('Processing...', true)
                                    await this.getGroups()
                                    this.$parent.hideSnackbar()
                                    this.hideBackdrop()
                                }, 500)
                            }
                        )

                        // console.log('Assignment group deleted successfully');
                        // this.$parent.showSnackbar('Successfully Removed Group', false, 'success', 'fas fa-check-circle')
                    } else {
                        console.error('Unexpected response status:', response.status);
                        this.$parent.hideSnackbar()
                        this.hideBackdrop()
                    }
                    
                }
            ).catch(error => {
                console.log(error)
                this.$parent.hideSnackbar()
                this.$parent.showSnackbar("An error occurred while removing Group", false, 'invalid','fas fa-exclamation-triangle')
                this.hideBackdrop()
            })
        },
        async moveToAnotherGroup() {
            await this.addGroupToCombo(this.moveTo_anotherGroup.selected_combo?.[0]?.id)
        },
        async removeFromThisGroup() {
            await this.addGroupToCombo(this.removeFrom_thisGroup.combo?.[0]?.id, true)
            this.closeModal('remove-group-from-combo')
            this.reset_removeFromThisGroup()
        },
        initialize_removeFromThisGroup(combo) {
            let groups = [...this.groups]
            
            // initialize the selected_group
            this.selected_group = groups.filter(g => g.id == combo.assignment_group_id)?.[0]
            this.removeFrom_thisGroup.group = this.selected_group
            this.removeFrom_thisGroup.combo = [combo]  

            this.openModal('remove-group-from-combo')
        },
        async initialize_moveToAnotherGroup(combo) {
            let groups = [...this.groups]
            this.step = 5

            this.moveTo_anotherGroup.selected_combo = [combo]
            this.moveTo_anotherGroup.from_group = groups.filter(g => g.id == combo.assignment_group_id)?.[0]

            this.openModal('auto-campaign-assignment-manage-group')

        },
        reset_moveToAnotherGroup() {
            this.moveTo_anotherGroup = {
                from_group: {}, // from or current group to be transferred
                // to_group: {}, // to or selected_group,
                selected_combo: [], // to be transferred combo
            }
            this.reset_manageGroup() // triggering this function because in this step (5) I am using the same code from step 1 `Manage Group`
        },
        reset_removeFromThisGroup() {
            this.removeFrom_thisGroup = {
                group: {},
                combo: {},
            }
        },
        isClickedGroup(group) {
            return this.selected_group?.id == group?.id
        },
        isHoveredGroup(group) {
            return this.hovered_group?.id == group?.id
        },
        isClickedUser(user) {
            return this.selected_user?.value == user?.value
        },
        isHoveredUser(user) {
            return this.hovered_user?.value == user?.value
        },
        isClickedAssignment(combo) {
            return this.selected_forDelete?.id == combo?.id  
        },
        group_checkAvailability(group_name, from = 'create_group') {
            group_name = group_name.toString().trim()
            if(group_name == '')
                return true

            let groups = [...this.groups]

            let duplicate = groups.filter(g => g.name == group_name)

            if(duplicate.length > 0) {

                switch (from) {
                    case 'is_changeName':
                        this.step = 3.1
                        this.update_group.is_valid = false
                        // this.update_group.err_txt = ''
                        break
                    // case 'group_combination':
                    //     this.create_group.is_valid = false
                    //     this.create_group.err_txt = 'Inputted Group already existed in the database.'
                    //     break
                    /**
                     * default is 'create_group'
                     */
                    default:
                        this.create_group.is_valid = false
                        this.create_group.err_txt = 'Inputted Group already existed in the database.'
                        this.create_group.disable_adding = true
                }

                return false
            } else {

                switch (from) {
                    case 'is_changeName':
                        this.step = 3.2
                        this.update_group.is_valid = true
                        // this.update_group.err_txt = ''
                        break
                    case 'group_combination':
                        this.step = 4.12
                        this.create_group.is_valid = true
                        break
                    /**
                     * default is 'create_group'
                     */
                    default:
                        this.step = 1.1
                        this.create_group.is_valid = true
                        this.create_group.err_txt = ''
                        this.create_group.disable_adding = false
                }

                return true
            }
        },
        reset_createGroup() {
            this.create_group = {
                group_name: '',
                is_valid: false,
                err_txt: '',
            }
        },
        reset_manageGroup() {
            this.reset_createGroup()
            this.manage_group = {
                search: ''
            },
            this.step = 0
            this.selected_group = {}

        },
        reset_schedulingReport() {
            this.scheduling_report = {
                search: '',
                permanent_recipientSearch: '',
                add_user: -1,
                permanent_recipient: -1,
                selected: [], // manual_added_recipients
                assigned_recipients: [],
                assigned_recipients_clean: [],
                include_assigned_recipient: 0,
                selected_permaRecipient: [],
            }
        },
        async multiple_addGroupToCombo(combo_ids) {
            this.$parent.showSnackbar('Processing...', true)

            const headers = getHeaders()

            let payload = {
                assignment_group_id: this.selected_group?.id,
            }

            const addGroup_toCombo = async (combo_id) => {
                await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${combo_id}`,payload, headers).then(
                    response => {
                        if(response.status == 200) {
                            console.log(response.data.data)
                        }
                    }
                ).catch(error => {
                    this.$parent.showSnackbar('Something went wrong in adding combo to Group. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');
                    console.log(error)
                    // Ensure the promise is always resolved, even if there's an error
                    return Promise.resolve();
                })
            }

            let promises = combo_ids.map(c => addGroup_toCombo(c));

            // Wait for all promises to settle
            Promise.allSettled(promises).then(
                async results => {
                     // Check each result to see if the promise was fulfilled or rejected
                    results.forEach(result => {
                        if (result.status === 'fulfilled') {
                            console.log('Promise fulfilled:', result.value);
                        } else {
                            console.error('Promise rejected:', result.reason);
                        }
                    })
                    this.$parent.hideSnackbar()
                    this.$parent.showSnackbar('Successfully Assigned Combinations to the Group.', false, 'success', 'fas fa-check-circle')
                    this.closeModal('auto-campaign-assignment-manage-group')
                    this.initializeSelectionCache()
                    this.reset_manageGroup()

                    this.$parent.showSnackbar('Fetching Groups...', true)
                    await this.getGroups(false, 'multiple_addGrouptToCombo')
                }
            )
            
            // await Promise.allSettled(promises)
            // console.log(promises)
        },
        async addGroupToCombo(combo_id, is_remove = false) {
            this.$parent.showSnackbar('Processing...', true)

            const headers = getHeaders()
            let payload = {
                assignment_group_id: this.selected_group?.id,
            }
            if(is_remove) 
                payload.assignment_group_id = null

            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${combo_id}`,payload,headers).then(
                async response => {
                    if(response.status == 200) {
                        // reset Add Combo Selection
                        this.update_group.add_combo = 0
                        // this.update_group.reset_value = true
                        await this.getGroups()
                        this.$parent.hideSnackbar()
                        
                        if(is_remove) {
                            this.getCombos()
                            this.$parent.showSnackbar('Successfully Removed Assigned Combo.', false, 'success', 'fas fa-check-circle')
                        }

                        if(this.step == 5) {
                            // reset moveTo_anotherGroup
                            this.reset_moveToAnotherGroup()
                            this.closeModal('auto-campaign-assignment-manage-group')
                        }
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.hideSnackbar()

                if(is_remove) 
                    this.$parent.showSnackbar("An error occurred while Removing Assigned Combo", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        getGroupBy_Id(group_id) {
            /**
             * Use the groups retrieved from getGroups()
             * @type {Array}
             */
            let groups = [...this.groups]

            let group = groups.filter(g => g.id == group_id)

            return group[0]
        },
        /**
         * Add or Update Schedule of the Group
         */
        async addSchedule_toGroup(group_id,schedules = [], is_default = true) {
            const headers = getHeaders()
            let payload = {}

            if(is_default) {
                payload = {
                    days: [
                        "monday",
                        "tuesday",
                        "wednesday",
                        "thursday",
                        "friday",
                    ]
                }
            } else {
                payload = {days: schedules}
            }

            await axios.patch(`${process.env.VUE_APP_URL}/assignment_groups/${group_id}/schedules`,payload,headers).then(
                response => {
                    if(response.status == 200) {
                        // console.log(response.data)
                        this.$parent.hideSnackbar()
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar("An error occurred while adding Schedule to the Group", false, 'invalid','fas fa-exclamation-triangle')
            })
        },
        async getAllCombos() {
            const headers = getHeaders()
            let params = {
                include: ['campaign','platform', 'status_label','schedules','group.schedules'],
                filter: {},
                page: this.page,
                page_size: 10000,
                // sort: this.sortBy,
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/assignments?${qs.stringify(params, { encode: false })}`,headers).then(
                response => {
                    if(response.status == 200) {
                        this.all_combos = response.data.data
                        this.mapped_all_combos = response.data.data.map(r => {
                            return {
                                assignment_group_id: r.assignment_group_id,
                                campaign_id: r.campaign_id,
                                campaign_label: r.campaign.name,
                                platform_id: r.platform_id,
                                platform_label: r.platform.name,
                                label: `${r.campaign.name} - ${r.platform.name}`,
                                value: r.id,
                            }
                        })
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        },
        getCampaign(campaign_id) {
            let campaigns = [...this.campaigns]
            return campaigns.filter(c => c.id == campaign_id)[0]
        },
        getPlatform(platform_id) {
            let platforms = [...this.platforms]
            return platforms.filter(p => p.id == platform_id)[0]
        },
        async getUsers() {
            let headers = getHeaders()
            headers.where = JSON.stringify({'access_level': [1,2,5,7]})

            await axios.get(`${process.env.VUE_APP_URL}/users/multi?page_size=1000`, headers).then(
                response => {
                    if(response.status == 200) {
                        this.users = response.data.data.map(d => {
                            return {
                                label: `${d.first_name} ${d.last_name ?? ''} [${getRole(d.access_level)}]`,
                                value: d.id,
                                email: d.e_mail,
                                access_level: d.access_level,
                                selected: false,
                            }
                        })
                        this.users_copy = this.users
                        // console.log(response.data.data)
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        },
        add_selectedUser_forSchedule(id) {
            this.users = [...this.users.map(u => {
                if(u.value == id) {
                    return { ...u, selected: true}
                }
                // For other objects, return them unchanged
                return u;
            })]

            // console.log(this.scheduling_report?.selected?.push(this.users?.filter(u => u?.value == id)[0]))
            this.scheduling_report?.selected?.push(this.users?.filter(u => u?.value == id)[0])
            this.scheduling_report.add_user = 0
        },
        remove_selectedUser_forSchedule(id) {
            this.users = [...this.users.map(u => {
                if(u.value == id) {
                    return { ...u, selected: false}
                }
                // For other objects, return them unchanged
                return u;
            })]
            let scheduling_report_selected = [...this.scheduling_report?.selected]

            this.scheduling_report.selected = scheduling_report_selected?.filter(s => s.value != id)            
        },
        clearAll_selectedUser_forSchedule() {
            let selected = [...this.scheduling_report.selected]
            
            selected.forEach(s => {
                this.remove_selectedUser_forSchedule(s.value)
            })
        },
        /**
         * @param { Array } userIds || user_ids
         * @param { Boolean } proceedAnyway || proceed_anyway 
         */
        // async addRecipient_forSchedule(user_ids, proceed_anyway = false) {
        async addRecipient_forSchedule(userIds, proceedAnyway = false) {
            this.$parent.showSnackbar("Processing...",true)

            let validUserIds = userIds.filter(user => user.email);
            let usersWithoutEmail = userIds.filter(user => !user.email);

            // Handle scenario where a single user without email is selected
            if (usersWithoutEmail.length === 1 && validUserIds.length === 0) {
                this.$parent.showSnackbar('Selected user has no email address set. Unable to add user to list of permanent recipients.', false, 'invalid', 'fas fa-times-circle');
                return;
            }

            // Handle scenario with multiple users, some of whom have no email
            if (!proceedAnyway && usersWithoutEmail.length > 0) {
                if (validUserIds.length > 0) {
                    this.openModal('proceed-adding-recipient-as-permanent');
                    this.$parent.hideSnackbar();
                    return;
                }
                if (usersWithoutEmail.length === userIds.length) {
                    this.$parent.showSnackbar('All selected users has no email address set. Unable to add user to list of permanent recipients.', false, 'invalid', 'fas fa-times-circle');
                    return;
                }
            }

            const addUser_asRecipient = async (userId) => {
                let payload = {
                    receive_combo_report: 1,
                }
                await axios.patch(`${process.env.VUE_APP_URL}/api/v2/users/${userId}`,payload, getHeaders()).then(
                    response => {
                        if(response.status == 200) {
                            console.log(response.data.data)
                        }
                    }
                ).catch(error => {
                    console.log(error)
                    return Promise.resolve();
                })
            }

            let promises = validUserIds.map(ui => addUser_asRecipient(ui.value));

            // Wait for all promises to settle
            Promise.allSettled(promises).then(
                async results => {
                     // Check each result to see if the promise was fulfilled or rejected
                    results.forEach(result => {
                        if (result.status === 'fulfilled') {
                            console.log('Promise fulfilled:', result.value);
                        } else {
                            console.error('Promise rejected:', result.reason);
                        }
                    })
                    
                    this.refreshKey_forComputed++
                    this.scheduling_report.add_user = 0
                    await this.getRecipients() // get all recipients

                    // Turning variable "selected" to all users 
                    // to not affect the transfers from selected to permanent recipients and vice versa
                    usersWithoutEmail.forEach(uwe => this.remove_selectedUser_forSchedule(uwe.value))
                    validUserIds.forEach(vui => this.remove_selectedUser_forSchedule(vui.value))

                    this.scheduling_report.selected = []
                    this.$parent.showSnackbar('Successfully Added Users to the Permanent Recipients', false, 'success', 'fas fa-check-circle')
                }
            )
        },
        async removeRecipient_forSchedule(user_id) {
            this.$parent.showSnackbar("Processing...",true)

            let payload = {
                receive_combo_report: 0,
            }

            await axios.patch(`${process.env.VUE_APP_URL}/api/v2/users/${user_id}`,payload, getHeaders()).then(
                async response => {
                    if(response.status == 200) {
                        // console.log(response.data.data)
                        await this.getRecipients()
                        this.$parent.showSnackbar('Successfully Removed Recipient', false, 'success', 'fas fa-check-circle')
                        this.selected_forDelete_recipient = {}
                        this.refreshKey_forComputed++
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('Something went wrong in Removing Recipient. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');
                return Promise.resolve();
            })
        },
        rearranged_schedule(schedule_array) {
            let basis = [{
                name: 'SUNDAY',
                is_active: 0,
            }, {
                name: 'MONDAY',
                is_active: 0,
            }, {
                name: 'TUESDAY',
                is_active: 0,
            }, {
                name: 'WEDNESDAY',
                is_active: 0,
            }, {
                name: 'THURSDAY',
                is_active: 0,
            }, {
                name: 'FRIDAY',
                is_active: 0,
            }, {
                name: 'SATURDAY',
                is_active: 0,
            }]
            let sorted_schedule = []
            basis.forEach((b,_index) => {
                sorted_schedule[_index] = b

                if(schedule_array?.includes(b.name)) {
                    sorted_schedule[_index].is_active = 1
                }
            })
            // console.log(sorted_schedule)
            return sorted_schedule
        },
        // used in filterBySchedule
        reformat_schedule(schedule) {
            let formatted_schedule = []
            schedule.forEach(_as => {
                ['saturday','sunday','thursday'].includes(_as?.toLowerCase()) ? formatted_schedule.push(`${_as[0]}${_as[1]}`) : formatted_schedule.push(`${_as[0]}`)
            })
            if (formatted_schedule.length && formatted_schedule.length < 7) return formatted_schedule
            else if (formatted_schedule.length == 7) return 'All Schedules'
            else return 'No Schedule Selected'
        },
        async getRecipients() {
            await axios.get(`${process.env.VUE_APP_URL}/api/v2/users?filter[receive_combo_report]=1`, getHeaders()).then(
                response => {
                    if(response.status == 200) {
                        this.scheduling_report.assigned_recipients = response.data.data.map(r => {return {...r, value: r.id, label: `${r.first_name} ${r.last_name}`}})
                        this.scheduling_report.assigned_recipients_clean = response.data.data.map(r => r.id)
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        },
        /**
         * Sending Report to Multiple Recipients with 1 or more users with no Email
         * @param {Boolean} proceed_anyway
         */
        async sendReport(proceed_anyway = false) {
            // let scheduling_report_selected = [...(this.scheduling_report?.selected || [])]

            // this.$parent.showSnackbar("Processing...",true)

            // /**
            //  * Checking If there are no emails
            //  */
            // let assigned_recipients = [...(this.scheduling_report?.assigned_recipients || [])]
            // let selected_user_for_report = scheduling_report_selected.map(ss => {
            //     const { value, ...rest } = ss
            //     return { ...rest, id: value }
            // })

            // let all_users_for_report = [...assigned_recipients, ...selected_user_for_report]
            // let has_noEmail = all_users_for_report.filter(user => !user.e_mail)

            // if (!proceed_anyway) {
            //     if (has_noEmail && all_users_for_report.length > 1) {
            //         // Unclear Error Message - sending report to multiple recipients
            //         this.openModal('proceed-sending-report-confirmation-multiple')
            //         this.$parent.hideSnackbar()
            //         console.log('here')
            //         return
            //     }
            // }

            // let clean_selected_scheduling_report = scheduling_report_selected?.map(sr => sr.value)

            // if(proceed_anyway) {
            //     clean_selected_scheduling_report = selected_user_for_report?.filter(sr => sr.e_mail)?.map(sr => sr.value)
            // }

            // let payload = {
            //     users: clean_selected_scheduling_report
            // }

            // if(this.scheduling_report.include_assigned_recipient) {
            //     if(proceed_anyway) {
            //         payload.users = all_users_for_report?.filter(users => users.e_mail).map(us => us.id)
            //     } else {
            //         payload.users = [...clean_selected_scheduling_report, ...this.scheduling_report.selected_permaRecipient]
            //     }
            // }

            let schedulingReportSelected = [...(this.scheduling_report?.selected || [])];
            let assignedRecipients = [...(this.scheduling_report?.assigned_recipients || [])].map(({ e_mail, ...rest }) => ({
                ...rest,
                email: e_mail
            }));

            // filtered by the checklist in perma recipients
            assignedRecipients = assignedRecipients.filter(ar => this.scheduling_report?.selected_permaRecipient.includes(ar.value))

            this.$parent.showSnackbar("Processing...", true);

            // Combine assigned recipients and selected users for report
            let selectedUsersForReport = schedulingReportSelected.map(({ value, ...rest }) => ({
                ...rest,
                id: value
            }));
            let allUsersForReport = this.scheduling_report.include_assigned_recipient ? [...assignedRecipients, ...selectedUsersForReport] : [...selectedUsersForReport];

            // Check for users without email
            let usersWithoutEmail = allUsersForReport.filter(user => !user.email);

            // Handle scenario with multiple recipients and missing emails
            if (!proceed_anyway && usersWithoutEmail.length) {
                if(allUsersForReport.length > 1) {
                    if(allUsersForReport.length == usersWithoutEmail.length) {
                        this.$parent.hideSnackbar();
                        this.$parent.showSnackbar('The selected users have no email addresses set. Unable to send report.', false, 'invalid', 'fas fa-times-circle')
                        return
                    } else {
                        this.openModal('proceed-sending-report-confirmation-multiple');
                        // this.$parent.hideSnackbar();
                        return;
                    }
                } else if(allUsersForReport.length == 1) {
                    this.$parent.hideSnackbar();
                    this.$parent.showSnackbar('Selected user has no email address set. Unable to send report.', false, 'invalid', 'fas fa-times-circle')
                    return
                }
            } 

            // Prepare the list of users for the payload
            let cleanSelectedSchedulingReport = proceed_anyway 
                ? selectedUsersForReport.filter(user => user.email).map(user => user.id)
                : schedulingReportSelected.map(sr => sr.value);

            let payload = {
                users: cleanSelectedSchedulingReport
            };

            // Include assigned recipients if specified
            if (this.scheduling_report.include_assigned_recipient) {
                payload.users = proceed_anyway
                    ? allUsersForReport.filter(user => user.email).map(user => user.id)
                    : [...cleanSelectedSchedulingReport, ...this.scheduling_report.selected_permaRecipient];
            }
            console.log(payload)
            await axios.post(`${process.env.VUE_APP_URL}/api/users/send-combo-report`,payload,getHeaders()).then(
                response => {
                    if(response.status == 200) {
                        // reset the data
                        this.scheduling_report.include_assigned_recipient = 0
                        this.scheduling_report.selected_permaRecipient = []
                        this.clearAll_selectedUser_forSchedule()
                        // console.log(response.data.data)
                        this.$parent.showSnackbar('Successfully Sent Report', false, 'success', 'fas fa-check-circle')
                    }
                }
            ).catch(error => {
                let err = {error}
                console.log(error)
                console.log(err?.error?.response?.data?.meta?.message)
                this.$parent.showSnackbar(`${err?.error?.response?.data?.meta?.message}`, false, 'invalid', 'fas fa-times-circle');
                // this.$parent.showSnackbar('Something went wrong in Sending the Report. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');
            })
        },
        handleClick_UnSelectAll_permaRecipients() {
            if(this.scheduling_report?.include_assigned_recipient == 0)
                return
            else {
                if(this.scheduling_report?.selected_permaRecipient?.length == this.scheduling_report?.assigned_recipients_clean.length) {
                    this.scheduling_report.selected_permaRecipient = []
                } else {
                    this.scheduling_report.selected_permaRecipient = [...this.scheduling_report.assigned_recipients_clean]
                }
            }
        },
        async addUpdateScheduleTo_singleCombo(assignment_id, from = 'add') {
            const headers = getHeaders()
            let payload = {
                days: this.create_auto_assign.schedules
            }
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/${assignment_id}/schedules`,payload,headers).then(
                response => {
                    if(from == 'edit')
                        this.create_auto_assign.schedules_raw = response.data.data
                }
            ).catch(error => {
                console.log(error)
            })
        },
        async addUpdateScheduleTo_multipleCombo () {
            this.$parent.showSnackbar('Processing...', true)

            let payload = {
                ids: this.selectedCombosClean,
                days: this.ungroup_combinations.schedules,
            }
            await axios.patch(`${process.env.VUE_APP_URL}/api/assignments/schedules/multiple`,payload,getHeaders()).then(
                async response => {
                    if(response.status == 200) {
                        // console.log(response)
                        await this.getCombos()
                        this.initializeSelectionCache()
                        this.resetUngroupCombinations()
                        this.$parent.showSnackbar('Successfully Updated Schedules for Selected Ungrouped Combinations', false, 'success', 'fas fa-check-circle')
                        this.closeModal('edit-schedule')
                    }
                }
            ).catch(error => {
                console.log(error)
                this.$parent.showSnackbar('An Error occurred when Updating Schedules for Selected Ungrouped Combinations', false, 'invalid', 'fas fa-exclamation-triangle')
            })
        },
        addUpdate_selectedCombinations(combo_id, method='add') {
            let all_combos = [...this.all_combos]
            let selected_combination = all_combos.filter(ac => ac.id == combo_id)[0]
            if(method == 'add') {
                // this.preset_combinations.push(selected_combination)
                this.update_group.selected_combinations.push(selected_combination)
                this.update_group.add_combo = 0
            }

            if(method == 'remove') {
                this.update_group.selected_combinations = [...this.update_group.selected_combinations.filter(sc => sc.id != combo_id)]
                this.update_group.add_combo = -1
                this.selected_forDelete = {}

                // forcing execution of the `clearAllSearched()` function 
                // inside the select component using ref
                this.$refs.adminSelectCombinationsInUpdateGroup.clearAllSearched()
            }
        },
        /**
         * Update the combinations in the Selected Group
         * @param { Number | Int } assignment_group_id 
         * @param { Array } assignment_ids 
         */
        async updateSelectedCombinations(assignment_group_id, assignment_ids) {
            /**
             * PATCH /assignment_groups/{assignment_group_id}/combos
             * {
             *      assignment_ids: []
             * }
             */
            let payload = {
                assignment_ids: assignment_ids
            }
            await axios.patch(`${process.env.VUE_APP_URL}/assignment_groups/${assignment_group_id}/combos`,payload, getHeaders()).then(
                response => {
                    if(response == 200) {
                        // Update the preset_combinations after updating the combinations under the group
                        this.preset_combinations = JSON.parse(JSON.stringify(this.update_group.selected_combinations))
                        // console.log(response.data)
                    }
                }
            ).catch(error => {
                console.log(error)
            })
        },
    },
    computed: {
        // page
        // last_page() {
        //     return Math.ceil(this.visible_total / parseInt(this.page_size));
        // },
        selectedCombosValues() {
            return this.requestedCombos.filter(r => this.selectedCombosClean.includes(r.id))
        },
        selectedCombosClean() {
            let ids = []
            Object.values(this.selectedCombos).forEach(c => ids.push(...c))
            return ids
        },
        from_page_count() {
            return (this.page * parseInt(this.page_size)) - parseInt(this.page_size) + 1;
        },
        to_page_count() {
            return (this.page * parseInt(this.page_size)) - (parseInt(this.page_size) - this.visible);
        },
        from_page_index() {
            return parseInt(this.page - 1) * parseInt(this.page_size);
        },
        to_page_index() {
            return parseInt(this.from_page_index + parseInt(this.page_size));
        },

        // for filter by
        selected_platform() {
            // return this.platforms[this.platform]
            return this.modified_platformForFilterBy.filter(p => p.id == this.platform)[0]?.name
        },
        selected_priority() {
            let priorities = [...this.priorities]
            return priorities.filter(p => p.value == this.priority)[0]?.label
        },
        selected_status() {
            let statuses = [...this.statuses]
            return statuses.filter(s => s.value == this.status)[0]?.label
        },
        selected_campaign() {
            return this.modified_campaignForFilterBy.filter(c => c.value == this.campaign)[0]?.label
        },
        selected_fallback() {
            let fallbacks = [...this.fallbacks]
            return fallbacks.filter(fb => fb.value == this.fallback)[0]?.label
        },
        selected_schedule() {
            let filterBy_schedules_options = [...this.filterBy_schedules_options]
            return filterBy_schedules_options.filter(so => so.value == this.schedule)[0]?.label
        },
        selected_filterBy_group() {
            let groups = [...this.mapped_groups]
            return groups.filter(g => g.value == this.filterBy.group)[0]?.name
        },
        mapped_groups() {
            let groups = [...this.groups]
            groups = groups.map(g => ({...g, label: g.name, value: g.id}))
            groups = [{label: 'All Groups', value: 0, name: 'All Groups'}, ...groups]
            return groups
        },
        // for adding and editing auto campaign assignment
        mapped_platforms() {
            let platforms = [...this.platforms]
            return platforms.filter(p => p.id != 0).map(p => ({label: p.name, value: p.id}))
        },
        mapped_campaigns() {
            let campaigns = [...this.campaigns]
            return campaigns.filter(c => c.id != 0).map(c => ({label: c.name, value: c.id}))
        },
        isValidForAddingCombo() {
            /**
             * Fallback Condition
             * If fallback or Priority is URGENT and Target == 0 = it is Valid
             * otherwise it's Not Valid
             */

            /**
             * Note for Urgent Priority
             * Should be treated similar to Fallback:
             * No Set Quota (No Target),
             * BUT Urgent can never be a Fallback
             * Researchers are allowed to add as many listings as possible.
             * 
             * Researchers should only be assigned a different combination if either 
             * the combination is marked as "Exhausted" or has been disabled
             */

            let isValid = false
            let required_fields = ['campaign','platform','no_of_researchers','target','priority','schedules','fallback'] // Default Required Fields
            let isRequired_counter = 0

            if(this.create_auto_assign.fallback || this.create_auto_assign.priority == 'URGENT') { 
                required_fields = required_fields.filter(rf => rf != 'target') // remove target
            } else {
                if(!required_fields.includes('target')) required_fields.push('target')
            }

            if(this.create_auto_assign.hasNoSchedule) {
                required_fields = required_fields.filter(rf => rf != 'schedules')
            } else {
                if(!required_fields.includes('schedules')) required_fields.push('schedules')
            }

            required_fields.forEach(rf => {
                // Checking
                switch (rf) {
                    case 'campaign':
                    case 'platform':
                    case 'target':
                    case 'no_of_researchers':
                        if(this.create_auto_assign[rf] > 0) isRequired_counter++
                        break
                    case 'priority':
                        if(this.create_auto_assign[rf]) isRequired_counter++
                        break
                    case 'schedules':
                        if(this.create_auto_assign[rf].length) isRequired_counter++
                        break
                    case 'fallback':
                        isRequired_counter++
                        break

                }
            })
            
            // console.log(required_fields)
            // console.log(isRequired_counter, `required_fields.length == isRequired_counter: ${required_fields.length == isRequired_counter}`)

            if(required_fields.length == isRequired_counter) isValid = true
            else isValid = false

            return isValid
        },
        isValidForAddingGroup() {
            let isValid = false
            let fields = ['no_of_researchers','target','priority','schedules','fallback','selected_combinations'] // Default Required Fields
            let isEdited_counter = 0
            let isRequiredFulfilled_counter = 0

            if(this.update_group.fallback || this.update_group.priority == 'URGENT') { 
                fields = fields.filter(rf => rf != 'target') // remove target
            } else {
                if(!fields.includes('target')) fields.push('target')
            }

            fields.forEach(rf => {
                // Checking
                switch (rf) {
                    case 'target':
                        if(this.update_group?.[rf] > 0 && this.update_group?.[rf] != this.selected_group?.target) isEdited_counter++
                        if(this.update_group?.[rf] > 0) isRequiredFulfilled_counter++
                        break
                    case 'no_of_researchers':
                        if(this.update_group?.[rf] > 0 && this.update_group?.[rf] != this.selected_group?.no_of_researcher) isEdited_counter++
                        if(this.update_group?.[rf] > 0) isRequiredFulfilled_counter++
                        break
                    case 'priority':
                        if(this.update_group?.[rf] != this.selected_group?.priority) isEdited_counter++
                        if(this.update_group?.[rf]) isRequiredFulfilled_counter++
                        break
                    case 'schedules':
                        if(this.update_group?.[rf].length != this.selected_group?.schedules?.length) isEdited_counter++
                        if(this.update_group?.[rf].length) isRequiredFulfilled_counter++
                        break
                    case 'fallback':
                        if(this.update_group?.[rf] != this.selected_group?.is_fallback) isEdited_counter++
                        break
                    case 'selected_combinations':
                        if(this.update_group?.[rf].length != this.preset_combinations?.length) isEdited_counter++
                        break
                }
            })

            if(isEdited_counter > 0 && isRequiredFulfilled_counter >= 3) isValid = true
            else isValid = false

            return isValid
        },
        modified_campaignForFilterBy() {
            let campaigns = this.campaigns_inCombination.filter(c => c.status).map(c => ({label: c.name, value: c.id, logo: this.$parent.getImage('campaigns', c.name || 'Default')}))
            campaigns = [{value: 0, label: 'All Campaigns', logo: '/static/svg/Nav_Campaign_Active.svg'}, ...campaigns]
            return campaigns
        },
        modified_platformForFilterBy() {
            let platforms = this.platforms_inCombination.map(d => ({id: d.id, name: d.name, logo: this.$parent.getImage('platforms', d.name || 'Default')}))
            platforms = [{id: 0, name: 'All Platforms', logo: '/static/svg/Filterby_Platforms.svg'}, ...platforms]
            return platforms
        },
        filtered_combos() {
            this.refreshKey_forComputed

            let combos = [...this.combos]
            // filter by campaigns
            if(this.campaign != 0) {
                combos = combos.filter(c => c.campaign_id == this.campaign)
            }

            // filter by platforms
            if(this.platform != 0) {
                combos = combos.filter(c => c.platform_id == this.platform)
            }

            // filter by priority
            if(this.priority != 0) {
                /**
                 * Check Grouping first
                 * IF there is group then filter the group
                 * otherwise use the individual data
                 */

                combos = combos.filter(c => {
                    if(c.assignment_group_id == null) {
                        return c.priority == this.priority
                    } else {
                        return c?.group?.priority == this.priority
                    }
                })
                /**
                 * Previous Code
                 */
                // combos = combos.filter(c => c.priority == this.priority)
            }

            // // filter by status
            // if(this.status != 0) {
            //     combos = combos.filter(c => c.status_label.toLowerCase() == this.status)
            // }

            // filter by fallback
            if(this.fallback != -1) {
                /**
                 * Check Grouping first
                 * IF there is group then filter the group
                 * otherwise use the individual data
                 */
                combos = combos.filter(c => {
                    if (c.assignment_group_id == null) {
                        return c.is_fallback == this.fallback
                    } else {
                        return c?.group?.is_fallback == this.fallback
                    }
                })

                /**
                 * Previous Code
                 */
                // combos = combos.filter(c => c.is_fallback == this.fallback)
            }

            // search
            // if(this.search.trim() != '') {
            //     combos = combos?.filter(c => 
            //         c?.platform?.name.toLowerCase().includes(this.search.toString().toLowerCase()) || 
            //         c?.campaign?.name.toLowerCase().includes(this.search.toString().toLowerCase())
            //     )
            // }
            let result = combos
            let visible_total = combos.length
            let visible = result.length

            // return combos
            return { result, visible_total, visible }
        },
        hasSelection() {
            return this.selectedCombosClean.length
        },
        filtered_mapped_all_combos() {
            let all_combos = [...this.mapped_all_combos]
            let selected_group = {...this.selected_group}
            let selected_combinations = [...this.update_group?.selected_combinations].map(ug_sc => ug_sc.id)
            const removed_combinations_clean = selected_group.assignments.filter(sc => !selected_combinations?.includes(sc.id)).map(sg_a => sg_a.id)
            let data = []


            data = all_combos.filter(ac =>
                ac.assignment_group_id != selected_group.id
                && (ac.assignment_group_id == null)
            )
            // console.log(data)
            // console.log(removed_combinations_clean)
            if(removed_combinations_clean.length) {
                let combos_toBeAdded = all_combos.filter(ac => removed_combinations_clean.includes(ac.value))

                combos_toBeAdded.forEach(c => {
                    data.push(c)
                })

                data = arraySort_byNumberKeyword(data, 'value', 'asc')
            }
            
            // console.log(data)

            return data
            // return mapped_combos that are not assigned to itself or null or undefined 
            // return all_combos.filter(a => a.assignment_group_id != selected_group.id && (a.assignment_group_id == null || a.assignment_group_id == undefined || a.assignment_group_id == null))
            /**
             * Previous Condition Below
             */
            // return all_combos.filter(a => a.assignment_group_id != selected_group.id && (a.assignment_group_id == null || a.assignment_group_id == undefined || a.assignment_group_id == null) && !preset_combinations_clean.includes(a.value))
        },
        filtered_groups() {
            let groups = [...this.groups]
            let search = this.manage_group.search.toString().toLowerCase().trim()

            switch(this.step) {
                case 5:
                    groups = groups.filter(g => g.id != this.moveTo_anotherGroup.from_group?.id)
                    break
            }

            if(search != '')
                groups = groups.filter(g => g.name?.toString()?.toLowerCase()?.includes(search))

            return groups
        },
        selectCombo_resetValue() {
            let add_combo = this.update_group.add_combo
            if(add_combo == 0)
                return true
            else return false
        },
        filtered_mapped_users() {
            this.refreshKey_forComputed

            let all_users = [...this.users]
            // let assigned_recipients_clean = this.scheduling_report?.assigned_recipients_clean

            /**
             * Actually this haven't been used
             */
            // let search = this.scheduling_report?.search?.toString().toLowerCase().trim()
            // if(search != '')
            //     all_users = all_users.filter(al => al.label?.toString()?.toLowerCase()?.includes(search))
            
            all_users = all_users.filter(al => al?.selected == false)

            // exclude recipients in filter
            all_users = all_users.filter(al => !this.computed_assigned_recipients_clean?.includes(al.value))            

            return all_users
        },
        computed_assigned_recipients_clean() {
            let clean = this.scheduling_report?.assigned_recipients_clean
            return clean
        },
        filtered_permanent_recipients() {
            let perma_recipients = [...this.scheduling_report?.assigned_recipients]
            let search = this.scheduling_report?.permanent_recipientSearch?.toString().toLowerCase().trim()

            if(search != '')
                perma_recipients = perma_recipients.filter(pc => 
                    pc.label?.toString()?.toLowerCase()?.includes(search) ||
                    pc.e_mail?.toString()?.toLowerCase()?.includes(search)
                )

            return perma_recipients
        },
        selectUsers_resetValue() {
            let add_user = this.scheduling_report.add_user
            if(add_user == 0)
                return true
            else return false
        },
        isOkayToEdit() {
            let _selectedCombosValues = [...this.selectedCombosValues]
            let count = 0
            _selectedCombosValues.forEach(_s => {
                if(_s.assignment_group_id == null || _s.assignment_group_id == undefined || _s.assignment_group_id == null)
                    count++
            })
            return count == 0 ? false : count == _selectedCombosValues.length
        },
        selectedCombos_hasGroup() {
            let _selectedCombosValues = [...this.selectedCombosValues]
            let hasGroup = false
            _selectedCombosValues.forEach(_s => {
                if(_s.assignment_group_id != null && _s.assignment_group_id != undefined && _s.assignment_group_id != null)
                    hasGroup = true
            })
            return hasGroup
        },
        isAbleTo_sendReport() {
            let scheduling_report = this.scheduling_report
            let isAble = false

            switch(scheduling_report.include_assigned_recipient){
                case 0:
                case '0':
                    if(scheduling_report?.selected?.length > 0)
                        isAble = true
                    break
                case 1:
                case '1':
                    if(scheduling_report?.selected_permaRecipient?.length > 0 || scheduling_report?.selected?.length > 0) 
                        isAble = true
                    break
            }

            return isAble
        },
    },
    watch: {
        // combos() {
        //     this.filtered_combos
        // },
        last_page() {
            this.initializeSelectionCache();
        },
        filtered_combos(val) {
            this.visible = val.visible
            this.visible_total = val.visible_total
        },
        selectedCombos: {
            handler() {
                this.initializeSelectAll()
            },
            deep: true
        },
        async page() {
            this.initializeSelectAll();
            await this.getCombos('pagination')
        },
        // 'table_order.order'() {
        //     let isAsc = +(this.table_order.order == 'asc')
        //     if (['campaign','platform','priority'].includes(this.table_order.column)) { //string sorting
        //         this.combos.sort((a,b) => {
        //             let combo1 = [b, a][isAsc]
        //             let combo2 = [a, b][isAsc]
        //             const com1 = ['campaign', 'platform'].includes(this.table_order.column) ? combo1[this.table_order.column]?.name : combo1[this.table_order.column]
        //             const com2 = ['campaign', 'platform'].includes(this.table_order.column) ? combo2[this.table_order.column]?.name : combo2[this.table_order.column]
        //             return (com1).localeCompare(com2)
        //         })
        //     } else { //number sorting
        //         this.combos.sort((a,b) => {
        //             let combo1 = [b, a][isAsc]
        //             let combo2 = [a, b][isAsc]
        //             return combo1[this.table_order.column] - combo2[this.table_order.column]
        //         })
        //     }
        // },
        // 'table_order.column'(val) {
        //     let isAsc = +(this.table_order.order == 'asc')
        //     if (['campaign','platform','priority'].includes(this.table_order.column)) { //string sorting
        //         this.combos.sort((a,b) => {
        //             let combo1 = [b, a][isAsc]
        //             let combo2 = [a, b][isAsc]
        //             const com1 = ['campaign', 'platform'].includes(this.table_order.column) ? combo1[this.table_order.column]?.name : combo1[this.table_order.column]
        //             const com2 = ['campaign', 'platform'].includes(this.table_order.column) ? combo2[this.table_order.column]?.name : combo2[this.table_order.column]
        //             return (com1).localeCompare(com2)
        //         })
        //     } else { //number sorting
        //         this.combos.sort((a,b) => {
        //             let combo1 = [b, a][isAsc]
        //             let combo2 = [a, b][isAsc]
        //             return combo1[this.table_order.column] - combo2[this.table_order.column]
        //         })
        //     }
        // },
        async campaign() {
            this.page = 1
            await this.getCombos('filterBy')
        },
        async platform() {
            this.page = 1
            await this.getCombos('filterBy')
        },
        async priority() {
            this.page = 1
            await this.getCombos('filterBy')
        },
        async fallback() {
            this.page = 1
            await this.getCombos('filterBy')
        },
        async status() {
            this.page = 1
            await this.getCombos('filterBy')
        },
        async 'filterBy.schedules'(val) {
            if(val.length < 7)
                this.filterBy.selectAll_schedules = false
            this.page = 1
            await this.getCombos('filterBy')
        },
        async 'filterBy.group'() {
            this.page = 1
            await this.getCombos('filterBy')
        },
        async 'filterBy.selectAll_schedules'(val) {
            if(val) {
                this.filterBy.schedules = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY']
            } else {
                if(!this.filterBy.schedules.length || this.filterBy.schedules.length == 7)
                    this.filterBy.schedules = []
            }
        },
        search(val) {
            if (val.trim() == '' && this.has_usedGlobalSearch) {
                this.searchNow()
                this.has_usedGlobalSearch = false
            }
        },
        async page_size() {
            this.page = 1
            this.showBackdrop()
            await this.getCombos()
            this.hideBackdrop()
        },
        async step(new_val, old_val) {
            if((old_val > 0 && new_val == 0) || (old_val == 0 && new_val > 0)) {
                const modal = document.getElementById('auto-campaign-assignment-manage-group');
                const isOpen = modal.style.display === 'block';

                if (isOpen) {
                    this.closeModal('auto-campaign-assignment-manage-group');
                    if(old_val != 4) {
                        setTimeout(() => {
                            this.openModal('auto-campaign-assignment-manage-group');
                        }, 1000);
                    }
                } else {
                    setTimeout(() => {
                        this.openModal('auto-campaign-assignment-manage-group');
                    }, 1000);
                }
            }
        },
        "scheduling_report.include_assigned_recipient"(new_val) {
            // if(new_val)
            //     this.scheduling_report.selected_permaRecipient = [...this.scheduling_report.assigned_recipients_clean]
            if(!new_val)
                this.scheduling_report.selected_permaRecipient = []
        },
        "create_auto_assign.priority"(new_val) {
            if(new_val == 'URGENT') this.create_auto_assign.fallback = 0
        },
        "create_auto_assign.hasNoSchedule"(new_val) {
            if(new_val) this.create_auto_assign.schedules = []
        },
        "update_group.hasNoSchedule"(new_val) {
            if(new_val) this.update_group.schedules = []
        },
        "ungroup_combinations.hasNoSchedule"(new_val) {
            if(new_val) this.ungroup_combinations.schedules = []
        },
    },
}
</script>

<style scoped>
.auto-campaign-container {
    /* display: flex;
    align-items: flex-start;
    justify-content: flex-start; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    /* margin-left: 60px; */
    /* width: calc(100% - 60px); */
    width: calc(100%);
    height: 100vh;

    padding-top: 57px;
}
.auto-campaign-titlebar {
    width: 100%;
    height: auto;
    /* border: 1px solid red; */
    background: #FFFFFF;
    position: relative;
    z-index: 4;
    padding-bottom: 5px;
}

.has-bullet-separator {
    position: relative;
}

.has-bullet-separator::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 5px;
    width: 5px;
    border-radius: 100%;
}

/* main  */

.main-con {
    width: 100%;
    /* height: 100%; */
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    padding: 20px 20px 20px 80px;
}

.main-con table tr {
    font-size: 12px;
}

.main-con table thead tr {
    background: #53606D26;
    /* border-bottom: 2px solid rgb(214, 214, 216); */
    color: #202229;
    font-size: 11px;
}

.main-con table thead th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    white-space: nowrap;
}

.main-con table tbody tr {
    font-weight: bold;
    border-bottom: 2px solid #F5F6FA;
    vertical-align: middle;
    padding: 5px 0px;
}

.main-con table tbody .researcher-own-campaigns {
    max-height: 0;
    overflow: hidden;
    transition: all ease .5s;
    -webkit-transition: all ease .5s;
}

.main-con table tbody .researcher-own-campaigns .border-bottom {
    border-bottom: 2px solid #F5F6FA !important;
}
/* 
.table-scroll .researcher-own-campaigns.show {
    max-height: 500px;
} */

.main-con table tbody:last-child tr {
    border-bottom: none;
}

.main-con table tfoot tr {
    background: #53606D26;
}

.main-con table tfoot th {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.main-con table tfoot th.bigger-font {
    font-size: 14px;
}

.main-con table tr td .r-name {
    top: 2.5px;
}

.table-scroll{
    width:100%;
    display: block;
    empty-cells: show;
    background: #ffff;
}

.table-scroll thead,
.table-scroll tfoot{
    position:relative;
    display: block;
    width:100%;
}

.table-scroll tbody{
    display: block;
    position:relative;
    width:100%;
    height: calc(100vh - 300px);
    /* max-height: calc(100vh - 336px); */
    overflow-y:auto;
}

.table-scroll tr{
    width: 100%;
    display: inline-block;
}

.table-scroll > tbody > tr {
    height: 42.22px;
}

.table-scroll td,.table-scroll th{
    display: inline-block;
    word-break: break-all;
    padding: .6rem 7px;
    text-align:left;
}

.table-scroll td {
    padding: .4rem 7px;
}

.table-scroll thead th,
.table-scroll tfoot th {
    font-size: 11px;
}

.sorting-caret-con {
    width: 9px;
    height: 9px;
}

.sorting-caret {
    color: #53606D;
    font-size: 13px;
    position: absolute;
    left: 0;
    right: 0;
}

.sorting-caret-con .sorting-caret.top-caret {
    bottom: 2px;
}

.sorting-caret-con .sorting-caret.bottom-caret {
    top: 2px;
}

/* table-item */
.table-item {
    background: #FFFFFF;
}
.table-item.isHovered {
    background: #FBF9FA;
}
.table-item.noSchedule {
    background: #f6d6d6;
}

/* gui admin specific css */
.gui-admin .has-bullet-separator::before {
    background: #601848;
}

.gui-admin .dropdown-item {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #FFFFFF;
}
.gui-admin .dropdown-menu {
    max-height: 250px;
    overflow: auto;
    z-index: 5;
    background: #FFFFFF;
}
.gui-admin .dropdown-menu.show {
    max-height: 250px;
    overflow: auto;
    z-index: 5;
    background: #FFFFFF;
}
.gui-admin .dropdown-item:hover,
.gui-admin .dropdown-item:focus {
    color: #FFFFFF;
    background: #6018488F;
}
/* override the default in button for groupDropdown */
.gui-admin .a-btn-text:not(.disabled):hover.groupDropdown {
    background-color: transparent !important;
    color: transparent !important;
}

.filter-bar {
    padding-left: 19px;
    min-height: 48px;
    height: auto;
    max-height: 80px;
    margin-left: 82px !important;
    width: calc(100% - 104px);
    -webkit-transition: border-color .5s ease-in;
    transition: border-color .5s ease-in;
}

.selection-bar {
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
}

.selection-bar.has-selection {
    border-color: #601848;
    font-size: 12px;
    padding: 0 20px;
}

.combo-btn {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    opacity: 1;
    display: inline-flex;
    align-items: center;
    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
    color: #1a1a1a;
}

.combo-btn img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
}

.combo-btn.update {
    color: #601848;
}

.combo-btn.enable {
    color: #29ABA2;
}

.combo-btn.disable {
    color: #DC3C40;
}
.combo-btn.add-group {
    color: #F07241;
}
.combo-btn.edit-schedule {
    color: #3E88DA;
}

.combo-btn:hover {
    opacity: .7;
}

.has-selection-selected-count {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0px;
    color: #601848;
    display: inline-flex;
    align-items: center;
}

.has-selection-selected-count img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
}

/* priority */
.priority {
    font-weight: bold;
    width: max-content;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 4px;
    letter-spacing: -0.2px;
}
.priority.high {
    color: #DC3C40;
    background: #f2a2a3;
}
.priority.low {
    color: #DCA73C;
    background: #f3d18e;
}
.priority.urgent {
    color: #edbcbd;
    background: #DC3C40;
}
.priority.medium {
    color: #f3d18e;
    background: #F07241;
}
.priority.disabled {
    color: #000000;
    background: #b3b3b3;
}

/* status */
.status {
    font-weight: bold;
    width: max-content;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 4px;
    letter-spacing: -0.2px;
    border: 1px solid transparent;
    word-break: break-word;
}
.status.exhausted {
    color: #DC3C40;
    background: #f2a2a3;
}
.status.pending {
    color: #F07241;
    background: #eda98e;
}
.status.completed {
    color: #4ECDC4;
    background: #d9f5f3;
}
.status.in-progress {
    color: #601848;
    background: #CF8DB9;
}
.status.verified-exhausted {
    color: #DC3C40;
    background: #DC3C401C;
    border: 1px solid #DC3C40;
}
.status.disabled {
    color: #515365;
    background: #c5c6c6;
}

.manage-group-view-title,
.group-combination-view-title,
.create-new-custom-group-title,
.scheduling-report-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
}

.manage-group-group-container,
.group-combination-group-container,
.scheduling-report-container {
    height: 254px;
    width: 100%;
    margin: 0px 0px 60px 0px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    overflow: auto;
    padding: 15px 10px;
}

.manage-group-group-item,
.group-combination-group-item,
.scheduling-report-item {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    border-radius: 4px;
}
.manage-group-group-item-item,
.group-combination-group-item-item,
.scheduling-report-item-item {
    position: relative;
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 10px 12px;

    /* border-bottom: 1px solid #7070704A; */
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
}
.manage-group-group-item-item .click-check,
.group-combination-group-item-item .click-check {
    position: absolute;
    right: 18px;
    width: 16px;
    height: 16px;
}
/* .manage-group-group-item-item.isClicked,
.manage-group-group-item-item.isHovered.isClicked, */
.group-combination-group-item-item.isClicked,
.group-combination-group-item-item.isHovered.isClicked {
    background: #F6E6EE;
    color: #601848;
    font-weight: bold;
    border: 1px solid #4800485C;
}
.manage-group-group-item-item.isHovered,
.group-combination-group-item-item.isHovered {
    background: #FBF9FA;
}
.manage-group-group-item hr,
.group-combination-group-item hr,
.scheduling-report-item hr {
    /* border: 1px solid #ced4da; */
    height: 0px;
    background: #7070704A;
    width: 100%;
    margin: 0px !important;
}

.check-description {
  width: 240px;
  height: 67px;
}
.combo-assignments {
    position: relative;
    font-size: 12px;
    letter-spacing: 0.15px;
    font-weight: bold;
    width: 100%;
    /* height: 42px !important; */
    height: 100%;
}
.combo-assignments hr {
    /* border: 1px solid #ced4da; */
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 0px;
    background: #00000029;
    width: 100%;
    margin: 0px !important;
}

.schedule-format {
    color: #601848;
    text-transform: uppercase;
    padding:4px;
    border-radius:4px;
    border: 1px solid #601848;
}
.schedule-format.isActive {
    color: #FFFFFF;
    background: #601848;
}

/* media */
@media screen and (max-width: 1079px) {
    .campaign,
    .platform {
        padding: 0px;
        width: 90% !important;
    }
    .table-scroll tbody {
        height: calc(100vh - 315px);
    }
}
@media screen and (min-width: 1080px) {
    .campaign,
    .platform {
        padding: 0px 16px;
        width: 70% !important;
    }
    .table-scroll tbody {
        height: calc(100vh - 315px);
    }
}
@media screen and (min-width: 1280px) {
    .campaign,
    .platform {
        padding: 0px 16px;
        width: 60% !important;
    }
}
@media screen and (min-width: 1320px) {
    .table-scroll tbody {
        height: calc(100vh - 315px);
    }
}
@media screen and (min-width: 1409px) {
    .table-scroll tbody {
        height: calc(100vh - 305px);
    }
}
@media screen and (min-width: 1600px) {
    .campaign,
    .platform {
        padding: 0px 16px;
        width: 50% !important;
    }
}

</style>